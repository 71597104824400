import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

// Working Process Images


// Service Icons
import techImg3 from '../../assets/img/technology/style2/3.webp';
import techImg5 from '../../assets/img/technology/style2/5.webp';
import techImg1 from '../../assets/img/technology/style2/1.webp';
import techImg2 from '../../assets/img/technology/style2/2.webp';
import techImg43 from '../../assets/img/technology/style2/43.webp';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.webp';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.webp';
import serviceIcon24 from '../../assets/img/service/style2/main-img/24.webp';
import serviceIcon25 from '../../assets/img/service/style2/main-img/25.webp';

// Service Icons


// Service Icons
import effectImg2 from '../../assets/img/about/dotted-3.webp';
import effectImg3 from '../../assets/img/about/shape3.webp';

const AngularJsDevelopment = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text gold-color"
                                subtitle="Full Stack JavaScript Development Solutions"
                                titleClass="title pb-25"
                                title="End-to-End JavaScript Development Services to transform your digital domain 
                                "
                                descClass="desc pb-5"
                                description="Are you a startup looking to thrive in the competitive digital realm? Or are you a well-established enterprise aiming to reach out to overseas customers?"
                                secondDescClass="desc pb-16"
                                secondDescription="MetaQualt is the right stop for you. You will luck into the most advanced solution with our years of experience in end-to-end Javascript frameworks such as VueJS, React JS & Node JS. By leveraging the trending tools, and technologies we swing into action to make a doozy product that’s rapid-to-market and reliable."
                            />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-60 pb-60">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text gold-color"
                        subtitle="Services"
                        titleClass="title pb-10"
                        title="Make great strides in your Business with our full-stack JavaScript Development"
                        descClass="desc"
                        description="Revamp your online presence with our front-end and back-end apps. 
                        Client-side JavaScript was at first only supported in browsers. Now, Node.JS proves to be a perfect example that JS is emerging as a server-side technology. 
                        "
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-20">
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        titleClass="title pb-10 fz-36"
                                        title="Front-end JS Development"
                                        descClass="desc"
                                        description="Our team of hand-picked developers makes sure to provide highly dynamic and swift-in-response web pages for your application. We use JavaScript frameworks such as React Native, Angular, and Vue.js to make it happen." 
                                    />
                                    <div className="rs-technology">
                                        <div className="row">            
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div className="technology-item">
                                                    <a href="#">
                                                        <div className="logo-img">
                                                            <img src={techImg3} alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                    <a href="#">
                                                        <div class="logo-img">
                                                        <img src={techImg1} alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                    <a href="#">
                                                        <div class="logo-img">
                                                        <img src={techImg5} alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6 mb-20">
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        titleClass="title pb-10 fz-36"
                                        title="Back-end JS Development"
                                        descClass="desc"
                                        description="Back-end JavaScript is used to manage simultaneous web page requests, data transfers, and general server tasks. Opt for our Back-End JS Development Services for a robust back-end made using Node.js and Meteor."  
                                    />
                                    <div className="rs-technology">
                                        <div className="row">            
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div className="technology-item">
                                                    <a href="#">
                                                        <div className="logo-img">
                                                            <img src={techImg2} alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                    <a href="#">
                                                        <div class="logo-img">
                                                        <img src={techImg43} alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-end */}

            {/* <Technologies /> */}

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-60"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title"
                        title="Why pick our talents for Development?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon14}
                            Title="Proven Expertise"
                            Text="With our years of experience in user-centric app development using JavaScript, the solution you get is the unrivaled window to showcase your talent online."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Customer-Centric" 
                            Text="Our entire development process, from ideation to delivery, revolves around your goals and needs. Your website’s maintenance is in the hands of skilled and proactive developers. 
                            " 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon24}
                            Title="Year-long Experience" 
                            Text="Developing feature-rich JavaScript is a piece of cake for our experienced team, who have upgraded their skills promptly. So, what you get is a sheer hassle-free and authoritative application. 
                            " 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon25}
                            Title="Result-Drive" 
                            Text="We don’t just provide glittering apps. We give a customized product that suits your industry’s needs. What you get is glittering and golden, appealing and profitable." 
                        />
                    </div>
                </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            
              {/* rs-servicess-area-start */}

           {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Book a Call with our JavaScript Developers"
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, deployment, design, and support to turn them into irresistible apps. With us, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision. 
                "
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default AngularJsDevelopment;