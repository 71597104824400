import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";

import bannerImg from "../../assets/img/banner/applicationmin12.webp";





import effectImg2 from "../../assets/img/about/dotted-3.webp";
import effectImg3 from "../../assets/img/about/shape3.webp";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";

import mean_stack1 from "../../assets/img/about/appliint12.webp";
import OurServices from "./weProvide";
import WhyChoose from "./whychooseus";

const application_migration = () => {

  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about row_rev_sec pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-5 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="We are Different"
                titleClass="title pb-25"
                title="Future-Proof Your Business with Scalable, Secure, and Agile IT Solutions


                                "
                descClass="desc pb-5"
                description="Outdated systems can hold your business back in today's fast-paced digital landscape. At MetaQualt, our Application Migration Services are designed to transition your legacy applications to modern, cloud-ready environments seamlessly.
We don’t just migrate applications—we align every technical decision with your business goals. The result? Enhanced scalability, improved resilience, and the agility to adapt to ever-changing market demands.
Every migration is a step towards innovation. With MetaQualt, every decision is backed by strategic insights and measurable outcomes.
Transform your legacy systems. Elevate your business. Choose MetaQualt.
 "
              />
              <ConsultNowBtn />
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>

      <div className="style3 pt-100 pb-100 md-pt-40 md-pb-40 provide_sec">
        <div className="container relative">
          <div className="sec-left">
            <h2 className="title"> What We Provide</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div className="row align-items-left">
                <div className="col-lg-12 col-md-12">
                  <SectionTitle
                    sectionClass="sec-title2 text-left mb-30"
                    subtitleClass="sub-text style-bg"
                    subtitle="Application Migration Services"
                    titleClass="title pb-10"
                    title="Application Migration Services
                                        "
                    descClass="desc"
                    description="In today’s fast-paced digital landscape, outdated systems and legacy applications can severely limit your business’s potential for growth, innovation, and security. At MetaQualt, our Application Migration Services empower businesses to seamlessly transition from legacy systems to modern, cloud-ready, and high-performance environments.
We go beyond simple migration—we ensure every technical decision aligns with your long-term business goals, delivering measurable outcomes that drive success. Whether you're moving to the cloud, adopting hybrid solutions, or upgrading on-premise infrastructure, we guarantee a seamless migration journey with minimal disruption.

                                        "
                  />
                </div>

                <div className="col-lg-12 col-md-12">
                  <ul className="check-square">
                    <li className="">
                      Assessment & Planning: Comprehensive evaluation of your
                      existing infrastructure to identify opportunities and
                      risks.
                    </li>
                    <li className="">
                      Strategic Roadmap: Customized migration strategy aligned
                      with your business objectives.
                    </li>
                    <li className="">
                      Seamless Execution: Expertise-driven implementation with
                      minimal disruption.
                    </li>
                    <li className="">
                      Post-Migration Support: Ongoing monitoring, optimization,
                      and technical support.
                    </li>
                    <li className="">
                      Transform your legacy systems. Elevate your business.
                      Choose MetaQualt for smarter, faster, and future-ready
                      application migration.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="text-center relative">
                <img className="main" src={mean_stack1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <OurServices />

      <WhyChoose />

      <div className="accordions pb-50 pt-80">
        <div className="container">
          <FAQHeader data={faqDescriptions["Application_Migration_Services"]} />

          <AccordionCards data={accordions.Application_Migration_Services} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default application_migration;
