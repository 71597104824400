import microsoft from "../../assets/img/about/microsoft.webp";
import mean from "../../assets/img/about/mean_stack.png";
export const detailData = [
  {
    id: 1,
    title: "Beautiful Sunset",
    img: microsoft,
  },
  {
    id: 2,
    title: "Majestic Mountains",
    img: mean,
  },
];
