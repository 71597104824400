import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../Common/SectionTitle';
import SingleProject from './SingleProject';
import Fintech from './fintech';
import Healthcare from './healthcare';
import Travel from './travel';
import Real_estate from './real-estate';
import Business_app from './business_app';
import Fitness from './fitness';






import projectImgP1 from "../../assets/img/project/P12.webp";
import projectImgP13 from "../../assets/img/project/P13.webp";

import projectImgP3 from "../../assets/img/project/P3.webp";
import projectImgP4 from "../../assets/img/project/business_app.webp";
import projectImgS3 from "../../assets/img/project/real-estate.webp";

import projectImgP7 from "../../assets/img/project/fitness_app.webp";
import projectImgP11 from "../../assets/img/project/travel_app.webp";


const Project = () => {
  const options = {
    items: 3,
    nav: true,
    dots: false,
    margin: 30,
    rewind: false,
    autoplay: false,
    stagePadding: 30,
    loop: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    center: false,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        nav: false,
      },
      768: {
        items: 2,
        nav: false,
        stagePadding: 0,
      },
      992: {
        items: 2,
        nav: false,
        stagePadding: 0,
      },
      1200: {
        items: 3,
        nav: true,
        stagePadding: 0,
      },
      1500: {
        items: 3,
        stagePadding: 0,
      },
    },
  };

  return (
    <React.Fragment>
      <div
        id="rs-portfolio"
        className="rs-project style1 pt-80 pb-50 md-pt-80 mb-50 home_inner"
      >
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-44 md-mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Top Industries We Serve"
            titleClass="title"
            title="We Offer The Most Client-Satisfying Services In Varied Verticals."
            descClass="descr"
            description="We cover all commercial sectors when it comes to reinventing company operations by giving clever solutions that work. Your e-commerce gets a buoy up by our methodical, reliable, and creative solutions."
            effectClass="heading-line"
          />
          <OwlCarousel options={options}>
            <Fintech
              itemClass="project-item"
              projectImage={projectImgP1}
              Title="FINTECH"
            />
            <SingleProject
              itemClass="project-item"
              projectImage={projectImgP3}
              Title="E-COMMERCE"
            />

            <Healthcare
              itemClass="project-item"
              projectImage={projectImgP13}
              Title="HEALTHCARE"
            />
            <Travel
              itemClass="project-item"
              projectImage={projectImgP11}
              Title="TRAVEL"
            />
            <Real_estate
              itemClass="project-item"
              projectImage={projectImgS3}
              Title="REAL-ESTATE"
            />
            <Business_app
              itemClass="project-item"
              projectImage={projectImgP4}
              Title="BUSINESS APP"
            />
            <Fitness
              itemClass="project-item"
              projectImage={projectImgP7}
              Title="HEALTH & FITNESS"
            />
          </OwlCarousel>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Project;
