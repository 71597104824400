import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons
import serviceImg15 from '../../assets/img/service/style9/salesforce1.png';
import serviceImg16 from '../../assets/img/service/style9/salesforce2.png';
import serviceImg14 from '../../assets/img/service/style9/salesforce5.png';
import serviceImg13 from '../../assets/img/service/style9/salesforce4.png';
import serviceImg11 from '../../assets/img/service/style9/salesforce3.png';
import serviceImg12 from '../../assets/img/service/style9/salesforce6.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.webp';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-80 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text style-bg"
                    subtitle="Salesforce Industry"
                    titleClass="title new-title"
                    title="Boost Your Business with Expert Salesforce CRM Consulting | MetaQualt"
                    descClass="desc"
                    description="We take the time to understand your business needs, designing tailored solutions that streamline processes, reduce the tech burden, and maximize ROI. With MetaQualt, we’re not just advising—we’re building your digital future, empowering your business to stay ahead in an ever-changing technological landscape. "
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg14}
                            Title="Salesforce Enablement
"
                            
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg13}
                            Title="Salesforce Platform-as-a-Service
"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg11}
                            Title="Strategy, Vision & Governance" 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg12}
                            Title=" Integration Expertise"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width  bg-light-green"
                            serviceImg={serviceImg15}
                            Title="Salesforce integrations – connectors, APIs, & custom development

"
                            
                        />
                    </div>  <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg16}
                            Title="AppExchange & 3rd party product integration & operation

"
                            
                        />
                    </div>
                    
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

