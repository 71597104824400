import React from "react";
import OurServices from "./weProvide";

import SectionTitle from "../../components/Common/SectionTitle";

import WhyChoose from "./whychooseus";


// Service Icons
import stack from "../../assets/img/about/SaaSApplication2.webp";
import bannerImg from "../../assets/img/about/SaasApplication3.webp";

import ConsultNowBtn from "../../components/ConsultNowBtn";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";

const SaasDevMain = () => {
  return (
    <React.Fragment>
      {/* <!-- banner section end --> */}
      <div id="rs-about" className="rs-about row_rev_sec pt-150 pb-20 md-pt-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="text-center relative">
                <img className="main" src={bannerImg} alt="" />
              </div>
              <div
                className="banner-img absolute"
                style={{ top: 25, left: 0 }}
              ></div>
            </div>
            <div className="col-lg-6 col-md-12">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 pb-30 pt-0 md-pt-10 md-pb-20"
                subtitleClass="sub-text style-bg"
                subtitle="Hiring"
                titleClass="title pb-25"
                title="MetaQualt's SaaS Development Expertise
                                "
                descClass="desc mb-25"
                description="Managing IT infrastructure can be a daunting task for many businesses, especially when it’s not your core focus. That’s where SaaS (Software as a Service) steps in as a game-changer—offering powerful, scalable software solutions without the hassle of ongoing maintenance or infrastructure management.
                                "
                secondDescClass="mb-10 pb-16"
                secondDescription="At MetaQualt, we don’t just build SaaS applications—we engineer seamless digital experiences. From the initial spark of an idea to implementation, deployment, and ongoing support, we ensure every stage is executed with precision and clarity.
With years of hands-on experience in SaaS product development, we’ve helped businesses across the globe launch secure, high-performing SaaS solutions that drive growth, reduce operational complexity, and deliver unmatched user experiences.
Partner with MetaQualt to turn your SaaS vision into a reality—secure, scalable, and future-ready.

                                "
              />
              <ConsultNowBtn />
            </div>
          </div>
        </div>
      </div>

      <div className="style3 pt-100 pb-100 md-pt-40 md-pb-40 provide_sec">
        <div className="container relative">
          <div className="sec-left">
            <h2 className="title"> What We Provide</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div className="row align-items-left">
                <div className="col-lg-12 col-md-12">
                  <SectionTitle
                    sectionClass="sec-title2 text-left mb-30"
                    subtitleClass="sub-text gold-color"
                    subtitle=""
                    titleClass="title pb-10"
                    title="Unlock Potential with MetaQualt’s SaaS Solutions"
                    descClass="desc"
                    description="In today’s fast-paced digital world, a powerful SaaS application isn’t just a tool—it’s the backbone of business innovation. At MetaQualt, we specialize in crafting robust, intuitive, and cloud-powered SaaS solutions designed to deliver exceptional performance, seamless scalability, and an unparalleled user experience.
From ideation to deployment, our end-to-end SaaS development services are tailored to reduce upfront costs, accelerate time-to-market, and ensure your product stands out in an increasingly competitive landscape.

                                        "
                  />
                </div>

                <div className="col-lg-6 col-md-12">
                  <ul className="check-square">
                    <li className="">SaaS Development Consulting</li>
                    <li className="">SaaS Platform Development</li>
                    <li className="">SaaS Integration Platforms</li>
                    <li className="">Cloud Tech Migration & Re-engineering</li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-12">
                  <ul className="check-square">
                    <li className="">SaaS Mobile App Development</li>
                    <li className="">SaaS App Design & Prototyping</li>
                    <li className="">Support & Maintenance</li>
                    <li className="">Performance Optimization</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="text-center relative">
                <img className="main" src={stack} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <OurServices />

      <WhyChoose />

      <div className="accordions pb-50 pt-80">
        <div className="container">
          <FAQHeader data={faqDescriptions["SaaSApplicationDevelopment"]} />

          <AccordionCards data={accordions.SaaS_Application_Development} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SaasDevMain;
