import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProjectTwo from '../../components/phpDevelopment/SingleProjectTwo';


import projectImg7 from '../../assets/img/project/7.webp';
import projectImg8 from '../../assets/img/project/8.webp';
import projectImg9 from '../../assets/img/project/9.webp';
import projectImg10 from '../../assets/img/project/10.webp';

import projectImg17 from '../../assets/img/bg/case-study.webp';


const Technologies = () => {

    const options = {
        items: 4,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 0,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: true,
            },
            768: {
                items: 2,
                nav: true,
            },
            1200: {
                items: 3,
                nav: true,
            },
            1366: {
                items: 3,
                nav: true,
            },
            1600: {
                items: 4,
                nav: true,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-case-study pt-0 pb-0">
                <div className="">
                    <div className="row margin-0 align-items-center">
                        <div className="col-lg-4 padding-0 md-mb-50">
                            <div className="case-study" style={{ backgroundImage: `url(${projectImg17})` }}>
                                <SectionTitle
                                    sectionClass="sec-title2 mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Our Expertise"
                                    titleClass="title testi-title white-color pb-20"
                                    title="The services of Front-end developers."
                                    descClass="desc-big"
                                    description="Your brand encounters a front-end team that walks the extra mile then doing just a few lines of coding as any typical development company does. Your website is your online presence, and we make sure it is striking and provides your clients with an inspiring user experience."
                                />
                            </div>
                        </div>
                        <div className="col-lg-8 padding-0">
                            <div className="case-study-wrap">
                                <div className="rs-project style3 modify1 mod md-pt-0">
                                    <OwlCarousel options={options} >
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg8} 
                                            Title="AngularJS Development"
                                            Text="One of the most popular options for a component-based frontend is the typescript-based framework.
                                            "
                                            projectURL="./angularJs-development"
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg9} 
                                            Title="JavaScript Development"
                                            Text="Your organization is all set to boom with our JavaScript development service. You get nothing less than a snappy, responsive, and feature-rich app." 
                                            projectURL="./javaScripts-development"
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg10} 
                                            Title="ReactJS Development" 
                                            Text="For you, we leverage the UI-centric library of React.JS to come up with highly innovative, dynamic, and intuitive applications." 
                                            projectURL="./reactJs-development" 
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg7} 
                                            Title="VueJS Development"
                                            Text="We pick a delicate, multifaceted, feature-rich, and scalable  Vue.JS platform to give you a future-proof solution." 
                                            projectURL="./vueJs-development"
                                        />
                                       
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </React.Fragment>
	)
}

export default Technologies;