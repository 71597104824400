import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import CTA from '../../components/Common/CTA';
// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.webp';
import mainIcon55 from '../../assets/img/service/style2/main-img/55.webp';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.webp';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.webp';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.webp';
import mainIcon48 from '../../assets/img/service/style2/main-img/48.webp';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-0 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Solution"
                    titleClass="title testi-title text-white"
                    title="Enhance Your Salesforce Experience with MetaQualt’s Lightning Services"
                    descClass="desc text-dark"
                    description="At MetaQualt, we transform your Salesforce experience by crafting bespoke Lightning solutions designed to enhance productivity, streamline workflows, and deliver exceptional results."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Salesforce Lightning Consulting"
                           Text="We turn your vision into reality with customised Salesforce Lightning strategies. From UX optimisation and Lightning Component implementation to advanced workflow automation, we design practical solutions tailored to your needs.
"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon55}
                            hoverIcon={mainIcon55}
                            Title="Salesforce Lightning App Development"
                           Text="Using Lightning components as reusable building blocks, we create bespoke, scalable applications for startups and enterprises alike. Our apps are engineered for performance, mobility, and user satisfaction."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Lightning Readiness Check"
                           Text="As certified Salesforce Lightning experts, we evaluate your current platform’s performance, ensuring seamless compatibility and customisations. Our readiness assessment re-engineers your system to deliver unmatched value.
"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Lightning Process Automation"
                           Text="Say goodbye to manual processes. Using Lightning Process Builders, we automate workflows, optimise operations, and drive higher ROI by channelising sales efforts more effectively.
" />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Salesforce Lightning Migration"
                            Text="Whether migrating from Salesforce Classic or another platform, we ensure a secure, smooth transition to Salesforce Lightning. Our process is designed for zero data loss, no downtime, and immediate results." />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon48}
                            hoverIcon={mainIcon48}
                            Title="Lightning Experience Implementation & Deployment"
                            Text="Looking to start fresh with Salesforce Lightning? Our experts implement and deploy the platform using cutting-edge features that boost productivity, empower teams, and deliver measurable business outcomes."/>
                    </div>
                </div>
            </div>
           
        </div>
    );
}

export default WhyChoose;