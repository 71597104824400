import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons
import serviceImg14 from '../../assets/img/service/26.webp';
import serviceImg13 from '../../assets/img/service/25.webp';
import serviceImg11 from '../../assets/img/service/27.webp';
import layerIcon from '../../assets/img/service/style9/layer-icon.webp';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text gold-color"
                    subtitle="Hire Best Resources"
                    titleClass="title new-title"
                    title="Our Flexible Hiring Models"
                    descClass="desc"
                    description="You will hardly meet such a flexible Node.JS team as ours. Hire us as per your project needs, budget, and time. You are in control of how quickly you want to get your project done. Go through our hiring models. You can change the hiring model at any point in the development process."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-blue"
                            serviceImg={serviceImg14}
                            Title="Full Time Hiring"
                            Text="9 Hours Per Day & Minimum 30 days"
                        />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-green"
                            serviceImg={serviceImg13}
                            Title="Part Time Hiring"
                            Text="4 Hours Per Day & Minimum 30 days"
                        />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <SingleServiceSix 
                            itemClass="services-item bg-light-red"
                            serviceImg={serviceImg11}
                            Title="Hourly Time Hiring" 
                            Text="Flexible hrs Per Day & Minimum 50 hrs"
                        />
                    </div>
                    
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

