import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";

import bannerImg from "../../assets/img/banner/arti.webp";

import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";



// Service Icons
import imgMain40 from "../../assets/img/service/style2/main-img/ai11.webp";
import imgMain41 from "../../assets/img/service/style2/main-img/ai12.webp";
import imgMain42 from "../../assets/img/service/style2/main-img/ai13.webp";
import imgMain43 from "../../assets/img/service/style2/main-img/ai15.webp";
import imgMain28 from "../../assets/img/service/style2/main-img/ai14.webp";
import imgMainai16 from "../../assets/img/service/style2/main-img/ai16.webp";

// Service Image Icons
import experiencedai from "../../assets/img/service/style2/main-img/ai1.webp";
import experiencedai1 from "../../assets/img/service/style2/main-img/ai2.webp";
import experiencedai2 from "../../assets/img/service/style2/main-img/ai3.webp";



import effectImg2 from "../../assets/img/about/dotted-3.webp";
import effectImg3 from "../../assets/img/about/shape3.webp";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import { CounterSection } from "../../components/CounterSection/Data";

import { Feature_Item } from "../../components/CounterSection/Data";

import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";

const artificial_intelligence_sec = () => {
  let data = CounterSection.artificialintelligence[0];
  const featureData = Feature_Item.artificialintelligence;
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-6 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="AI Innovation Hub"
                titleClass="title pb-25"
                title="Top-Tier AI Development Company
                                "
                descClass="desc pb-5"
                description="Elevate your AI journey with Metaqualt’s specialized development services, uniquely crafted to meet your project’s specific needs. From custom AI strategies for business intelligence and advanced analytics to Natural Language Processing (NLP) and scalable AI model development, Metaqualt is your trusted partner for unparalleled AI expertise and talent."
                secondDescClass="desc pb-16"
              />
              <ConsultNowBtn />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>
      <div className="rs-process style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Guiding Innovation"
            titleClass="title pb-10"
            title="Metaqualt AI Development Services: Guiding Your Transformation"
            descClass="desc"
            description="Metaqualt’s team of AI experts supports businesses through every stage of their AI transformation. Our comprehensive AI solutions empower organizations to explore innovative AI possibilities and build robust models aligned with their unique goals.
                        "
            effectClass="heading-line"
          />

          <div className="rs-services style3 modify2 pt-20">
            <div className="row">
              <div className="col-lg-6  mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain40}
                  HoverImg={imgMain40}
                  Title="Tailored AI Application Development"
                  Text="From virtual assistants and customer service chatbots to fraud detection, our AI application development services are designed to meet project-specific requirements, ensuring seamless integration and optimal impact. 
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain41}
                  HoverImg={imgMain41}
                  Title="Metaqualt: Your AI Development Partner"
                  Text="At Metaqualt, we don’t just create AI solutions; we pioneer groundbreaking AI possibilities that align with your goals. Our reputation as a leading AI company is built on excellence, customization, and dedicated support.
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain42}
                  HoverImg={imgMain42}
                  Title="Data Engineering Solutions for AI"
                  Text="Our data engineering approach spans data collection, cleaning, ETL processes, and big data handling to establish solid data models. This foundation is key to successful AI deployment in today’s data-centric environment.
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain28}
                  HoverImg={imgMain28}
                  Title="Predictive Analytics Solutions"
                  Text="Using advanced statistical models and machine learning algorithms, Metaqualt’s predictive analytics solutions empower businesses to forecast outcomes, seize opportunities, and optimize strategies. From time series and demand forecasting to tailored needs, we cover it all."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain43}
                  HoverImg={imgMain43}
                  Title="Advanced Natural Language Processing (NLP)"
                  Text="Metaqualt leverages cutting-edge NLP techniques to deliver text analysis, multilingual support, chatbot solutions, and conversational AI, enhancing user experiences, streamlining operations, and automating routine tasks with efficiency and precision.
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMainai16}
                  HoverImg={imgMainai16}
                  Title="Seamless AI Integration Services"
                  Text="Our AI integration services are secure and tailored, from API integrations to cloud enablement. Metaqualt’s integration solutions help businesses enhance existing systems, boost productivity, and maximize AI potential.
                                    "
                  btnClass="d-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Process"
            titleClass="title title"
            title="Looking for the Best AI Solutions? Our Experts Are Ready to Take Your Business to the Next Level.
                            "
            effectClass="heading-line"
            descClass="desc w-80"
            description="Our experts master the development of Artificial Intelligence solutions that show more innovation and efficiency. In case you aspire to inject AI into your business processes, you've come to the right place. We offer advanced AI services to empower your business and make your operations better."
          />
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai}
                Title="Data-Informed Insights"
                Text="From Metaqualt, we leverage AI to make raw data actionable insights. Our AI solutions involve integrating machine learning and predictive analytics in order to enable you in making well-informed data-driven decisions that result in maximum business efficiency and growth."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai1}
                Title="Customized AI Solutions"
                Text="We closely co-operate with you and comprehend your business needs so that we can provide you with tailored AI solutions. Be it automating processes, improving customer experiences, or optimizing operations, our team designs an AI system that fits perfectly in line with your goals and challenges."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai2}
                Title="Future-Ready AI"
                Text="Metaqualt designs AI solutions that scale with business. Combining the newest technology and strategic foresight, it ensures that the corresponding growth of your business is matched in terms of the evolution of your AI systems so that you stay top of the game with innovative solutions for tomorrow."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="accordions pb-50">
        <div className="container">
          <FAQHeader data={faqDescriptions["ai-development"]} />

          <AccordionCards data={accordions.artificial_intelligence} />
        </div>
      </div>

      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
        ctaTitleClass="epx-title"
        ctaTitle="Hire Our AI Experts and Revolutionize Your Business"
        ctaTextClass="exp-text"
        ctaText="Transform your ideas into intelligent solutions with artificial intelligence. From smart automation and predictive analytics-innovative scalable models towards improving your business processes-our AI experts create."
        ctaText2="Experience growth, efficiency, and success: with our personalized AI solutions to your goals and endless possibilities."
        cta2TextClass="exp-text mannage"
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Get In Touch"
      />
    </React.Fragment>
  );
};

export default artificial_intelligence_sec;
