import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.webp';


const DevelopmentServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 gray-bg pt-50 pb-50">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text style-bg"
                        subtitle="Technologies We are working with"
                        titleClass="title pb-10"
                        title="We Leverage the most creative and strategic ways of Web Development"
                        descClass="desc"
                        description="We offer you a wide range of Web Development Services at frugal rates. Take a look at them."
                        effectClass="heading-line"
                />
                <div className="row align-items-center">

                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Web Design"
                                    Text="Based on your unique design needs, brand guidelines, and goals, our web designers will hit the ground running to deliver appealing yet highly converting designs.
                                    "
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Back-end Web Development"
                                    Text="Connect with our agile experts who are well-versed in leading backend technologies like Flask, NodeJS, Laravel, Django, Ruby on Rails, and more."
                                />
                            </div>
                            
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Front-end Web Development"
                                    Text="Our expertise in HTML, CSS, and Javascript helps us turn your web development ideas into reality. Our analytical minds go to great lengths to develop the best software.                                    "
                                />
                            </div>
                           
                        </div>
                    </div>
                   
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Full-cycle Web Development"
                                    Text="Right from creative ideation and development to designing and maintenance, you are a part of our friendly team, who is willing to support you at any step."
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="Enterprise Solution Development"
                                    Text="With our Online Development Agency, you can rely on the best specialists to create affordable enterprise-class web solutions.
                                    "
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="Maintenance & Support"
                                    Text="We are the family doctors of your website. We keep a sharp eye on your website’s health and provide 24/7 support and timely maintenance."
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DevelopmentServices;