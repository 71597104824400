import React from "react";
import BannerTwo from "../../components/Banner/BannerTwo";
import Service from "./ServiceSection";
import About from "./AboutSection";
import ServiceFlip from "./ServiceSectionFlip";

import Process from "./ProcessSection";
import Project from "../../components/Project";


import CTA from "../../components/Common/CTA";

import ScrollToTop from "../../components/Common/ScrollTop";
import SectionTitle from "../../components/Common/SectionTitle";
import ServicesTabs from "../../components/Elements/Tab/Services";

const HomeThreeMain = () => {
  return (
    <React.Fragment>
      <BannerTwo />

      <About />

      <Service />

      <ServiceFlip />

      <Project />

      <Process />

      <div className="rs-process style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-50"
            subtitleClass="sub-text style-bg"
            subtitle="TECHNOLOGY INDEX"
            titleClass="title pb-10"
            title="Technologies We Adopt To Scale Our Customer’s Brand."
            descClass="desc"
            effectClass="heading-line"
          />
          <ServicesTabs />
        </div>
      </div>

      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-120 pb-80"
        ctaTitleClass="epx-title"
        ctaTitle="Hire Razor-Sharp Alpha Geeks For Your Web Development Project"
        ctaTextClass="exp-text"
        ctaText="Let your ideas traverse the right stream of brainstorming, designing, deployment, and delivery to turn them into irresistible web apps. With our web app masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision."
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Get In Touch"
      />

      <ScrollToTop />
    </React.Fragment>
  );
};

export default HomeThreeMain;
