import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.webp';
import processImage13 from '../../assets/img/process/13.webp';
import processImage14 from '../../assets/img/process/14.webp';
import processImage15 from '../../assets/img/process/15.webp';

// Service Icons
import imgMain28 from '../../assets/img/service/style2/main-img/28.webp';
import imgMain29 from '../../assets/img/service/style2/main-img/29.webp';
import imgMain33 from '../../assets/img/service/style2/main-img/33.webp';
import imgMain13 from '../../assets/img/service/style2/main-img/13.webp';
import imgMain30 from '../../assets/img/service/style2/main-img/30.webp';


// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.webp';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.webp';
import serviceIcon4 from '../../assets/img/service/style2/main-img/4.webp';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.webp';

import effectImg2 from '../../assets/img/about/dotted-3.webp';
import effectImg3 from '../../assets/img/about/shape3.webp';
// Service Icons


const phpDevelopment = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                    
                        <div className="col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text gold-color"
                                subtitle="Our Laravel Expertise"
                                titleClass="title pb-25"
                                title="Agile Methodology Underpins Our Laravel Development Services."
                                descClass="desc pb-5"
                                description="If you want to lubricate your business app with the most resource-rich and performance-driven Laravel framework, our experts will help you out."
                                secondDescClass="desc pb-16"
                                secondDescription="Our exceptional geeks develop impressive websites using Laravel, an open-source and free framework for PHP-based web applications. Team up with reliable, friendly, and customer-centric developers to kickstart your business venture. 
                                "
                            />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-50 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Expertise"
                        titleClass="title pb-10"
                        title="A Good Interface makes your Business look smart. A Professional Laravel Development Service helps it Grow.    
                        "
                        descClass="desc"
                        description="Do you want to create a modernized website-cum- digital carnival, where millions of excited users show up? You should choose the Laravel development service offering plenty of benefits. Our developers make the most of Laravel’s migration options to create a stunning platform that suits your business model. 
                        "
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-40">
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain28}
                                    HoverImg={imgMain28}
                                    Title="Custom Laravel Development"
                                    Text="If you want to be on top of your web game, clasp hands with our Laravel development experts for custom web apps with excellent, industry-specific features & design. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain29}
                                    HoverImg={imgMain29}
                                    Title="Laravel Enterprise Apps"
                                    Text="We are always in readiness to provide tailor-made Laravel enterprise app solutions that align with your business needs. To make it happen, we utilize advanced tools and technologies. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain33}
                                    HoverImg={imgMain33}
                                    Title="Laravel e-Commerce Apps"
                                    Text="Our expertise in the Laravel framework makes us the leading app makers for all industries. e-Commerce isn’t an exception. You can amp up your e-commerce pursuit with our Laravel developers who provide a scalable app with kick-ass performance and a vibrant e-shopping experience. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain13}
                                    HoverImg={imgMain13}
                                    Title="Maintenance & Support"
                                    Text="Are you worrying about the longevity of your website? Ease your mind as our team takes care of the site’s post-development. From essential upgrades to a strong defense against bugs, we provide apt maintenance, security, and support. 

                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain30}
                                    HoverImg={imgMain30}
                                    Title="Laravel Data Migration"
                                    Text="Businesses evolve. So must websites. We provide comprehensive Laravel data migration solutions to help your website upgrade to the latest version. 


                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="As a Trusted Laravel Developer, Our Team Provides Adroit Services."
                                    effectClass="heading-line"
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
{/* 
            <Technologies /> */}

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="Why work with us?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="Year-long Expertise"
                                Text="Your business meets the perfect Laravel development service. Our year-long expertise serves businesses of all sizes and niches. 
                                "
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon1}
                                Title="Customer-Centric" 
                                Text="After the development of our clients' websites has been completed, we continue to support the sites consistently & proactively." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon4}
                                Title="Futuristic Development" 
                                Text="The Laravel apps we build follow a futuristic development approach to ensure that they stand the test of time." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon3}
                                Title="Dedicated Team" 
                                Text="Our Laravel app developers and designers are thorough experts in all the features, skills, and tools of the platform." 
                            />
                        </div>
                    </div>
            </div>
        </div>
            {/* rs-services-area-start */}
            
              {/* rs-servicess-area-start */}

           {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            
            {/* rs-services-area-start */} 


            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-50"
				ctaTitleClass="epx-title"
				ctaTitle="Employ Dedicated Laravel Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default phpDevelopment;