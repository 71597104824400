import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/Salesforceimpl.webp';

const TechSlider = () => {
    return (
        <React.Fragment>
            <div className="bg18 provide_sec style3 pt-100 pb-100 md-pt-80 md-pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title text-white">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 text-white"
                                    title="Transform Your Business with MetaQualt’s Salesforce Implementation"
                                    descClass="desc text-white"
                                    description="At MetaQualt, we deliver tailored Salesforce implementation services designed to elevate your business. From Salesforce consulting and configuration to customization, data migration, system integration, and ongoing support, we provide end-to-end solutions that seamlessly align with your unique needs."
                                    secondDescClass="desc mb-10 pb-16 text-white"
                                    secondDescription="With over a decade of expertise in CRM development and a proven track record of success, we specialize in implementing reliable Salesforce solutions that drive growth, optimize operations, and transform how you connect with customers. Whether it’s empowering your sales, enhancing your customer service, or unlocking new possibilities in marketing, MetaQualt ensures your Salesforce investment delivers measurable results.
  "                                
                        
                                />
                                
                                </div>
                                <div className='col-lg-12 col-md-12'>
                                    <h5 className="text-white">Why Choose MetaQualt? </h5>
                                    <ul className="check-square">
                                        <li className="text-white">Comprehensive Salesforce consulting services to ensure a smooth and impactful implementation.</li>
                                        <li className="text-white">Scalable customization options tailored to your industry and goals.</li>
                                        <li className="text-white">Enhancing user experiences with intuitive design and functionality.</li>
                                        <li className="text-white">Expertise in seamless Salesforce migration and integration with existing systems.</li>
                                        <li className="text-white">Dedicated support to keep your systems running flawlessly.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default TechSlider;