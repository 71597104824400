import React, { useState } from "react";

const FileInput = () => {
  const [file, setFile] = useState([]);;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <>
      <div className="p-4  from-control input_type">
        <label
          htmlFor="fileInput"
          className="text-black   rounded-lg px-3 p5 file_sec"
        >
          <span className="text-black">Choose File</span>
        </label>
        <span className="text-black pl-2">{file.name}</span>
        { file.length === 0 && <span className="text-black pl-2">Attach CV</span>}
        <input
          type="file"
          id="fileInput"
          onChange={handleFileChange}
          accept=".docx, .odt, .ods, .ppt, .pptx, .xls, .xlsx, .rtf, .txt, .pdf"
          className="d-none"
        />
      </div>
    </>
  );
};

export default FileInput;
