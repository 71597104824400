import React from "react";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Footer from "../../components/Layout/Footer";
import Microsoft_dev_m from "../../components/Common/Breadcumb/MicrosoftNETDev";
import Microsoft_dev_sec from "./microsoftdev_Main";
import ScrollToTop from '../../components/Common/ScrollTop';
// Breadcrumbs Background Image
import bannerbg from "../../assets/img/banner/banner-6.webp";

const Microsoft_dev = () => {
  return (
    <React.Fragment>
      <HeaderStyleThree
        parentMenu="page"
        secondParentMenu="services"
        activeMenu="/services"
      />
      <div className="web_dev_sec">
        <Microsoft_dev_m
          pageTitle="Best-in-class Services In Microsoft Technologies"
          titleClass="page-title"
          pageName="Microsoft.Net  
"
          breadcrumbsImg={bannerbg}
          animatedFont="lined-Font dance2"
          animatedFontsize="Hire dedicated full-time .Net developers."
        />

        {/* ServiceOneMain */}
        <Microsoft_dev_sec />
        {/* ServiceOneMain */}
      </div>
      <ScrollToTop />
      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default Microsoft_dev;
