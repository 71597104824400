import React from "react";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ScrollToTop from "../../components/Common/ScrollTop";


// Breadcrumbs Background Image
import bannerbg from "../../assets/img/banner/banner-6.webp";
import SalesForceExchange from "../../components/Common/Breadcumb/SalesForceExchange.jsx";
import SalesForce_Main from "./SalesForce_Main.jsx";

const Salesforce_AppExchange = () => {
  return (
    <React.Fragment>
      <HeaderStyleThree parentMenu="about" activeMenu="/about" />
      <HeaderStyleThree />
      <main>
        <SalesForceExchange
          pageTitle="Salesforce App Exchange"
          titleClass="page-title"
          pageName="Salesforce Lightning Services"
          breadcrumbsImg={bannerbg}
          animatedFont="lined-Font dance2"
          animatedFontsize="Salesforce App Exchange"
        />

     
        <SalesForce_Main />
      

        <ScrollToTop />
      </main>
      <Footer />
      <SearchModal />
    </React.Fragment>
  );
};

export default Salesforce_AppExchange;
