import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/SalesforceConsultingRooted.webp';

const TechSlider = () => {
    return (
        <React.Fragment>
            <div className="bg18 style3 pt-100 pb-100 md-pt-40 md-pb-40" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title text-white">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 text-white"
                                    title="MetaQualt: Salesforce Consulting Rooted in Your Business Needs."
                                    descClass="desc text-white"
                                    description="Effective Salesforce consulting starts with truly understanding your business and industry. At MetaQualt, our seasoned experts bring hands-on experience to uncover your core challenges and opportunities. Through collaborative brainstorming sessions with key stakeholders, we craft a tailored roadmap for Salesforce implementation, ensuring solutions that align perfectly with your goals and drive meaningful results.
"
                                />
                                </div>
                                <div className='col-lg-6 col-md-6 '>
                                    <ul className="check-square">
                                        <li className="text-white">Analysis Of Current Processes</li>
                                        <li className="text-white">Deep Dive Into The Challenges</li>
                                        <li className="text-white">Center of Excellence (COE)</li>
                                        
                                    </ul>
                                </div>
                                <div className='col-lg-6 col-md-6 pl-0 pr-0'>
                                    <ul className="check-square">
                                        <li className="text-white">Vision & Strategy development</li>
                                        <li className="text-white">Creating A High-Level Architecture</li>
                                        <li className="text-white">Introduction/Realignment Of Salesforce</li>
                                    </ul>
                                </div>

                                <div className='col-lg-12 col-md-6 '>
                                    <ul className="check-square">
                                        <li className="text-white">Connecting You With Salesforce (For New Customers)
                                        </li>
                                        <li className="text-white">Start The Implementation Or Customization</li>
                                        <li className="text-white">Salesforce Org Check (For Existing Salesforce Customers)</li>
                                        <li className="text-white">CRM & marketing automation maturity models</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default TechSlider;