import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
import ScrollToTop from '../../components/Common/ScrollTop';



// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.webp';
import Cloud_App_Dev from '../../components/Common/Breadcumb/Cloud_App_Dev';
import CloudAppDev from './CloudAppDev';


const Cloud_App_Devlopment = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}
               
                <Cloud_App_Dev
                 pageTitle="Cloud Development: Transform your IT ecosystem"
                 titleClass="page-title"
                 pageName="Hire Dedicated Developers in Microsoft Technologies.
"
                 breadcrumbsImg={bannerbg}
                 animatedFont="lined-Font dance2"
                 animatedFontsize="Cloud Product Experts" />
                
                <CloudAppDev />
            
            </div>
            <ScrollToTop />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default Cloud_App_Devlopment;