import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import bannerImg from "../../assets/img/banner/web1.webp";
import TechSliderModels from "./TechSlider";
import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";

// Service Icons
import imgMain7 from '../../assets/img/service/style2/main-img/7.webp';
import imgMain8 from '../../assets/img/service/style2/main-img/8.webp';
import imgMain9 from '../../assets/img/service/style2/main-img/9.webp';
import imgMain11 from '../../assets/img/service/style2/main-img/11.webp';
import imgMain12 from '../../assets/img/service/style2/main-img/12.webp';
import imgMain13 from '../../assets/img/service/style2/main-img/13.webp';



import effectImg2 from '../../assets/img/about/dotted-3.webp';
import effectImg3 from '../../assets/img/about/shape3.webp';
import ConsultNowBtn from '../../components/ConsultNowBtn';

import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";
import FAQHeader from "../../components/FAQHeader/FAQHeader";

const WebDevelopment = () => {
  return (
    <React.Fragment>
      {/* rs-servicess-area-start */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-6 col-md-12 pl-20 md-pl-5">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="What We Do"
                titleClass="title pb-25"
                title="Contact us for the most resilient Websites & Apps

                            Do you fancy a Website that maximizes your profits?
                            "
                descClass="desc pb-16"
                description=""
                secondDescClass="desc pb-16"
                secondDescription="If you need a sleek, sturdy, secure, and customized web app, count on us. We are the leading team of developers having expertise in web technologies like ReactJS, Angular, Node.js, Laravel, Golang, and more. Our ability to use open-source technology to create a crackerjack product makes us stand out. We are geared up to provide custom solutions with appealing designs, high loyalty, and massive engagement for any enterprise, business, or consumer. With us, you are on your way to revolutionizing your digital presence like never before!"
              />
              <ConsultNowBtn />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>

      {/* rs-servicess-area-start */}
      <div className="rs-process style6 pt-50 pb-80">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Our services are at the forefront"
            titleClass="title pb-10"
            title="We cover all present-day Web Development needs."
            descClass="desc"
            description="What if you land on a site that is eye-pleasing, idiot-proof, and  
                        allures you to scroll and dig deeper? What if that site is yours? What if you want your customers to feel the same as you felt now? We will convert your dream ideas into a mind-blowing site. Our team gets the ball rolling by blending modern web development technologies with absorbing designs and interfaces that generate leads. Whether you run a startup or a large eCommerce platform, we give the best we can.
                        "
            effectClass="heading-line"
          />

          <div className="rs-services style3 modify2 pt-20 md-pt-50">
            <div className="row">
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain11}
                  HoverImg={imgMain11}
                  Title="Custom Web Development"
                  Text="We believe that each business is unique. To preserve this uniqueness, we give exclusive attention to each client’s requirements and build a trustworthy and sleek website. We develop a custom and dynamic platform to showcase your talents in the digital domain.  "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain7}
                  HoverImg={imgMain7}
                  Title="CMS Website Development"
                  Text="Are you a blogger willing to share your work via the best CMS platform, WordPress? Our techies build top CMS platforms like WordPress, Drupal, and more. They are top dogs in languages like .NET and .PHP that can build these famed CMS platforms. Make the most of WordPress, which features numerous plugins. Take your brand/blog to new heights."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain8}
                  HoverImg={imgMain8}
                  Title="Enterprise Web Development"
                  Text="Are you in the serious business of generating ROI via your website? Our team sets up a perfect B2B, B2C, intranet, or media website. MetaQualt boasts some of the most experienced developers who develop sites with a high conversion rate. "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain12}
                  HoverImg={imgMain12}
                  Title="Website Support & Maintenance"
                  Text="We provide support and maintenance to make sure your site stays as fresh as a daisy. We responsibly keep the site at optimal health by adding new features, managing the server, and implementing timely changes."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain9}
                  HoverImg={imgMain9}
                  Title="eCommerce Web Development"
                  Text="Are you sick of a website that’s just a weeny speck amid the crowd of websites in the digital realm? A website that doesn’t generate sales. Don’t be patient anymore. Reach out to us. Our eCommerce websites are sales-driven. We have a highly motivated team of Shopify, Magento, Bigcommerce, PHP, NET, open source, and PHP developers to scale your eCommerce store. "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain13}
                  HoverImg={imgMain13}
                  Title="OpenSource Web Development"
                  Text="If you want to speed up your web development venture, rely on us. We harness the power of open-source platforms like WordPress, Drupal, and Joomla to create a website fast and as neat as a pin. We also deliver hassle-free security and version updates to you. "
                  btnClass="d-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <TechSliderModels />

      <div className="accordions pb-50 pt-80">
        <div className="container">
          <FAQHeader data={faqDescriptions["Web-development"]} />

          <AccordionCards data={accordions.Web_Development} />
        </div>
      </div>

      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-50"
        ctaTitleClass="epx-title"
        ctaTitle="Hire The Dedicated Web Developers"
        ctaTextClass="exp-text"
        ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Get In Touch"
      />
    </React.Fragment>
  );
};

export default WebDevelopment;
