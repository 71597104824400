import React from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { detailData } from "./detailData"
const DetailPage = () => {
    const { cardId } = useParams();

    const Card = detailData.find((b) => b.id === Number(cardId));
  return (
    <div>
        <h3>MindInventory Garba Night 2024</h3>
        <img src={Card.img} alt="" />
    </div>
  )
}

export default DetailPage