import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';



// Service Image Icons
import mainIconM1 from '../../assets/img/service/M1.webp';
import mainIconM2 from '../../assets/img/service/M2.webp';
import mainIconM3 from '../../assets/img/service/M3.webp';
import mainIconM4 from '../../assets/img/service/M4.webp';
import mainIconM5 from '../../assets/img/service/M5.webp';
import mainIconM6 from '../../assets/img/service/M6.webp';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Partner with Dedicated.NET"
                    titleClass="title testi-title text-white"
                    title="Partner With .NET Experts For Reliable And Innovative Solutions."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIconM1}
                            hoverIcon={mainIconM1}
                            Title="Healthcare Solutions Designed for Better Care
                            "
                           
                            Text="We specialize in building reliable healthcare web applications using ASP.NET to meet the unique needs of the industry. Our skilled.NET developers deliver creative, results-driven solutions to simplify billing, streamline appointments, improve workflows, and boost efficiency."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIconM2}
                            hoverIcon={mainIconM2}
                            Title="Banking and Finance Solutions Built for Impact"
                            
                            Text="In banking and finance, reliable software is crucial. Our .NET developers leverage IoT, blockchain, and big data to build secure, efficient web apps for data analytics, payment tracking, budgeting, and forecasting—tailored to your needs."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIconM3}
                            hoverIcon={mainIconM3}
                            Title="Fintech Solutions that Drive Innovation and Growth"
                           
                            Text="Enhance your fintech operations with custom .NET solutions. We optimize performance, boost engagement, and deliver scalable, data-driven innovations tailored to your business." />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIconM4}
                            hoverIcon={mainIconM4}
                            Title="Retail and eCommerce Solutions that Convert"
                            
                           Text="Today’s customers expect a seamless shopping experience. Our.NET developers specialize in creating intuitive, user-friendly eCommerce apps that make browsing, purchasing, and tracking orders effortless. With powerful features like one-tap payments, we help boost conversion rates and reduce cart abandonment, driving your business forward.
"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIconM5}
                            hoverIcon={mainIconM5}
                            Title="Travel and Tourism Solutions that Enhance Every Journey"
                           
                            Text="Our expert C# developers specialize in creating seamless travel and tourism web interfaces that simplify management for both customers and hotel staff. Streamline operations, automate personalization, and boost conversions—all with one powerful platform. If you’re looking to elevate the customer experience, we’ve got the perfect solution for you.
"
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIconM6}
                            hoverIcon={mainIconM6}
                            Title="Logistics and Transport Solutions for Smarter Operations"
                           Text="Looking to optimize costs, streamline workflows, or boost efficiency? Our transport and logistics web solutions use innovative technologies to meet your business needs. From fleet management to data-driven insights, we cover every aspect of logistics to help your operations run smoothly.
                           "
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;