import React from "react";
import OurServices from "./weProvide";

import SectionTitle from "../../components/Common/SectionTitle";

import WhyChoose from "./whychooseus";





// Service Icons
import mean_stack2 from "../../assets/img/about/cloudapplication2.webp";
import bannerImg from "../../assets/img/about/cloud-services-isometric-composi.webp";

import ConsultNowBtn from "../../components/ConsultNowBtn";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";

const CloudAppDev = () => {
  return (
    <React.Fragment>
      {/* <!-- banner section end --> */}
      <div
        id="rs-about"
        className="rs-about row_rev_sec pt-150 pb-20 md-pt-60 cloud_appli_dev"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="text-center relative">
                <img className="main" src={bannerImg} alt="" />
              </div>
              <div
                className="banner-img absolute"
                style={{ top: 25, left: 0 }}
              ></div>
            </div>
            <div className="col-lg-6 col-md-12">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 pb-0 pt-0 md-pt-10 md-pb-20"
                subtitleClass="sub-text style-bg"
                subtitle="Hiring"
                titleClass="title pb-25"
                title="Drive Growth with MetaQualt’s Cloud Development
                              "
                descClass="desc mb-25"
                description="Partner with Metaqualt, a leading cloud application development company, to create innovative tech solutions that deliver exceptional digital experiences. Cloud computing has revolutionized the IT landscape, and our team of expert developers is at the forefront, building dynamic, scalable, and resilient systems using the latest technologies and methodologies.

                              "
                secondDescClass="mb-10 pb-16"
                secondDescription="

                              "
              />
              <h5>Why Choose Metaqualt for Cloud Application Development?</h5>
              <ul>
                <li>
                  <span>Usage-Based Expenditure:</span> Pay only for what you
                  use, optimizing your budget.
                </li>
                <li>
                  <span>Faster Time-to-Market:</span> Get your products to
                  market quickly and efficiently.
                </li>
                <li>
                  <span>Enhanced Agility:</span> Adapt and evolve with ease as
                  your business needs change.
                </li>
                <li>
                  <span>Lower Infrastructure Costs:</span> Save on costly
                  infrastructure with cloud-based solutions.
                </li>
              </ul>

              <ConsultNowBtn />
            </div>
          </div>
        </div>
      </div>

      <div className="style3 pt-100 pb-100 md-pt-40 md-pb-40 provide_sec">
        <div className="container relative">
          <div className="sec-left">
            <h2 className="title"> What We Provide</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div className="row align-items-left">
                <div className="col-lg-12 col-md-12">
                  <SectionTitle
                    sectionClass="sec-title2 text-left mb-30"
                    subtitleClass="sub-text gold-color"
                    subtitle=""
                    titleClass="title pb-10"
                    title="Cloud Application Development Services by Metaqualt
                                      "
                    descClass="desc"
                    description="Metaqualt’s Cloud application development services focus on creating scalable, device-compatible systems that deliver comprehensive value. We bring your app development projects to life with transformative cloud solutions that maximize efficiency and performance.
With 15 years of technology expertise and deep domain knowledge, our team is dedicated to helping you unlock the full potential of the cloud, ensuring your business stays ahead of the curve. Let Metaqualt empower your app development with cutting-edge, scalable cloud solutions that drive growth and innovation.
At MetaQualt, we deliver top-notch cloud development services tailored to your needs. With our dedicated offshore developers and reliable RelyShore model, we ensure scalable, secure, and efficient solutions to drive your business forward.
                                      "
                  />
                </div>

                <div className="col-lg-6 col-md-12">
                  <ul className="check-square">
                    <li className="">Cloud Assessment Consulting</li>

                    <li className="">SaaS Implementations</li>
                    <li className="">
                      Cloud-Based Web & Mobile App Development
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-12">
                  <ul className="check-square">
                    <li className="">Cloud Integration Services</li>
                    <li className="">Application Migration Services</li>
                    <li className="">Cloud Analytics and AI es</li>
                    <li className="">Cloud Security</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="text-center relative">
                <img className="main" src={mean_stack2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <OurServices />

      <WhyChoose />

      <div className="accordions pb-50 pt-80">
        <div className="container">
          <FAQHeader data={faqDescriptions["Cloud_Application_Development"]} />

          <AccordionCards data={accordions.Cloud_Application_Development} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CloudAppDev;
