import React, { useState } from "react";

import SingleServiceNumber from '../../components/Service/ServiceNumber';
import SectionTitle from '../../components/Common/SectionTitle';
import MobileImg from '../../assets/img/process/work-process.webp';

const Process = () => {


  return (
    <div
      id="rs-portfolio"
      className="rs-process style4 gray-bg pt-80 pb-50 md-pt-80 md-pb-50"
    >
      <div className="container relative Portfolio_home_sec">
        <SectionTitle
          sectionClass="sec-title2 text-center mb-44 md-mb-30"
          subtitleClass="sub-text style-bg"
          subtitle="Process"
          titleClass="title"
          title="Our work Flow!"
          descClass="desc w-80"
          description="To passionately develop and design the best possible solution with help of well-established and modern technologies is our sole aim. We go about development for each web project strategically from consultation to delivery. We ensure you get a superior product at an affordable rate that clings to your values, materializes your ideas, and scales your business."
          effectClass="heading-line"
        />
        <div className="row align-items-center">
          <div className="col-lg-12 col-md-12">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-40">
                <SingleServiceNumber
                  itemClass="rs-addon-number"
                  serviceNumber="1."
                  Title="Discover"
                  Text="Share your concept and we will arrange brainstorming sessions to discover your needs. Our discussions will lead to a blend of trendy and befitting solution that best suits your specifications."
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-40">
                <SingleServiceNumber
                  itemClass="rs-addon-number"
                  serviceNumber="2."
                  Title="Define"
                  Text="Rest assured that your product is on the right track of development. We lay brick after brick to construct your exceptional solution using the latest technologies which we are well-versed in."
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-40">
                <SingleServiceNumber
                  itemClass="rs-addon-number"
                  serviceNumber="3."
                  Title="Design"
                  Text="You get a design that has dual benefits. It not just attracts but it gives a user-friendly experience to the end users. After you talk to us, always expect a highly creative and interactive design. That’s what we have been doing for years!"
                />
              </div>

              <div className="col-lg-4 col-md-6 mb-40">
                <SingleServiceNumber
                  itemClass="rs-addon-number"
                  serviceNumber="4."
                  Title="Develop"
                  Text="We put our conscientious IT specialists to work on your valuable ideas. And each time you get a well-engineered solution that adheres to your brand idea."
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-40">
                <SingleServiceNumber
                  itemClass="rs-addon-number"
                  serviceNumber="5."
                  Title="Deploy"
                  Text="Testing and deploying the software step-by-step implementation process  ensures a smooth deployment process."
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-40">
                <SingleServiceNumber
                  itemClass="rs-addon-number"
                  serviceNumber="6."
                  Title="Delivery 
                                    "
                  Text="Our nifty development brings you software that’s smarter than your boss and more durable than your employees! Additionally, it gets our full attention as we are responsible for its after-launch maintenance."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
