import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.webp';
import processImage13 from '../../assets/img/process/13.webp';
import processImage14 from '../../assets/img/process/14.webp';
import processImage15 from '../../assets/img/process/15.webp';

// tech Icons
import techImg10 from '../../assets/img/technology/style2/10.webp';
import techImg3 from '../../assets/img/technology/style2/3.webp';
import techImg4 from '../../assets/img/technology/style2/3.webp';
import techImg6 from '../../assets/img/technology/style2/6.webp';
import techImg8 from '../../assets/img/technology/style2/8.webp';
import techImg17 from '../../assets/img/technology/style2/17.webp';

// Service Icons
import imgMain50 from '../../assets/img/service/style2/main-img/50.webp';
import imgMain49 from '../../assets/img/service/style2/main-img/49.webp';
import imgMain47 from '../../assets/img/service/style2/main-img/47.webp';
import imgMain48 from '../../assets/img/service/style2/main-img/48.webp';
import imgMain30 from '../../assets/img/service/style2/main-img/30.webp';

// Service Image Icons
import serviceIcon34 from '../../assets/img/service/style2/main-img/34.webp';
import serviceIcon4 from '../../assets/img/service/style2/main-img/4.webp';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.webp';

// Service Icons

import effectImg2 from '../../assets/img/about/dotted-3.webp';
import effectImg3 from '../../assets/img/about/shape3.webp';

const IosAppMainPageContent = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-80 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-7 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text gold-color"
                                subtitle="Make Yours IOS App Differently"
                                titleClass="title pb-25"
                                title="We develop an iOS app with a unique personality "
                                descClass="desc pb-5"
                                description="If you seek a trendy iOS app, hire us! Our team develops a sleek companion smarter than your personal assistant. With extensive knowledge of iOS SDK, frameworks & tools required in iOS app development, you get a super cool iOS solution that perfectly fits your industry and gains global recognition. "
                                secondDescClass="desc pb-16"
                                secondDescription="With our efforts, from consultation to maintenance, watch your iOS solution blossom. After a strategic consultation, our developers set the wheels in motion to develop a best-in-class app. With us, you will see millions of users flocking to your online entity via our feature-rich apps, which we also upgrade frequently. "
                            />
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

           {/* rs-technology-area-start */}
             <div className="rs-technology">
                <div className="container">
                    <div className="row">            
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                    <a href="#">
                                        <div className="logo-img">
                                            <img src={techImg17} alt="" />
                                        </div>
                                    </a>
                                </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                    <a href="#">
                                        <div className="logo-img">
                                            <img src={techImg3} alt="" />
                                        </div>
                                    </a>
                                </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                <a href="#">
                                    <div class="logo-img">
                                        <img src={techImg4} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                <a href="#">
                                    <div class="logo-img">
                                        <img src={techImg8} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item pt-5 pb-5">
                                <a href="#">
                                    <div class="logo-img">
                                        <img src={techImg10} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                <a href="#">
                                    <div class="logo-img">
                                        <img src={techImg6} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-technology-area-start */}
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text gold-color"
                        subtitle="Our iPhone Excellence"
                        titleClass="title pb-10"
                        title="Get a wacky iOS solution with serious results!"
                        descClass="desc"
                        description="Meet an elite squad of designers and strategists to develop an iOS app that is a success behind your business goals. After bringing the app to life, you get a crew of guardians who provide apt support and maintenance. 

                        "
                        effectClass="heading-line"
                    />                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain49}
                                    HoverImg={imgMain49}
                                    Title="iPhone App Development"
                                    Text="Get the lead out! Design, develop, and modify your ideas with us and dominate your niche market. Whether it's an iPhone, iPad, Apple Watch,  Apple Tv, or iOS game app, ping us, and our pointy-heads will give you a next-level iOS solution.  "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain50}
                                    HoverImg={imgMain50}
                                    Title="UI & UX Design Services"
                                    Text="We deliver a customized product that’s easy on the eyes, highly interactive, and grows sales. Sounds too good to be true? Not anymore. You have come across the best team of UI/UX designers who have a keen vision to create an iOS app that scales your business."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain47}
                                    HoverImg={imgMain47}
                                    Title="Quality Assurance & Testing"
                                    Text="That app you proudly use to make profits is put in the furnace of manual and automation tests and industry benchmark practices before its launch. Our skilled in-house testing crew makes sure your app attains optimum quality before it gears up to attract your clients."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain48}
                                    HoverImg={imgMain48}
                                    Title="iPhone Apps Support & Maintenance"
                                    Text="With us, your post-app development is on the right track. 
                                    Our bug repellents keep your app as clean as a whistle. Our support staff tweaks and updates your app for maximum performance and security. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain30}
                                    HoverImg={imgMain30}
                                    Title="App Porting & Migration"
                                    Text="Our custom iPhone mobile application Porting & Migration solution keeps you in sync with the evolving mobile ecosystem." 
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="Professional Excellence Combined with Next-Gen iOS App Development"
                                    effectClass="heading-line"
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}       

            {/* <Technologies /> */}

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="Why opt for our talented developers to build solutions?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon34}
                            Title="Flexible Hiring Models"
                            Text="Our prices are competitive. Our outputs are compelling. Choose from a wide range of flexible hiring models based on your budget and needs and get the most suitable app without any comprise in its quality. "
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon4}
                            Title="Diverse Technologies" 
                            Text="You can contact us to develop an iOS app for any challenging niche under the sun that uses coding skills in Objective-C, Swift, JSON, xCode, Cocoa Framework, Cocos2d, etc. " 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            Title="Best Talent" 
                            Text="A talented entrepreneur like you can be our best companion! Our talent gives you the platform to evolve your digital brand! Meet the iOS app developers skilled in advanced SDKs from Apple and IoT platforms, including Wearables, iBeacon, NFC, etc. 
                            " 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            Title="Best Talent"  
                            Text="A talented entrepreneur like you can be our best companion! Our talent gives you the platform to evolve your digital brand! Meet the iOS app developers skilled in advanced SDKs from Apple and IoT platforms, including Wearables, iBeacon, NFC, etc. 
                            " 
                        />
                    </div> 
                </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            
              {/* rs-servicess-area-start */}
           {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-60 pb-60"
				ctaTitleClass="epx-title"
				ctaTitle="Hire a talented team of iOS App Developers"
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, deployment, design, and support to turn them into irresistible iOS apps. With us, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision. "
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default IosAppMainPageContent;