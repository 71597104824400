import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProjectTwo from '../../components/Project/SingleProjectTwo';

import projectImg1 from '../../assets/img/project/P12.webp';
import projectImg2 from '../../assets/img/project/social_media_app.webp';
import projectImg3 from '../../assets/img/project/p31.webp';
import projectImg4 from '../../assets/img/project/business_app.webp';
import projectImg5 from '../../assets/img/project/real-estate.webp';
import projectImg6 from '../../assets/img/project/P13.webp';
import projectImg7 from '../../assets/img/project/fitness_app.webp';
import projectImg11 from '../../assets/img/project/travel_app.webp';

const Project = () => {

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-project style6 modify2 gray-bg pt-80 pb-80">
                <div className="container">
                    <SectionTitle 
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Grow With Us"
                        titleClass="title title7"
                        title="We are Web and Mobile app development providers"
                        effectClass="heading-line"
                    />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg1} 
                                Title="FINTECH" 
                                Text="No more delayed banking procedures! Switch to MetaQualt for your digital banking processes and enjoy a seamless investing experience." 
                                ProjectURL="./fintech-app-solution"
                            />
                        </div>
                                             
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg3} 
                                Title="E-COMMERCE" 
                                Text="Boost your sales like crazy! Set up an online e-store with us and benefit from its stylish looks and next-level user experience that converts visitors into buyers."
                                ProjectURL="./ecommerce-solutions" 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg4} 
                                Title="BUSINESS APP" 
                                Text="Let your enterprise snowball. Increased revenue and improved customer experience will overcome challenges and help you evolve like never before."
                                ProjectURL="./business-app-solution" 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg7} 
                                Title="FITNESS & WELLNESS" 
                                Text="Our apps make things easy for fitness enthusiasts. Gym trainers, physical instructors, and more can benefit from our fitness and wellness apps."
                                ProjectURL="./fitness-wellness-app-solutions" 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg11} 
                                Title="TRAVEL & TOURISM" 
                                Text="Develop custom travel apps with us that promise an increase in profits. Make the overall traveling process, that is, booking tickets, planning tours, and hotel booking much easier. "
                                ProjectURL="./travel-app-solutions" 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg2} 
                                Title="SOCIAL MEDIA APP"
                                Text="You can now harness the potential of social media with our apt social media app development. Expect high conversions & engagements with our future-proof solutions."
                                ProjectURL="./social-media-app" 
                            />
                        </div>  
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg6} 
                                Title="HEALTHCARE " 
                                Text="Our apps enhance the care for patients. Hospitals, doctors, pharmacists, etc benefit from our apps that give patients a satisfying experience through real-time communications."
                                ProjectURL="./healthcare-app-solution" 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg5} 
                                Title="REAL-ESTATE" 
                                Text="Your real-estate agency is going to witness some real profits by choosing our innovative real estate solutions."
                                ProjectURL="./real-estate-app-solution" 
                            />
                        </div>
                        
                       
                    </div>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Project