import React from "react";
import { Link } from "react-router-dom";

import SectionTitle from "../../components/Common/SectionTitle";

// Service Image Icons
import roundImg from "../../assets/img/banner/sf-development.webp";



import effectImg2 from '../../assets/img/about/dotted-3.webp';
import effectImg3 from '../../assets/img/about/shape3.webp';

const About = () => {
  return (
    <div
      id="rs-about"
      className="rs-about style3 pt-120 pb-50 md-pt-30"
      style={{ padding: "20px 0" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 md-mb-30">
            <div className="rs-animation-image md-pt-0">
              <div className="pattern-img">
                <img src={roundImg} alt="homepage_sec" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 pl-20 md-pl-14">
            <div className="contact-wrap">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="Fast-Tracking Innovation"
                titleClass="title pb-25"
                title="We Design, Develop, And Deliver The Best Experiences In The Global Market."
                descClass="margin-0 pb-16"
                description="At the intersection of design, development, and delivery, we craft exceptional digital experiences for the global market. Whether you're a startup, an enterprise,
                                             or a large organization, our team of skilled technologists is ready to help you bring your vision to life.."
                secondDescClass="margin-0 pb-16"
                secondDescription="Using cutting-edge technologies, we create dynamic web and mobile whenyou’re not. Our solutions are designed to captivate customers and boost
                                                    your bottom line. We take pride in developing high-quality, value-driven products that you can be proud of."
                thirdDescClass="margin-0 "
                thidDescription="Ready to take your business to the next level? Reach out, and we’ll get  back to you right away."
              />
              {/* Section Title End */}
              <div className="btn-part">
                <Link className="readon learn-more" to="/about">
                  Continuous . . .
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-image">
          <img className="top dance" src={effectImg2} alt="" />
          <img className="bottom dance" src={effectImg3} alt="" />
        </div>
      </div>
    </div>
  );
};

export default About;
