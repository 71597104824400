import React from 'react';
import { Link } from 'react-router-dom';
import HeaderStyleThree from '../components/Layout/Header/HeaderStyleThree';
import Footer from '../components/Layout/Footer';
import SearchModal from '../components/Layout/Header/SearchModal';
import ScrollToTop from '../components/Common/ScrollTop';
import SiteBreadcrumb from '../components/Common/Breadcumb';
import FaqMain from '../components/Elements/Faq/FaqMain';

// Breadcrumbs Background Image
import bannerbg from '../assets/img/banner/banner-6.webp';

const FaqMainPage = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='pages'
                activeMenu='/faq'
            />
            <div>
                {/* breadcrumb-area-start */}
                <SiteBreadcrumb
                    pageTitle="faq"
                    titleClass="page-title"
                    pageName="faq"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="faq"
                />
                {/* breadcrumb-area-start */}

                {/* Faq */}
                <FaqMain />
                {/* Faq */}

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </div>
            <Footer />
            <SearchModal />
        </React.Fragment>

    );
}


export default FaqMainPage;