import React from "react";
import { Link } from "react-router-dom";

const CTA = (props) => {
  const { ctaButtonLink } = props;

  return (
    <div className={props.ctaSectionClass}>
      <div className="container">
        <div className="cta-wrap">
          <div className="row align-items-left">
            <div className="col-lg-8 col-md-12 md-pr-0 md-pl-15 md-mb-30 md-center">
              <div className="title-wrap text-left">
                <h2 className={props.ctaTitleClass}>{props.ctaTitle}</h2>
                <p className={props.ctaTextClass}>{props.ctaText}</p>
                <p className={props.cta2TextClass}>{props.ctaText2}</p>
              </div>
              <div className="button-wrap text-left  md-center set_btnsize readon learn-more Consult btn-style510">
                <Link to="/contact" className="text-white">
                  Get In Touch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
