import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
import Software_Development_m from '../../components/Common/Breadcumb/software_dev_main';
import SoftwareDevelopmentsec  from './software-development-main';
import ScrollToTop from '../../components/Common/ScrollTop';



// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.webp';
import Database_Management from '../../components/Common/Breadcumb/Database_Management';
import DatabaseManagemnet from './DatabaseManagemnet';


const Database_Management_Main = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
           
               
                <Database_Management 
                  pageTitle="Database Management"
                  titleClass="page-title"
                  pageName="Database Management Services"
                  breadcrumbsImg={bannerbg}
                  animatedFont="lined-Font dance2"
                  animatedFontsize="Database Management" />
                <DatabaseManagemnet />
             
                {/* ServiceOneMain */}
            </div>
            <ScrollToTop />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default Database_Management_Main