import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/databasemanament1.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images

import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";
// Service Icons
import imgMain40 from '../../assets/img/service/style2/main-img/database1.webp';
import imgMain41 from '../../assets/img/service/style2/main-img/database2.webp';
import imgMain42 from '../../assets/img/service/style2/main-img/database3.webp';
import imgMain43 from '../../assets/img/service/style2/main-img/database4.webp';
import imgMain28 from '../../assets/img/service/style2/main-img/database5.webp';
import imgMain44 from '../../assets/img/service/style2/main-img/database6.webp';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/d1.webp';
import serviceIcon15 from '../../assets/img/service/style2/main-img/d2.webp';
import serviceIcon16 from '../../assets/img/service/style2/main-img/d4.webp';
import serviceIcon17 from '../../assets/img/service/style2/main-img/d3.webp';
import serviceIcon18 from '../../assets/img/service/style2/main-img/d5.webp';

// Service Icons



import effectImg2 from '../../assets/img/about/dotted-3.webp';
import effectImg3 from '../../assets/img/about/shape3.webp';
import ConsultNowBtn from '../../components/ConsultNowBtn';
import { CounterSection } from '../../components/CounterSection/Data';
import CounterSectioin from '../../components/CounterSection/CounterSectioin';
import { Feature_Item } from "../../components/CounterSection/Data";

const DatabaseManagemnet = () => {
  let data = CounterSection.Database_Management[0];
  const featureData = Feature_Item.DatabaseManagement;
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-7 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="Marketing Experts"
                titleClass="title pb-25"
                title="Database Management Services

                                "
                descClass="desc pb-5"
                description="As data volumes grow exponentially, managing diverse and complex data sources becomes challenging. Our services enable organizations to harness the power of intelligent data and analytics, simplifying data management to achieve optimal results.Selecting the Right Database: Choosing the right database is an important task when it comes to handling the complexities of enterprise-class data. Modern data needs cannot be handled by legacy systems; therefore, agile and updated NoSQL databases are increasingly in demand.
Our relational and non-relational database experience helps businesses make the right decision according to the volume, variety, and data types."
              />
              <ConsultNowBtn />
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>
      <div className="rs-process style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Our Expertise"
            titleClass="title pb-10"
            title="Our Database Management Solutions

                        "
            descClass="desc"
            description="We offer strong, scalable solutions for modern enterprises:
                        "
            effectClass="heading-line"
          />

          <div className="rs-services style3 modify2 pt-20">
            <div className="row">
              <div className="col-lg-6  mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain40}
                  HoverImg={imgMain40}
                  Title="Infrastructure Planning"
                  Text=" Designing hardware solutions to suit the organizational pattern and goals."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain41}
                  HoverImg={imgMain41}
                  Title="Architecture Design "
                  Text="Software frameworks and architectures that align with industrial needs."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain42}
                  HoverImg={imgMain42}
                  Title="Performance Tuning "
                  Text=" Improving database efficiency and automating the processes to best utilize resources."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain28}
                  HoverImg={imgMain28}
                  Title="Backup & Recovery Management"
                  Text="Ensuring secure, encrypted data storage with efficient recovery."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain43}
                  HoverImg={imgMain43}
                  Title="Security Management"
                  Text="Encrypted solutions along with restrictions on access."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain44}
                  HoverImg={imgMain44}
                  Title="Patch Management"
                  Text="Continuous updates to minimize security threats along with functionalities.
 "
                  btnClass="d-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Process"
            titleClass="title title"
            title="Looking for Exceptional Database Management? We are here to help you. 
                            "
            effectClass="heading-line"
            descClass="desc w-80"
            description="
                          Our Process We are using a structured and results-oriented process to provide streamlined database solutions with the following key steps:
                            "
          />
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon14}
                Title="Strategy"
                Text=" Documenting the architectures and workflows that will go in the database.

"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon15}
                Title="Wireframes"
                Text=" Documenting the architectures and workflows that will go in the database."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon16}
                Title="Development"
                Text="Producing solid business database solutions"
              />
            </div>
            <div className="col-lg-2 col-md-6 mb-30"></div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon17}
                Title="Quality Testing"
                Text="Ensuring dependability, safety, and productivity."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon18}
                Title="Launch"
                Text="Deploying a database to bring out its ultimate performance.
"
              />
            </div>
            <div className="col-lg-2 col-md-6 mb-30"></div>
          </div>
        </div>
      </div>

      <div className="accordions pb-50">
        <div className="container">
          <FAQHeader data={faqDescriptions["Database_Management"]} />

          <AccordionCards data={accordions.Database_Management} />
        </div>
      </div>

      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
        ctaTitleClass="epx-title"
        ctaTitle="Hire Our Software Development Experts and Witness a Game-Changer"
        ctaTextClass="exp-text"
        ctaText="Develop your innovative ideas into robust, scalable, and cutting-edge software solutions. From brainstorming to deployment, our software development maestros craft tailor-made applications that drive business growth, streamline processes, and bring your vision to life. With Metaqualt, you get solutions that maximize value, boost productivity, and deliver results that exceed expectations."
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Get In Touch"
      />
    </React.Fragment>
  );
};

export default DatabaseManagemnet;
