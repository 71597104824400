import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";

import bannerImg31 from "../../assets/img/banner/cloudcon.webp";






// Service Icons



import effectImg2 from "../../assets/img/about/dotted-3.webp";
import effectImg3 from "../../assets/img/about/shape3.webp";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";

import { CounterSection } from "../../components/CounterSection/Data";
import { Feature_Item } from "../../components/CounterSection/Data";
import OurServices from "./weProvide";
import WhyChoose from "./whychooseus";
import mean_stack31 from "../../assets/img/about/clousconst3.webp";

const cloud_consulting = () => {
  let data = CounterSection.machinelearning[0];
  const featureData = Feature_Item.machinelearning;
  return (
    <React.Fragment>
      <div className="rs-about row_rev_sec pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-5 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg31} alt="" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 pl-20">
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="We are Different"
                titleClass="title pb-25"
                title="Why Amazon And Azure Cloud Development with MetaQualt?"
                descClass="desc pb-5"
                description="MetaQualt is a leading provider of AWS cloud services, helping businesses unlock the full potential of AWS cloud hosting through a robust framework of managed services. Our team of seasoned experts has years of experience within the AWS ecosystem, offering solutions ranging from AWS-based community management to storage and administration.
                We also boast a skilled team of Microsoft developers with extensive experience in the Azure platform. Whether you need Azure development, administration, or cloud solutions, MetaQualt is your go-to partner. Our mission is simple: to help your business grow by delivering reliable, scalable, and tailored cloud solutions for every project.
 "
              />
              <ConsultNowBtn />
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>

      <div className="style3 pt-100 pb-100 md-pt-40 md-pb-40 provide_sec">
        <div className="container relative">
          <div className="sec-left">
            <h2 className="title"> What We Provide</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div className="row align-items-left">
                <div className="col-lg-12 col-md-12">
                  <SectionTitle
                    sectionClass="sec-title2 text-left mb-30"
                    subtitleClass="sub-text "
                    subtitle=""
                    titleClass="title pb-10"
                    title="MetaQualt: Transforming Businesses With Cloud Solutions

                                        "
                    descClass="desc"
                    description="Cloud computing has become essential for enterprises and SMBs, enabling them to securely store critical data while maintaining confidentiality. AWS has revolutionized the perception of the cloud, breaking barriers and offering businesses a cost-effective, efficient way to launch applications online.
At MetaQualt, we deliver top-notch cloud development services tailored to your needs. With our dedicated offshore developers and reliable RelyShore model, we ensure scalable, secure, and efficient solutions to drive your business forward."
                  />
                </div>

                <div className="col-lg-6 col-md-12">
                  <ul className="check-square">
                    <li className="">Cloud assessment consulting</li>
                    <li className="">Maintenance and support services</li>
                    <li className="">Developing cloud applications</li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-12">
                  <ul className="check-square">
                    <li className="">Cloud application migration</li>
                    <li className="">Integrating cloud applications</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="text-center relative">
                <img className="main" src={mean_stack31} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <OurServices />

      <WhyChoose />

      <div className="accordions pb-50 pt-80">
        <div className="container">
          <FAQHeader data={faqDescriptions["Cloud_Consulting"]} />

          <AccordionCards data={accordions.Cloud_Consulting} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default cloud_consulting;
