import React from "react";
import OurServices from "./weProvide";

import SectionTitle from "../../components/Common/SectionTitle";

import WhyChoose from "./whychooseus";

// Working Process Images




// Service Icons
import microsoft from "../../assets/img/about/microsoft.webp";
import bannerImg from "../../assets/img/about/dedicated2.webp";

import ConsultNowBtn from "../../components/ConsultNowBtn";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";

const Microsoft_dev = () => {
  return (
    <React.Fragment>
      {/* <!-- banner section end --> */}
      <div id="rs-about" className="rs-about pt-150 pb-20 md-pt-60 row_rev_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="text-center relative">
                <img className="main" src={bannerImg} alt="" />
              </div>
              <div
                className="banner-img absolute"
                style={{ top: 25, left: 0 }}
              ></div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <SectionTitle
                sectionClass="sec-title2 pb-70 md-pt-10 md-pb-20"
                subtitleClass="sub-text style-bg"
                subtitle="Hiring"
                titleClass="title pb-25"
                title="Transform Your Business With MetaQualt's.NET Development Services
                                "
                descClass="desc mb-25"
                description="At MetaQualt, we bring together a team of experienced full-stack.NET developers who are passionate about delivering real results for your business. Whether you’re building something new or upgrading existing systems, we use the latest technologies and development practices to create solutions that are reliable, scalable, and tailored to your specific needs. 
                                "
                secondDescClass="mb-10 pb-16"
                secondDescription="Our services cover everything from application development to regular updates, ongoing maintenance, and dedicated support—ensuring your.NET-based websites and applications perform at their best.
                If you’re looking for modern, efficient, and fully customized.NET development services that align with your business goals, we’re here to help.
                Let’s get started—contact us today!  
                                "
              />
              <ConsultNowBtn />
            </div>
          </div>
        </div>
      </div>

      <div className="style3 pt-100 pb-100 md-pt-40 md-pb-40 provide_sec">
        <div className="container relative">
          <div className="sec-left">
            <h2 className="title"> What We Provide</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div className="row align-items-left">
                <div className="col-lg-12 col-md-12">
                  <SectionTitle
                    sectionClass="sec-title2 text-left mb-30"
                    subtitleClass="sub-text gold-color"
                    subtitle=""
                    titleClass="title pb-10"
                    title="Ready To Build Dynamic And Powerful Web Solutions? Let’s Bring Your Vision to Life
                                        "
                    descClass="desc"
                    description="At MetaQualt, we believe that your business size or the number of pages in your app shouldn’t limit your ambitions. What truly matters is delivering a seamless, robust, and trusted development experience. That’s why we offer tailored.NET development services designed to transform your ideas into powerful digital solutions.
                    Our team specializes in crafting dynamic and high-performing applications, ensuring your web solutions are built to thrive in today’s competitive landscape. With a focus on quality, reliability, and innovation, we’re here to help you achieve your goals—no matter the scope of your project.

                                        "
                  />
                </div>

                <div className="col-lg-12 col-md-12">
                  <ul className="check-square">
                    <li className="">
                      Flexible engagement models tailored to your needs.
                    </li>
                    <li className="">
                      Advanced security measures, IP protection, and strict NDA
                      compliance.
                    </li>
                    <li className="">
                      Skilled and agile developers with a proven track record.
                    </li>
                    <li className="">
                      Access to an on-demand, scalable pool of cutting-edge
                      technology and expertise.
                    </li>
                    <li className="">
                      Over 15 years of experience, a 50+ strong team, and 500+
                      successful projects delivered.
                    </li>
                    <li className="">
                      100+ happy clients who trust us to bring their visions to
                      life.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="text-center relative">
                <img className="main" src={microsoft} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <OurServices />

      <WhyChoose />

      <div className="accordions pb-50 pt-70">
        <div className="container">
          <FAQHeader data={faqDescriptions["Microsoft_.NET_dev"]} />

          <AccordionCards data={accordions.Microsoft_DotNET_dev} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Microsoft_dev;
