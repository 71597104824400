import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.webp';

const bgStyle = {
    backgroundImage:`url(${bgImg})`
}

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 gray-bg pt-50 pb-80" style={bgStyle}>
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text style-bg"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="What do we bring to the table?
                        "
                        effectClass="heading-line"
                />
                <div className="row align-items-center">

                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Daily Reporting Via Email"
                                    Text="Hire us, and we will stay in touch daily. You get all the project updates via a dedicated project manager, who efficiently manages projects of varied sizes and types."
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="160 Man Hours Guaranteed"
                                    Text="Benefit from a monthly rolling contract. Your project gets our 160 hours of undivided attention be it a yearly or monthly model."
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="Flexible Hours Timezone"
                                    Text="Our offshore development service facilitates us to can connect, communicate, and receive feedback as we efficiently manage the time zones."
                                />
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Monthly/Yearly Hiring"
                                    Text="You play a pivotal role in hiring our developers. Depending on your project needs you can hire dedicated resources on a monthly or yearly basis."
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Daily/Weekly Code Delivery"
                                    Text="Don’t keep guessing how your product looks and responds! You get to check the products in working mode with us. Get the codes delivered on a daily and weekly basis."
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="Direct Access Of Resources"
                                    Text="You are just a click or a tap away from connecting with the industry’s leading resources. 
                                    We can get in touch with us via Skype, Email, Calls, and Chat.
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurServices;