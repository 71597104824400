import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
import Software_Development_m from '../../components/Common/Breadcumb/software_dev_main';
import SoftwareDevelopmentsec  from './software-development-main';
import ScrollToTop from '../../components/Common/ScrollTop';



// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.webp';


const software_development = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}
                <Software_Development_m
                    pageTitle="Software Development"
                    titleClass="page-title"
                    pageName="Software Development Services"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="Software Development"
                />
                {/* breadcrumb-area-start */}

                {/* ServiceOneMain */}
                <SoftwareDevelopmentsec />
             
                {/* ServiceOneMain */}
            </div>
            <ScrollToTop />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default software_development;