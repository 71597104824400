import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.webp';
import mainIcon55 from '../../assets/img/service/style2/main-img/55.webp';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.webp';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.webp';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.webp';
import mainIcon48 from '../../assets/img/service/style2/main-img/48.webp';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-0 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Solution"
                    titleClass="title testi-title text-white"
                    title="Unlock the Full Potential of Salesforce Sales Cloud with MetaQualt"
                    descClass="desc text-dark"
                    description="Transform your sales operations and elevate customer engagement with Salesforce Sales Cloud. By leveraging its powerful capabilities, you can streamline your sales processes, boost productivity, and deliver a personalised experience that drives better results.
"
                    effectClass="heading-line"
                />
                <>
                <h4 className='text-center'>Key Benefits of Salesforce Sales Cloud</h4>
                </>
                <div className="row">
                  
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Enhanced Sales Productivity"
                           Text="Automate time-consuming tasks like data entry, lead nurturing, and opportunity management, giving your sales team more time to focus on closing deals and generating revenue."   />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon55}
                            hoverIcon={mainIcon55}
                            Title="Improved Sales Forecasting"
                           Text="Gain real-time insights with Salesforce Sales Cloud’s advanced analytics. Make informed decisions, predict future revenue, and fine-tune your sales strategies based on accurate, data-driven forecasts.
"/>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Increased Customer Engagement"
                           Text="With a 360-degree view of each customer, Salesforce Sales Cloud empowers your team to engage effectively and efficiently, providing exceptional service and strengthening relationships."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Better Collaboration"
                           Text="Centralise all client data in one place, accessible from any device. Your team can collaborate seamlessly, make quick decisions, and act on the latest, most accurate information—anytime, anywhere.
" />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Mobile Access"
                            Text="Access Salesforce Sales Cloud from any device, ensuring your sales team can update progress and access crucial customer data on the go—enhancing flexibility and productivity.
"/>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon48}
                            hoverIcon={mainIcon48}
                            Title="Highly Customisable"
                            Text="Salesforce Sales Cloud adapts to your unique sales processes, offering deep customisation options that let you tailor the platform to suit your specific business needs.
"
                        />
                    </div>
                </div>
            </div>
           
        </div>
    );
}

export default WhyChoose;