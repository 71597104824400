import React from "react";

import WeServes from "./it-services";
import SectionTitle from "../../components/Common/SectionTitle";

import WhyChoose from "./whychooseus";

import TechSlider from "./TechSlider";


// Working Process Images

import roundImg from '../../assets/img/about/SalesforceImplementation2.webp';
import aboutImg from '../../assets/img/about/SalesforceImplementation1.webp';


import effectImg2 from '../../assets/img/about/dotted-3.webp';
import effectImg3 from '../../assets/img/about/shape3.webp';
import ConsultNowBtn from '../../components/ConsultNowBtn';

const Salesforce_implementation_main = () => {
  const options = {
    items: 3,
    nav: false,
    dots: true,
    margin: 30,
    rewind: false,
    autoplay: false,
    stagePadding: 30,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    loop: true,
    center: false,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        dots: false,
      },
      768: {
        items: 2,
        stagePadding: 0,
        dots: true,
      },
      992: {
        items: 3,
        stagePadding: 0,
      },
      1500: {
        items: 3,
        stagePadding: 0,
      },
    },
  };

  return (
    <React.Fragment>
      {/* <!-- banner section end --> */}
      <div id="rs-about" className="rs-about disc style3 pt-150 pb-0 md-pt-60 row_rev_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 mb-20">
              <div className="rs-animation-image md-pb-10 md-pt-10">
                <div className="pattern-img">
                  <img src={roundImg} alt="" />
                </div>
                <div className="middle-img ">
                  <img className="dance3" src={aboutImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2  pt-70 md-pt-10 "
                subtitleClass="sub-text style-bg"
                subtitle="WHO WE ARE"
                titleClass="title mb-25"
                title="Unleash the Power of Salesforce with MetaQualt"
                descClass="desc pb-15"
                description="The Salesforce platform redefines how businesses operate, offering robust core applications for sales, service, marketing, commerce, and community. Supported by cutting-edge technologies like Salesforce Einstein—an advanced AI and analytics engine—and Heroku and Lightning for custom development, Salesforce empowers businesses to adapt, grow, and innovate like never before."
                secondDescClass="mb-10 pb-16"
                secondDescription="With Salesforce, you have the freedom to select the implementation services you need, scale effortlessly as your business evolves, and customize the platform to align perfectly with your vision.
"
              />

              <ConsultNowBtn />
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>

      <WeServes />

      <TechSlider />

      <WhyChoose />
    </React.Fragment>
  );
};

export default Salesforce_implementation_main;
