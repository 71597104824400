import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {
  const personlcss1 = {
    padding: "5px",
  };

  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [services, setService] = useState(false);
  const [solution, setSolution] = useState(false);
  const [blog, setBlog] = useState(false);
  const [hiredevelopers, setHiredevelopers] = useState(false);
  const [Portfolio, setPortfolio] = useState(false);

  const openMobileMenu = (menu) => {
    if (menu === "home") {
      setHome(!home);
      setAbout(false);
      setService(false);
      setBlog(false);
      setSolution(false);
      setHiredevelopers(false);
      setPortfolio(false);
    } else if (menu === "about") {
      setHome(false);
      setAbout(!about);
      setService(false);
      setBlog(false);
      setSolution(false);
      setHiredevelopers(false);
      setPortfolio(false);
    } else if (menu === "services") {
      setHome(false);
      setAbout(false);
      setService(!services);
      setBlog(false);
      setSolution(false);
      setHiredevelopers(false);
      setPortfolio(false);
    } else if (menu === "solution") {
      setHome(false);
      setAbout(false);
      setService(false);
      setBlog(false);
      setSolution(!solution);
      setHiredevelopers(false);
      setPortfolio(false);
    } else if (menu === "blog") {
      setHome(false);
      setAbout(false);
      setService(false);
      setBlog(!blog);
      setSolution(false);
      setHiredevelopers(false);
      setPortfolio(false);
    } else if (menu === "hiredevelopers") {
      setHome(false);
      setAbout(false);
      setService(false);
      setBlog(false);
      setSolution(false);
      setHiredevelopers(!hiredevelopers);
      setPortfolio(false);
    } else if (menu === "Portfolio") {
      setHome(false);
      setAbout(false);
      setService(false);
      setBlog(false);
      setSolution(false);
      setHiredevelopers(false);
      setPortfolio(!Portfolio);
    }
  };

  return (
    <div className="container relative">
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <li
              className={
                services
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to=""
                onClick={() => {
                  openMobileMenu("services");
                }}
              >
                Service
              </Link>
              <ul className={services ? "sub-menu current-menu" : "sub-menu"}>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Microsoft Tech</h4>
                      <ul>
                        <li>
                          <Link
                            to="/microsoft-dotnet"
                            lassName={
                              activeMenu === "/MicroSoft-Dot-NET"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Microsoft .NET Development
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/cloud-consulting"
                            lassName={
                              activeMenu === "/Cloud-Consulting"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Cloud Consulting{" "}
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/application-migration"
                            lassName={
                              activeMenu === "/Application-Migration"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Application Migration{" "}
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/application-integration"
                            lassName={activeMenu === "/" ? "active-menu" : ""}
                          >
                            Application Integration
                          </Link>
                        </li>
                        <li>
                          <Link
                              to="/cloud-app-development"
                            lassName={
                              activeMenu === "/Cloud-App-Development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Cloud Application Development
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/saas-app-development"
                            lassName={
                              activeMenu === "/SaaS-App-Development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            SaaS Application Development
                          </Link>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Resources</h4>
                      <ul>
                        <li>
                          <Link
                           to="/power-bi"
                            lassName={activeMenu === "/" ? "active-menu" : ""}
                          >
                            Power BI
                          </Link>{" "}
                        </li>
                        {/* <li><Link to="" lassName={activeMenu === "" ? "active-menu" : ""}>SSIS</Link> </li>
                                                                <li><Link to="" lassName={activeMenu === "" ? "active-menu" : ""}>SSRS</Link> </li> */}
                        <li>
                          <Link
                            to="/tableau"
                            lassName={activeMenu === "" ? "active-menu" : ""}
                          >
                            <span>Tableau</span>
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                           to="/database-management"
                            lassName={
                              activeMenu === "/Database-Management"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Database Management
                          </Link>{" "}
                        </li>
                        {/* <li><Link to="/eCommerce-development" lassName={activeMenu === "/eCommerce-development" ? "active-menu" : ""}>Database Performance Tuning</Link> </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>DeepTech</h4>
                      <ul>
                        <li>
                          <Link
                              to="/artificial-intelligence"
                            lassName={
                              activeMenu === "/Artificial-Intelligence"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Artificial Intelligence (AI)
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                             to="/machine-learning"
                            lassName={
                              activeMenu === "/Machine-Learning"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Machine Learning
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/data-science"
                            lassName={
                              activeMenu === "/Data-Science"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Data Science
                          </Link>{" "}
                        </li>
                        {/* <li><Link to="/Data-Science" lassName={activeMenu === "/Data-Science" ? "active-menu" : ""}>BlockChain</Link> </li> */}
                      </ul>
                      <h4 className="pt-3">Digital Transformation</h4>
                      <ul>
                        <li>
                          <Link
                            to="/ux-ui-design"
                            lassName={
                              activeMenu === "/Ux-Ui-Design"
                                ? "active-menu"
                                : ""
                            }
                          >
                            UI/UX Design
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/digitle-market"
                            lassName={
                              activeMenu === "/Digitle-Market"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Digital Marketing
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                           to="/quality-engineering"
                            lassName={
                              activeMenu === "/Quality-Engineering"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Quality Engineering
                          </Link>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner">
                      <h4>Application Development</h4>
                      <ul>
                        <li>
                          <Link
                             to="/web-development"
                            lassName={
                              activeMenu === "/Web-Development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Web Development
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                          to="/software-development"
                            lassName={
                              activeMenu === "/Software-Development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Software Development
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/mobile-app-development"
                            lassName={
                              activeMenu === "/Mobile-App-Development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Mobile App Development
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/cms-development"
                            lassName={
                              activeMenu === "/CMS-Development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            CMS Development
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                           to="/crm-development"
                            lassName={
                              activeMenu === "/CRM-Development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            CRM Development
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                           to="/ecommerce-development"
                            lassName={
                              activeMenu === "/eCommerce-development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            e-commerce Development
                          </Link>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ul>
            </li>
            <li
              className={
                solution
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to=""
                onClick={() => {
                  openMobileMenu("solution");
                }}
              >
                Solution
              </Link>
              <ul className={solution ? "sub-menu current-menu" : "sub-menu"}>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>By Business Scales</h4>
                      <ul>
                        <li>
                          <Link
                             to="/startup-solutions"
                            lassName={
                              activeMenu === "/startup-solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Startup Solutions
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/enterprise-solution"
                            lassName={
                              activeMenu === "/enterprise-solution"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Enterprise Solution
                          </Link>{" "}
                        </li>
                        {/* <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>SME</Link> </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Custom Solutions</h4>
                      <ul>
                        <li>
                          <Link
                             to="/devops-solutions"
                            lassName={
                              activeMenu === "/Devops-Solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            DevOps Solutions
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                           to="/cloud-solutions"
                            lassName={
                              activeMenu === "/Cloud-Solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Cloud Solutions
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/on-demand-business-solutions"
                            lassName={
                              activeMenu === "/On-Demand-Business-Solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            On-demand Business Solutions
                          </Link>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Industry Expertise</h4>
                      <ul>
                        <li>
                          <Link
                           to="/business-app-solution"
                            lassName={
                              activeMenu === "/business-app-solution"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Retail/Business{" "}
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/fitness-wellness-app-solutions"
                            lassName={
                              activeMenu === "/fitness-wellness-app-solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Fitness App{" "}
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                           to="/fintech-app-solution"
                            lassName={
                              activeMenu === "/fintech-app-solution"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Fintech-App{" "}
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                           to="/travel-app-solutions"
                            lassName={
                              activeMenu === "/travel-app-solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Travel Tourism
                          </Link>{" "}
                        </li>
                        {/* <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Hospitality</Link></li>
                                                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>e-commerce</Link> </li> */}
                        <li>
                          <Link
                             to="/real-estate-app-solution"
                            lassName={
                              activeMenu === "/real-estate-app-solution"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Real Estate{" "}
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/healthcare-app-solution"
                            lassName={
                              activeMenu === "/healthcare-app-solution"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Healthcare
                          </Link>{" "}
                        </li>
                        {/* <li><Link to="/social-media-app" lassName={activeMenu === "/social-media-app" ? "active-menu" : ""}>Acution</Link> </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner">
                      <h4>Salesforce Development</h4>
                      <ul>
                        <li>
                          <Link
                            to="/salesforce-consulting-services"
                            lassName={
                              activeMenu === "/Salesforce-Consulting-Services"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Salesforce Consulting
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                           to="/salesforce-implementation"
                            lassName={
                              activeMenu === "/Salesforce-Implementation"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Salesforce Implementation{" "}
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                           to="/salesforce-lightning-services"
                            lassName={
                              activeMenu === "/Salesforce-Lightning-Services"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Salesforce Lightning{" "}
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/salesforce-exchange"
                            lassName={
                              activeMenu === "/Salesforce-Exchange"
                                ? "active-menu"
                                : ""
                            }
                          >
                            SalesForce App Exchange
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/salescloud-consulting"
                            lassName={
                              activeMenu === "/SalesCloud-Consulting"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Sales Cloud Consulting{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ul>
            </li>
            <li
              className={
                hiredevelopers
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to=""
                onClick={() => {
                  openMobileMenu("hiredevelopers");
                }}
              >
                “Hire offshore developers
              </Link>
              <ul
                className={
                  hiredevelopers ? "sub-menu current-menu" : "sub-menu"
                }
              >
                <div className="row">
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Mobile App Developers</h4>
                      <ul>
                        <li>
                          <Link
                            to="/Hire-Flutter-Developers"
                            lassName={
                              activeMenu === "/hire-flutter-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Flutter Developers
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/Hire-React-Native-Developers"
                            lassName={
                              activeMenu === "/hire-react-native-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            React Native Developers
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/Hire-iOS-App-Developer"
                            lassName={
                              activeMenu === "/hire-ios-app-developer"
                                ? "active-menu"
                                : ""
                            }
                          >
                            iOS Developers
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/Hire-Android-App-Developer"
                            lassName={
                              activeMenu === "/hire-android-app-developer"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Android Developers
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Full Stack Developers</h4>
                      <ul>
                        <li>
                          <Link
                            to="/Hire-Mean-Stack-Developers"
                            lassName={
                              activeMenu === "/hire-mean-stack-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            MeanStack Developers
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/Hire-PHP-Developers"
                            lassName={
                              activeMenu === "/hire-php-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            PHP Developers
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/Hire-Python-Developers"
                            lassName={
                              activeMenu === "/hire-python-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Python Developers
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/Hire-Nodejs-Developers"
                            lassName={
                              activeMenu === "/hire-nodejs-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Node.js Developers
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/Hire-Angular-Developer"
                            lassName={
                              activeMenu === "/hire-angular-developer"
                                ? "active-menu"
                                : ""
                            }
                          >
                            AngularJs Developers
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/Hire-Vuejs-Developer"
                            lassName={
                              activeMenu === "/hire-vuejs-developer"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Vue Developers
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/Hire-Reactjs-Developers"
                            lassName={
                              activeMenu === "/hire-reactjs-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            ReactJs Developers
                          </Link>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Functional Resources</h4>
                      <ul>
                        <li>
                          <Link
                            to="/Business-Aanalyst"
                            lassName={
                              activeMenu === "/Business-Aanalyst"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Business Analyst
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/Project-Managers"
                            lassName={
                              activeMenu === "/Project-Managers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Project Managers
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/Software-Testers"
                            lassName={
                              activeMenu === "/Software-Testers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Software Testers
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/Hire-Designers"
                            lassName={
                              activeMenu === "/Hire-Designers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            {" "}
                            Hire Designers
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner">
                      <h4>Experts In</h4>
                      <ul>
                        {/* <li><Link to="/Crypto" lassName={activeMenu === "Crypto" ? "active-menu" : ""}>Crypto</Link></li>
                                                                <li><Link to="/Blockcain" lassName={activeMenu === "/Blockcain" ? "active-menu" : ""}> Blockchain</Link> </li>
                                                                <li><Link to="/Fintech" lassName={activeMenu === "/Fintech" ? "active-menu" : ""}>Fintech</Link> </li> */}
                        <li>
                          <Link
                            to="/Hire-UI-UX-Developers"
                            lassName={
                              activeMenu === "/hire-ux-ui-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            UI/UX Developer
                          </Link>
                        </li>
                        {/* <li><Link to="/Salesforce" lassName={activeMenu === "/Salesforce" ? "active-menu" : ""}>Salesforce</Link></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </ul>
            </li>
            <li
              className={
                blog
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to=""
                onClick={() => {
                  openMobileMenu("blog");
                }}
              >
                Company
              </Link>
              <ul className={blog ? "sub-menu current-menu" : "sub-menu"}>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="main_menu_inner mega_menu">
                      <h4>About Metaqualt</h4>
                      <ul>
                        <li>
                          <Link
                            to="/About"
                            lassName={
                              activeMenu === "/about" ? "active-menu" : ""
                            }
                          >
                            About us
                          </Link>{" "}
                        </li>
                        {/* <li><Link to="/Our-Achievement" lassName={activeMenu === "/Our-Achievement" ? "active-menu" : ""}>Our Achievement</Link> </li>
                                                                 <li><Link to="/Our-Leading-Metaqualt" lassName={activeMenu === "/Our-Leading-Metaqualt" ? "active-menu" : ""}>Leadership Team</Link> </li> */}
                        <li>
                          <Link
                            to="/Client-Testimonials"
                            lassName={
                              activeMenu === "/Client-Testimonials"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Client Testimonials
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            to="/Contact"
                            lassName={
                              activeMenu === "/Client-Testimonials"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Contact Us
                          </Link>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="main_menu_inner mega_menu">
                      <h4>Careers</h4>
                      <ul>
                        <li>
                          <Link
                            to="/Current-Openings"
                            lassName={
                              activeMenu === "/Current-Openings"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Current Openings
                          </Link>{" "}
                        </li>
                        {/* <li><Link to="/Why-Join-Us" lassName={activeMenu === "/Why-Join-Us" ? "active-menu" : ""}> Why Join Us</Link> </li>
                                                                 <li><Link to="/Employee-Rewards" lassName={activeMenu === "/Employee-Rewards" ? "active-menu" : ""}> Employee Rewards</Link> </li> */}
                        <li>
                          <Link
                            to="/Life-At-Metaqualt"
                            lassName={
                              activeMenu === "/Life-At-Metaqualt"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Life At Metaqualt
                          </Link>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="main_menu_inner">
                      <h4>Resources</h4>
                      <ul>
                        <li>
                          <Link
                            to="/Blog"
                            lassName={
                              activeMenu === "/blog" ? "active-menu" : ""
                            }
                          >
                            Blog
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/White-paper"
                            lassName={
                              activeMenu === "/white-paper" ? "active-menu" : ""
                            }
                          >
                            White paper
                          </Link>{" "}
                        </li>
                        {/* <li><Link to="/hire-flutter-developers" lassName={activeMenu === "/hire-flutter-developers" ? "active-menu" : ""}>   Videos</Link> </li>
                                                         <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Podcasts</Link></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </ul>
            </li>

            {/* <li>
							<Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
						</li> */}

            <li>
              <Link
                to="/Portfolio"
                className={activeMenu === "/Portfolio" ? "active-menu" : ""}
              >
                Portfolio
              </Link>
            </li>

            <li style={personlcss1}>
              <Link className="rs-get-in" to="/contact">
                Get In Touch
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
