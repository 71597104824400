import React from "react";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Footer from "../../components/Layout/Footer";

import ScrollToTop from '../../components/Common/ScrollTop';
// Breadcrumbs Background Image
import bannerbg from "../../assets/img/banner/banner-6.webp";
import SaasDevMain from "./SaasDevMain";
import SaasAppDev from "../../components/Common/Breadcumb/SaasAppDev";

const SaasAppDevelopment = () => {
  return (
    <React.Fragment>
      <HeaderStyleThree
        parentMenu="page"
        secondParentMenu="services"
        activeMenu="/services"
      />
      <div>
        {/* breadcrumb-area-start */}

        <SaasAppDev
          pageTitle="SaaS Application Development"
          titleClass="page-title"
          pageName="Hire Dedicated Developers in Microsoft Technologies."
          breadcrumbsImg={bannerbg}
          animatedFont="lined-Font dance2"
          animatedFontsize="SaaS Application Development"
        />
        {/* breadcrumb-area-start */}

        {/* ServiceOneMain */}

        <SaasDevMain />
        {/* ServiceOneMain */}
      </div>
      <ScrollToTop />
      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default SaasAppDevelopment;
