import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';


// Service Image Icons
import mainIconM1 from '../../assets/img/service/M1.webp';
import mainIconM2 from '../../assets/img/service/M2.webp';
import mainIconM3 from '../../assets/img/service/M3.webp';
import mainIconM4 from '../../assets/img/service/M4.webp';
import mainIconM5 from '../../assets/img/service/M5.webp';
import mainIconM6 from '../../assets/img/service/M6.webp';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Transform App Experiences with MetaQualt’s Cloud Expertise"
                    titleClass="title testi-title text-white"
                    title="Unlock your apps' potential with MetaQualt’s cloud-native solutions for innovation and growth.
"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIconM1}
                            hoverIcon={mainIconM1}
                            Title="Scalability Built-In
                            "
                           
                            Text="Every project begins with understanding your scaling needs. Our cloud-native architectures are built with elasticity in mind, enabling seamless scale-up and scale-out capabilities as your business grows.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIconM2}
                            hoverIcon={mainIconM2}
                            Title="Resilient by Design"
                            
                            Text="We design, develop, and deploy applications with built-in recovery mechanisms, ensuring automatic failover and minimal downtime. Future-proofing your apps isn’t an afterthought—it’s our core strategy.

                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIconM3}
                            hoverIcon={mainIconM3}
                            Title="Instant Data Access"
                           
                            Text="Faster access to critical data drives better performance. Our caching strategies and optimized content delivery services reduce latency and ensure quick access to static and dynamic data.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIconM4}
                            hoverIcon={mainIconM4}
                            Title="Fortified Data Security"
                            
                           Text="Your data is non-negotiable. We implement SSL, advanced encryption, and secure design protocols to safeguard both in-transit and at-rest data, ensuring airtight protection against vulnerabilities."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIconM5}
                            hoverIcon={mainIconM5}
                            Title="Decoupled for Agility"
                           
                            Text="By loosely coupling app components, we eliminate unnecessary dependencies, making it easier to scale, test, and deploy updates independently. This modular approach keeps your systems flexible and resilient.
                            "
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIconM6}
                            hoverIcon={mainIconM6}
                            Title="The Right Cloud Platform
"
                           Text="Whether it's IaaS, PaaS, or SaaS, we offer you the best option after having seen your objectives. Our skill helps in delivering an efficient cloud infrastructure. We here at MetaQualt design and engineer solutions future-ready for your cloud success.

                           "
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;