import React from "react";
import { Link } from "react-router-dom";

const MenuItems = (props) => {
  const { parentMenu, secondParentMenu, activeMenu } = props;
  return (
    <React.Fragment>
      <li
        className={
          parentMenu === "service"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link className={activeMenu === "" ? "active-menu" : ""}>Services</Link>
        <ul className="sub-menu submenu">
          <div className="border_menu ">
            <div className="row">
              <div className="col-lg-3">
                <div className="main_menu_inner mega_menu_set">
                  <h4>Microsoft Tech</h4>
                  <ul>
                    <li>
                      <Link
                        to="/microsoft-dotnet"
                        lassName={
                          activeMenu === "/microSoft-dotnet"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Microsoft .NET Development
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/cloud-consulting"
                        lassName={
                          activeMenu === "/Cloud-Consulting"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Cloud Consulting{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/application-migration"
                        lassName={
                          activeMenu === "/Application-Migration"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Application Migration{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/application-integration"
                        lassName={activeMenu === "/" ? "active-menu" : ""}
                      >
                        Application Integration
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cloud-app-development"
                        lassName={
                          activeMenu === "/Cloud-App-Development"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Cloud Application Development
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/saas-app-development"
                        lassName={
                          activeMenu === "/SaaS-App-Development"
                            ? "active-menu"
                            : ""
                        }
                      >
                        SaaS Application Development
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="main_menu_inner mega_menu_set">
                  <h4>Resources</h4>
                  <ul>
                    <li>
                      <Link
                        to="/power-bi"
                        lassName={activeMenu === "/" ? "active-menu" : ""}
                      >
                        Power BI
                      </Link>{" "}
                    </li>

                    <li>
                      <Link
                        to="/tableau"
                        lassName={activeMenu === "" ? "active-menu" : ""}
                      >
                        <span>Tableau</span>
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/database-management"
                        lassName={
                          activeMenu === "/Database-Management"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Database Management
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="main_menu_inner mega_menu_set">
                  <h4>DeepTech</h4>
                  <ul>
                    <li>
                      <Link
                        to="/artificial-intelligence"
                        lassName={
                          activeMenu === "/Artificial-Intelligence"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Artificial Intelligence (AI)
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/machine-learning"
                        lassName={
                          activeMenu === "/Machine-Learning"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Machine Learning
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/data-science"
                        lassName={
                          activeMenu === "/Data-Science" ? "active-menu" : ""
                        }
                      >
                        Data Science
                      </Link>{" "}
                    </li>
                    {/* <li><Link to="/Data-Science" lassName={activeMenu === "/Data-Science" ? "active-menu" : ""}>BlockChain</Link> </li> */}
                  </ul>
                  <h4 className="pt-3">Digital Transformation</h4>
                  <ul>
                    <li>
                      <Link
                        to="/ux-ui-design"
                        lassName={
                          activeMenu === "/Ux-Ui-Design" ? "active-menu" : ""
                        }
                      >
                        UI/UX Design
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/digitle-market"
                        lassName={
                          activeMenu === "/Digitle-Market" ? "active-menu" : ""
                        }
                      >
                        Digital Marketing
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/quality-engineering"
                        lassName={
                          activeMenu === "/Quality-Engineering"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Quality Engineering
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="main_menu_inner">
                  <h4>Application Development</h4>
                  <ul>
                    <li>
                      <Link
                        to="/web-development"
                        lassName={
                          activeMenu === "/Web-Development" ? "active-menu" : ""
                        }
                      >
                        Web Development
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/software-development"
                        lassName={
                          activeMenu === "/Software-Development"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Software Development
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/mobile-app-development"
                        lassName={
                          activeMenu === "/Mobile-App-Development"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Mobile App Development
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/cms-development"
                        lassName={
                          activeMenu === "/CMS-Development" ? "active-menu" : ""
                        }
                      >
                        CMS Development
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/crm-development"
                        lassName={
                          activeMenu === "/CRM-Development" ? "active-menu" : ""
                        }
                      >
                        CRM Development
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/ecommerce-development"
                        lassName={
                          activeMenu === "/eCommerce-development"
                            ? "active-menu"
                            : ""
                        }
                      >
                        e-commerce Development
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </li>

      <li
        className={
          parentMenu === "solution"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link className={activeMenu === "" ? "active-menu" : ""}>Solution</Link>
        <ul className="sub-menu submenu">
          <div className="border_menu ">
            <div className="row">
              <div className="col-lg-3">
                <div className="main_menu_inner mega_menu_set">
                  <h4>By Business Scales</h4>
                  <ul>
                    <li>
                      <Link
                        to="/startup-solutions"
                        lassName={
                          activeMenu === "/startup-solutions"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Startup Solutions
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/enterprise-solution"
                        lassName={
                          activeMenu === "/enterprise-solution"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Enterprise Solution
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="main_menu_inner mega_menu_set">
                  <h4>Custom Solutions</h4>
                  <ul>
                    <li>
                      <Link
                        to="/devops-solutions"
                        lassName={
                          activeMenu === "/Devops-Solutions"
                            ? "active-menu"
                            : ""
                        }
                      >
                        DevOps Solutions
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/cloud-solutions"
                        lassName={
                          activeMenu === "/Cloud-Solutions" ? "active-menu" : ""
                        }
                      >
                        Cloud Solutions
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/on-demand-business-solutions"
                        lassName={
                          activeMenu === "/On-Demand-Business-Solutions"
                            ? "active-menu"
                            : ""
                        }
                      >
                        On-demand Business Solutions
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="main_menu_inner mega_menu_set">
                  <h4>Industry Expertise</h4>
                  <ul>
                    <li>
                      <Link
                        to="/business-app-solution"
                        lassName={
                          activeMenu === "/business-app-solution"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Retail/Business{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/fitness-wellness-app-solutions"
                        lassName={
                          activeMenu === "/fitness-wellness-app-solutions"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Fitness App{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/fintech-app-solution"
                        lassName={
                          activeMenu === "/fintech-app-solution"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Fintech-App{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/travel-app-solutions"
                        lassName={
                          activeMenu === "/travel-app-solutions"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Travel Tourism
                      </Link>{" "}
                    </li>

                    <li>
                      <Link
                        to="/real-estate-app-solution"
                        lassName={
                          activeMenu === "/real-estate-app-solution"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Real Estate{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/healthcare-app-solution"
                        lassName={
                          activeMenu === "/healthcare-app-solution"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Healthcare
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="main_menu_inner">
                  <h4>Salesforce Development</h4>
                  <ul>
                    <li>
                      <Link
                        to="/salesforce-consulting-services"
                        lassName={
                          activeMenu === "/Salesforce-Consulting-Services"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Salesforce Consulting
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/salesforce-implementation"
                        lassName={
                          activeMenu === "/Salesforce-Implementation"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Salesforce Implementation{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/salesforce-lightning-services"
                        lassName={
                          activeMenu === "/Salesforce-Lightning-Services"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Salesforce Lightning{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/salesforce-exchange"
                        lassName={
                          activeMenu === "/Salesforce-Exchange"
                            ? "active-menu"
                            : ""
                        }
                      >
                        SalesForce App Exchange
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/salescloud-consulting"
                        lassName={
                          activeMenu === "/SalesCloud-Consulting"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Sales Cloud Consulting{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </li>
      <li
        className={
          parentMenu === "hire-dedicated-developers"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link className={activeMenu === "" ? "active-menu" : ""}>
          Hire offshore developers
        </Link>

        <ul className="sub-menu submenu">
          <div className="border_menu ">
            <div className="row">
              <div className="col-lg-3">
                <div className="main_menu_inner mega_menu_set">
                  <h4>Mobile App Developers</h4>
                  <ul>
                    <li>
                      <Link
                        to="/hire-flutter-developers"
                        lassName={
                          activeMenu === "/hire-flutter-developers"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Flutter Developers
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/hire-react-native-developers"
                        lassName={
                          activeMenu === "/hire-react-native-developers"
                            ? "active-menu"
                            : ""
                        }
                      >
                        React Native Developers
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/hire-iphone-app-developer"
                        lassName={
                          activeMenu === "/hire-ios-app-developer"
                            ? "active-menu"
                            : ""
                        }
                      >
                        iOS Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/hire-android-app-developer"
                        lassName={
                          activeMenu === "/hire-android-app-developer"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Android Developers
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="main_menu_inner mega_menu_set">
                  <h4>Full Stack Developers</h4>
                  <ul>
                    <li>
                      <Link
                        to="/hire-mean-stack-developers"
                        lassName={
                          activeMenu === "/hire-mean-stack-developers"
                            ? "active-menu"
                            : ""
                        }
                      >
                        MeanStack Developers
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/hire-php-developers"
                        lassName={
                          activeMenu === "/hire-php-developers"
                            ? "active-menu"
                            : ""
                        }
                      >
                        PHP Developers
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/hire-python-developers"
                        lassName={
                          activeMenu === "/hire-python-developers"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Python Developers
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/hire-nodejs-developers"
                        lassName={
                          activeMenu === "/hire-nodejs-developers"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Node.js Developers
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/hire-angular-developer"
                        lassName={
                          activeMenu === "/hire-angular-developer"
                            ? "active-menu"
                            : ""
                        }
                      >
                        AngularJs Developers
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/hire-vuejs-developer"
                        lassName={
                          activeMenu === "/hire-vuejs-developer"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Vue Developers
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/hire-reactjs-developers"
                        lassName={
                          activeMenu === "/hire-reactjs-developers"
                            ? "active-menu"
                            : ""
                        }
                      >
                        ReactJs Developers
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="main_menu_inner mega_menu_set">
                  <h4>Functional Resources</h4>
                  <ul>
                    <li>
                      <Link
                        to="/business-analyst"
                        lassName={
                          activeMenu === "/Business-Aanalyst"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Business Analyst
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/project-managers"
                        lassName={
                          activeMenu === "/Project-Managers"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Project Managers
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/software-testers"
                        lassName={
                          activeMenu === "/Software-Testers"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Software Testers
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/hire-designers"
                        lassName={
                          activeMenu === "/Hire-Designers" ? "active-menu" : ""
                        }
                      >
                        {" "}
                        Hire Designers
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="main_menu_inner">
                  <h4>Experts In</h4>
                  <ul>
                    <li>
                      <Link
                        to="/hire-ui-ux-developers"
                        lassName={
                          activeMenu === "/hire-ux-ui-developers"
                            ? "active-menu"
                            : ""
                        }
                      >
                        UI/UX Developer
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </li>
      <li
        className={
          parentMenu === "about"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link className={activeMenu === "" ? "active-menu" : ""}>Company</Link>
        <ul className="sub-menu submenu">
          <div className="border_menu ">
            <div className="row">
              <div className="col-lg-4">
                <div className="main_menu_inner mega_menu">
                  <h4>About Metaqualt</h4>
                  <ul>
                    <li>
                      <Link
                        to="/about"
                        lassName={activeMenu === "/about" ? "active-menu" : ""}
                      >
                        About us
                      </Link>{" "}
                    </li>

                    <li>
                      <Link
                        to="/client-testimonials"
                        lassName={
                          activeMenu === "/Client-Testimonials"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Client Testimonials
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/contact"
                        lassName={
                          activeMenu === "/Client-Testimonials"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Contact Us
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="main_menu_inner mega_menu">
                  <h4>Careers</h4>
                  <ul>
                    <li>
                      <Link
                        to="/current-openings"
                        lassName={
                          activeMenu === "/Current-Openings"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Current Openings
                      </Link>{" "}
                    </li>

                    <li>
                      <Link
                        to="/life-at-metaqualt"
                        lassName={
                          activeMenu === "/Life-At-Metaqualt"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Life At Metaqualt
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="main_menu_inner">
                  <h4>Resources</h4>
                  <ul>
                    <li>
                      <Link
                        to="/blog"
                        lassName={activeMenu === "/blog" ? "active-menu" : ""}
                      >
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/white-paper"
                        lassName={
                          activeMenu === "/white-paper" ? "active-menu" : ""
                        }
                      >
                        White paper
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </li>
      <li className={parentMenu === "Portfolio" ? "current-menu-item" : ""}>
        <Link
          to="/portfolio"
          className={activeMenu === "/Portfolio" ? "active-menu" : ""}
        >
          Portfolio
        </Link>
      </li>
      <Link className="getTouch" to="/contact">
        Get In Touch
      </Link>
    </React.Fragment>
  );
};
export default MenuItems;
