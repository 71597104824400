import React from "react";

import WeServes from "./it-services";
import SectionTitle from "../../components/Common/SectionTitle";

import WhyChoose from "./whychooseus";

import TechSlider from "./TechSlider";


// Working Process Images

import roundImg from '../../assets/img/about/salesforcecloud1.webp';
import aboutImg from '../../assets/img/about/salesforcecloud.webp';

// Service Icons


import effectImg2 from '../../assets/img/about/dotted-3.webp';
import effectImg3 from '../../assets/img/about/shape3.webp';
import ConsultNowBtn from '../../components/ConsultNowBtn';

const Salesforce_cloud_main = () => {
  const options = {
    items: 3,
    nav: false,
    dots: true,
    margin: 30,
    rewind: false,
    autoplay: false,
    stagePadding: 30,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    loop: true,
    center: false,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        dots: false,
      },
      768: {
        items: 2,
        stagePadding: 0,
        dots: true,
      },
      992: {
        items: 3,
        stagePadding: 0,
      },
      1500: {
        items: 3,
        stagePadding: 0,
      },
    },
  };

  return (
    <React.Fragment>
      {/* <!-- banner section end --> */}
      <div id="rs-about" className="rs-about disc style3 pt-150 pb-0 md-pt-60 row_rev_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 mb-20">
              <div className="rs-animation-image md-pb-10 md-pt-10">
                <div className="pattern-img">
                  <img src={roundImg} alt="" />
                </div>
                <div className="middle-img ">
                  <img className="dance3" src={aboutImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2  pt-70 md-pt-10 "
                subtitleClass="sub-text style-bg"
                subtitle="WHO WE ARE"
                titleClass="title mb-25"
                title="Boost Sales with MetaQualt’s Sales Cloud Consulting"
                descClass="desc pb-15"
                description="At MetaQualt, we specialise in Salesforce Sales Cloud consulting services designed to optimise your sales processes, boost customer engagement, and accelerate revenue growth. Our team of certified Salesforce consultants brings years of experience working with Sales Cloud to help businesses of all sizes harness the full potential of this powerful CRM software."
                secondDescClass="mb-10 pb-16"
                secondDescription={
                  <>
                    <strong className="fontsizw-20">
                      Unlock the Power of Salesforce Sales Cloud
                    </strong>
                    <br />
                    &nbsp;Whether you’re implementing Sales Cloud for the first
                    time, looking to optimise your existing setup, or seeking
                    ongoing support and maintenance, MetaQualt has the expertise
                    to help you achieve your sales goals.
                  </>
                }
              />

              <ConsultNowBtn />
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>

      <WeServes />

      <TechSlider />

      <WhyChoose />
    </React.Fragment>
  );
};

export default Salesforce_cloud_main;
