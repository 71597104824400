import React, { useState } from "react";
import Card from "./Card";

const Cards = (data) => {
  const [openIndex, setOpenIndex] = useState(0);

  const AccordianData = data.data;

  if (!data) return null;
  return (
    <div>
    {AccordianData.map((n, i) => (
      <Card
        key={i}
        {...n}
        isOpen={i === openIndex ? true : false } //First Is open , rest is close
        setIsOpen={() => { i === openIndex ? setOpenIndex(null) : setOpenIndex(i) }}
      />
    ))}
  </div>
  );
};

export default Cards;
