import Blockchain from "../../assets/img/WhitePaper/blockchain.webp";
import Education from "../../assets/img/WhitePaper/education.webp";
import Pharmaceutical from "../../assets/img/WhitePaper/pharmaceutical.webp";
import Healthcare from "../../assets/img/WhitePaper/healthcare.webp";
import Salesforce from "../../assets/img/WhitePaper/salesforce.webp";
import SME from "../../assets/img/WhitePaper/sme.webp";

export const data = [
  {
    id: 1,
    blogTitle: "The Role of Blockchain in Transforming Healthcare",
    blogDesc:
      "The healthcare industry, while critical to human well-being, has long been plagued by inefficiencies, security concerns, and data fragmentation. The reliance on legacy systems has resulted in increased instances of data breaches, miscommunication between providers, and the inability to provide a seamless flow of patient information. Blockchain technology is emerging as a revolutionary solution to address these issues, providing enhanced security, privacy, and data management capabilities.",
    blogButtonClass: "blog-button inner-blog",
    img: Blockchain,
  },
  {
    id: 2,
    blogTitle: "The Role of Blockchain in Revolutionizing Education",
    blogDesc:
      "The education sector is evolving, with digital platforms and online courses reshaping how knowledge is delivered and validated. However, the integrity of credentials, transparency in certification, and secure data management remain significant challenges. Blockchain technology offers a powerful solution to these issues, allowing for secure, tamper-proof records, efficient verification processes, and the decentralization of learning systems.",
    blogButtonClass: "blog-button inner-blog",
    img: Education,
  },
 
  {
    id: 3,
    blogTitle: "The Role of Blockchain in the Pharmaceutical Industry",
    blogDesc:
      "The education sector is evolving, with digital platforms and online courses reshaping how knowledge is delivered and validated. However, the integrity of credentials, transparency in certification, and secure data management remain significant challenges. Blockchain technology offers a powerful solution to these issues, allowing for secure, tamper-proof records, efficient verification processes, and the decentralization of learning systems.",
    blogButtonClass: "blog-button inner-blog",
    img: Pharmaceutical,
  },
  {
    id: 4,
    blogTitle: "Leveraging Blockchain Technology for Indian Governance",
    blogDesc:
      "India has embarked on a transformative journey towards digitization, with the government launching several initiatives like Digital India, Aadhar, and e-Governance to modernize its operations and services. However, issues related to transparency, accountability, data security, and trust persist. Blockchain technology offers a secure, decentralized, and tamper-proof solution that can enhance the efficiency and reliability of government services across various sectors.",
    blogButtonClass: "blog-button inner-blog",
    img: Healthcare,
  },
  {
    id: 5,
    blogTitle: "How Salesforce Helps You Build Your Business",
    blogDesc:
      "In a rapidly evolving digital landscape, businesses must stay ahead of the curve to remain competitive. Salesforce, the world’s leading Customer Relationship Management (CRM) platform, has become a cornerstone for companies seeking to transform customer experiences, drive sales, and streamline operations. By leveraging Salesforce, organizations can unify their processes, gain actionable insights, and scale their operations effortlessly.",
    blogButtonClass: "blog-button inner-blog",
    img: Salesforce,
  },
  {
    id: 6,
    blogTitle: "Salesforce as a solution for MSME Sector and Manufacturers with Pan-India Distribution",
    blogDesc:
      "India’s MSME (Micro, Small, and Medium Enterprises) sector forms the backbone of its economy, contributing 30% of the GDP and providing employment to over 110 million people. Similarly, manufacturers with pan-India distribution networks face complex challenges in managing their operations, ensuring customer satisfaction, and scaling effectively.",
    blogButtonClass: "blog-button inner-blog",
    img: SME,
  },
];
