import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
import Web_developer from '../../components/Common/Breadcumb/web_developers';
import WebDevelopment from './webDevelopment';
import ScrollToTop from '../../components/Common/ScrollTop';
// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.webp';


const WebDevelopService = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}
                <Web_developer
                    pageTitle="Web Development Services"
                    titleClass="page-title"
                    pageName="Web Development Services "
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="Web Development"
                />
                {/* breadcrumb-area-start */}

                {/* ServiceOneMain */}
                <WebDevelopment />
                {/* ServiceOneMain */}
            </div>
            <ScrollToTop />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default WebDevelopService;