import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
import Data_Science_m from '../../components/Common/Breadcumb/Data_Science_Main';
import DataScienceSec from './DataScienceMain';
import ScrollToTop from '../../components/Common/ScrollTop';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.webp';


const Data_Science = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}
                <Data_Science_m
                    pageTitle="Data Science"
                    titleClass="page-title"
                    pageName="Data Science Services"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="Data Science"
                />
                {/* breadcrumb-area-start */}

                {/* ServiceOneMain */}
                <DataScienceSec />
                {/* ServiceOneMain */}
            </div>
            <ScrollToTop />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default Data_Science;