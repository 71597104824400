import React from "react";
import CounterHomeFive from "../../components/Elements/Counter/CounterHomeFive";
import SectionTitle from "../Common/SectionTitle";
import ContactUsForm from "../../components/Contact/ContactUsForm";
import Brand from "../Common/Brand";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

// WhyChooseUs Images
import mainImg from "../../assets/img/choose/2.webp";
import animImg1 from "../../assets/img/choose/dotted-1.webp";
import animImg2 from "../../assets/img/choose/dotted-2.webp";
import iconOne from "../../assets/img/choose/icons/1.webp";
import iconTwo from "../../assets/img/choose/icons/2.webp";
import iconThree from "../../assets/img/choose/icons/3.webp";
import bannerImg from "../../assets/img/banner/career_img1.webp";
import effectImg2 from "../../assets/img/about/dotted-3.webp";
import effectImg3 from "../../assets/img/about/shape3.webp";
import FileInput from "../FileInput";

const CareersMainPage = (props) => {
  const { submitBtnClass } = props;
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-6 col-md-12">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 pt-30 pb-30"
                subtitleClass="sub-text style-bg"
                subtitle="WE ARE HERE TO HELP YOU"
                titleClass="title pb-25"
                title="Boost Your Career, Graph With New Heights"
                descClass="desc pb-16"
                description="With our help, our clients can achieve amazing things in their own unique way. Our work leaves an indelible impression on the first glance, and offers outstanding results on the second. Take a look behind the scenes at some of our most popular works."
                secondDescClass="desc pb-16"
                secondDescription="Become a pro by learning from the best. We can give you a vital boost to your career when you join our Web & App Development Company."
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>
      {/* <!-- banner section end --> */}

      {/* <!-- choose-area-start --> */}
      <div className="rs-faq choose-area pt-130 pb-130">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 mb-50 text-center"
            subtitleClass="sub-text style-bg text-center"
            subtitle="THINK CAREER PROGRESSION"
            titleClass="title pb-10 text-center"
            title="We're Hiring!"
            descClass="desc"
            description="Ready to start your career with us? Let's create your success story."
            effectClass="heading-line"
          />
          <div className="row">
            <div className="col-xl-12 col-lg-12 mb-30">
              <div className="choose-wrapper">
                <div className="faq-box faq-2-box">
                  <Accordion className="accodion-style1" preExpanded={"0"}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="col-lg-12 col-md-12 pl-0 pr-0">
                            <div className="job-detail ree-card">
                              <div className="job-title">
                                <h4>Blockcain Developer</h4>
                                <p className="mb-10">
                                  Vacancies: 1 / Work Status: Full time /
                                  Experience: 2 to 7 Years
                                </p>
                              </div>
                              <div className="apply-btn pt-2 pb-2">
                                <a
                                  href="#ContactForm"
                                  class="readon buy-now get-in"
                                  target="_self"
                                  style={{ top: 0 }}
                                >
                                  Apply Now
                                </a>
                              </div>
                            </div>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="col-lg-12 col-md-12 pl-0 pr-0">
                            <div className="job-detail ree-card">
                              <div className="job-title">
                                <h4>Python Developer</h4>
                                <p className="mb-10">
                                  Vacancies: 1 / Work Status: Full time /
                                  Experience: 2 to 6 Years
                                </p>
                              </div>
                              <div className="apply-btn pt-2 pb-2">
                                <a
                                  href="#ContactForm"
                                  class="readon buy-now get-in"
                                  target="_self"
                                  style={{ top: 0 }}
                                >
                                  Apply Now
                                </a>
                              </div>
                            </div>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="col-lg-12 col-md-12 pl-0 pr-0">
                            <div className="job-detail ree-card">
                              <div className="job-title">
                                <h4>Business Development Executive(IT)</h4>
                                <p className="mb-10">
                                  Vacancies: 1 / Work Status: Full time /
                                  Experience: 1 to 5 Years
                                </p>
                              </div>
                              <div className="apply-btn pt-2 pb-2">
                                <a
                                  href="#ContactForm"
                                  class="readon buy-now get-in"
                                  target="_self"
                                  style={{ top: 0 }}
                                >
                                  Apply Now
                                </a>
                              </div>
                            </div>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- choose-area-end --> */}

      <div className="rs-call-us relative pt-80 mb-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pb-50 md-pb-15">
              <div className="rs-contact-box text-center">
                <SectionTitle
                  sectionClass="sec-title3 text-center"
                  subtitleClass="sub-text style-bg"
                  subtitle="CALL US 24/7"
                  titleClass="title"
                  title="Boosting Growth With a Trusted Tech Partner!"
                  descClass="desc"
                  description="Get assistance from the top IT solution provider company & turn your project idea into reality. Our representative will reply you shortly."
                />
              </div>
            </div>
            <div class="job-apply-form col-lg-12">
              <ul>
                <li>
                  <a href="mailto:career@metaqualt.com">
                    <i class="fa fa-envelope-open"></i> career@metaqualt.com
                  </a>
                </li>
                <li>
                  <a href="tel:(+91) 7096069000">
                    <i class="fa fa-phone"></i> (+91) 7096069000
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Section Start */}
      <div
        id="ContactForm"
        className="rs-contact rs-why-choose style2 pt-120 pb-80 md-pt-60"
      >
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-60"
            subtitleClass="sub-text style-bg"
            subtitle="Let's start the discussion"
            titleClass="title title"
            title="Are You Filled With The Passion To Try Fresh, New & innovative Subjects? "
            effectClass="heading-line"
            descClass="desc w-80"
            description="Are you an expert in the industry that we have an open position for? We can talk more."
          />
          <div className="row bx-shadow">
            <div className="col-lg-12 col-md-12 pl-30 md-pl-14">
              <div className="contact-widget">
                <form id="contact-form mt-20 pt-25 pb-25 mb-20" action="#">
                  <div className="row">
                    <div className="col-md-6 mb-30">
                      <input
                        className="from-control"
                        type="text"
                        id="f-name"
                        name="f-name"
                        placeholder="First Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-30">
                      <input
                        className="from-control"
                        type="text"
                        id="l-name"
                        name="l-name"
                        placeholder="Last Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-30">
                      <input
                        className="from-control"
                        type="text"
                        id="email"
                        name="email"
                        placeholder="E-Mail"
                        required
                      />
                    </div>

                    <div className="col-md-6 mb-30">
                      <input
                        className="from-control"
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="Phone Number"
                        required
                      />
                    </div>

                    <div className="col-md-12 mb-30">
                      <FileInput />
                    </div>
                    <div className="col-12 mb-30">
                      <textarea
                        className="from-control"
                        id="message"
                        name="message"
                        placeholder="Your message Here"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="btn-part pb-20">
                    <button
                      className={
                        submitBtnClass
                          ? submitBtnClass
                          : "readon buy-now get-in submit"
                      }
                      type="submit"
                      style={{ top: 0 }}
                    >
                      Submit Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Section End */}

      <div className="rs-why-choose style1 pt-80 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 hidden-md">
              <div className="image-part">
                <img src={mainImg} alt="Main Image" />
              </div>
              <div className="animation style2 modify-style">
                <div className="top-shape">
                  <img className="dance" src={animImg2} alt="Animation Two" />
                </div>
                <div className="bottom-shape">
                  <img className="dance2" src={animImg1} alt="Animation One" />
                </div>
              </div>
            </div>
            <div className="col-lg-7 pl-60 md-pl-14">
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                titleClass="title title"
                title="Hire Dedicated Web Designer at your flexibility."
                descClass="desc"
                description="Working with us directly, you can take advantage of the benefits of UI Developer on hire available to several IT companies in abroad."
              />
              <div className="services-wrap mb-24">
                <div className="services-icon">
                  <img src={iconOne} alt="Choose Icon" />
                </div>
                <div className="services-text">
                  <h3 className="title">
                    <a href="#">Full Time</a>
                  </h3>
                  <p className="services-txt">
                    {" "}
                    9 hours/Day for Min 30 Days contract. Monthly Payment cycle.
                  </p>
                </div>
              </div>
              <div className="services-wrap mb-24">
                <div className="services-icon">
                  <img src={iconTwo} alt="Choose Icon" />
                </div>
                <div className="services-text">
                  <h3 className="title">
                    <a href="#">Part Time</a>
                  </h3>
                  <p className="services-txt">
                    {" "}
                    4 hours/Day for Min 30 Days contract. Weekly Payment cycle.
                  </p>
                </div>
              </div>
              <div className="services-wrap">
                <div className="services-icon">
                  <img src={iconThree} alt="Choose Icon" />
                </div>
                <div className="services-text">
                  <h3 className="title">
                    <a href="#">Hourly</a>
                  </h3>
                  <p className="services-txt">
                    {" "}
                    Flexible hours/Day for Min 50 Hours contract. daily payment
                    cycle.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CareersMainPage;
