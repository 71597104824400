import React, {useState} from "react";
import FileInput from "../FileInput";
// import "./ContactUsForm.css"

const ContactUsForm = (props) => {
  const { submitBtnClass } = props;

  return (
    <form id="contact-form mt-20 pt-25 pb-25 mb-20" action="#">
      <div className="row">
        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="f-name"
            name="f-name"
            placeholder="First Name"
            required
          />
        </div>
        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="l-name"
            name="l-name"
            placeholder="Last Name"
            required
          />
        </div>
        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="email"
            name="email"
            placeholder="E-Mail"
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="phone"
            name="phone"
            placeholder="Phone Number"
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="website"
            name="website"
            placeholder="Your Website"
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input className="from-control" type="file" id="fileUpload" name="fileUpload" required />
        </div>
        <div className="col-12 mb-30">
          <textarea
            className="from-control"
            id="message"
            name="message"
            placeholder="Your message Here"
            required
          ></textarea>
        </div>
      </div>
      <div className="btn-part pb-20">
        <button
          className={
            submitBtnClass ? submitBtnClass : "readon buy-now get-in submit"
          }
          type="submit"
          style={{ top: 0 }}
        >
          Submit Now
        </button>
      </div>
    </form>
  );
};

export default ContactUsForm;
