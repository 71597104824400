import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';



// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.webp';
import Power_BI from '../../components/Common/Breadcumb/Power_BI';
import ScrollToTop from '../../components/Common/ScrollTop';

import Power_BI_Main from './Power_BI_Main';


const Power_BI_sec = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}
               
                <Power_BI 
                 pageTitle="Power BI Consulting Services"
                 titleClass="page-title"
                 pageName="Power BI"
                 breadcrumbsImg={bannerbg}
                 animatedFont="lined-Font dance2"
                 animatedFontsize="Hire Expert Power BI Consultants" />
                {/* breadcrumb-area-start */}

                {/* ServiceOneMain */}
                {/* < Power_BIsec/> */}
               <Power_BI_Main />
                {/* ServiceOneMain */}
            </div>
            <ScrollToTop />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default Power_BI_sec;