import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";

import bannerImg from "../../assets/img/banner/Applicationintigration2.webp";





import effectImg2 from "../../assets/img/about/dotted-3.webp";
import effectImg3 from "../../assets/img/about/shape3.webp";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";

import mean_1 from "../../assets/img/about/application3.webp";
import OurServices from "./weProvide";
import WhyChoose from "./whychooseus";

const ApplicationIntagration_Main = () => {
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about row_rev_sec pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-5 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="We are Different"
                titleClass="title pb-25"
                title="MetaQualt – Your Trusted Partner for Application Integration

                              "
                descClass="desc pb-5"
                description="Stay ahead in today’s fast-paced business world with agile, seamless integration that drives success. MetaQualt helps you connect business applications, databases, and business intelligence systems effortlessly, ensuring smooth data flow across your organization with our cutting-edge application integration services.
From simple point-to-point integrations to more complex, strategic connections between enterprise applications, we offer innovative solutions that break down data silos. Our services eliminate inefficiencies, streamline processes, and ensure data accuracy, empowering your business to operate with maximum productivity and consistency. Choose MetaQualt to accelerate your business and stay ahead of the competition.
"
              />
              <ConsultNowBtn />
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>

      <div className="style3 pt-100 pb-100 md-pt-40 md-pb-40 provide_sec">
        <div className="container relative">
          <div className="sec-left">
            <h2 className="title"> What We Provide</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div className="row align-items-left">
                <div className="col-lg-12 col-md-12">
                  <SectionTitle
                    sectionClass="sec-title2 text-left mb-30"
                    subtitleClass="sub-text style-bg"
                    subtitle="Application  Integration Services"
                    titleClass="title pb-10"
                    title="Why Software Integration is a Game-Changer
                                      "
                    descClass="desc"
                    description="In today’s fast-changing business landscape, the ability to quickly adapt and implement changes is essential. Enterprise application integration (EAI) empowers your business to improve operations, enhance data collection and analysis, and boost ROI by enabling seamless information exchange across your systems.tructure, we guarantee a seamless migration journey with minimal disruption."
                  />
                </div>

                <div className="col-lg-12 col-md-12">
                  <ul className="check-square">
                    <li className="">
                      {" "}
                      Helps reduce errors through seamless integration in
                      software, ultimately unlocking your total potential of its
                      software.
                    </li>
                    <li className="">
                      Synchronize data, reduce development costs by using SOA,
                      and enhance operational efficiency with MetaQualt.
                    </li>
                    <li className="">
                      {" "}
                      Leverage the power of one IT infrastructure to optimize
                      performance and collaboration across partners, customers,
                      vendors, and teams.
                    </li>
                    <li className="">
                      Save costs and integrate and upgrade software with
                      increasing agility, compliance, and flexibility.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="text-center relative">
                <img className="main" src={mean_1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <OurServices />

      <WhyChoose />

      <div className="accordions pb-50 pt-70">
        <div className="container">
          <FAQHeader
            data={faqDescriptions["Application_Integration_Services"]}
          />

          <AccordionCards data={accordions.Application_Integration_Services} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ApplicationIntagration_Main;
