import React, { Suspense } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
} from "react-router-dom";

//Custom Components

import Home from "../pages/home";

import TeamDetails from "../pages/team/team-details";

import BlogDetails from "../pages/blog/blog-details";
import Services from "../pages/service";
import WebDevelopment from "../pages/web-development";
import Solution from "../pages/solution";
import AngularJs from "../pages/angularJs-development";
import Codeigniter from "../pages/codeigniter-development";
import VueJs from "../pages/vueJs-development";
import JavaScripts from "../pages/javaScripts-development";
import WordpressDevelopment from "../pages/cms-development";
import CaseStudtyStyleOne from "../pages/case-study/style-1";
import phpDevelopment from "../pages/php-development";
import ReactJS from "../pages/reactJs-development";
import Laravel from "../pages/laravel-development";
import MobileAppDevelopment from "../pages/mobile-app-development";
import AndroidAppDevelopment from "../pages/android-app-development";
import IosAppDevelopment from "../pages/ios-app-development";
import webDesign from "../pages/ux-ui-design";
import Machin_learning_sec from "../pages/machine_learning";
import QualityEngineering from "../pages/quality_engineering";
import ArtificialIntelligenceSec from "../pages/artificial-intelligence";
import NodeJS from "../pages/nodeJs-development";
import Front_End from "../pages/front-end";
import eCommerce from "../pages/eCommerce-development";
import Careers from "../pages/careers";
import EcommerceSolutionsMain from "../pages/ecommerce-solutions";
import HireDevelopersMain from "../pages/hire-dedicated-developers";
import DigitalMarketSec from "../pages/digitl-market";
import DataScienceSec from "../pages/data-science";
import CRM_Development_sec from "../pages/crm_development";
import SoftwareDevelopmentsec from "../pages/softwaredevelopment";
import Microsoft_dev_sec from "../pages/microsoftNETdev";
import Cloud_consulting_sec from "../pages/cloudconsulting";
import Application_migration_sec from "../pages/applicationmigration";
import Salesforceconsulting_solution from "../pages/salesforceConsultingServices";
import Salesforce_Lightning from "../pages/SalesforceLightningServices";
import Salesforce_implementation from "../pages/salesforceimplementation";

import Salesforce_cloud from "../pages/salescloudconsulting";

import HireCrossPlatformAppDevelopers from "../pages/hire-cross-platform-app-developers";
import HireWebDevelopers from "../pages/hire-web-developers";

import Faq from "../pages/faq";

import LoadTop from "../components/Common/ScrollTop/LoadTop";

import ErrorPage from "../pages/ErrorPage";
import Salesforce_AppExchange from "../pages/salesforceAppExchange";
import SaasDevMain from "../pages/SaasDevelopment/SaasDevMain";
import SaasAppDevelopment from "../pages/SaasDevelopment";
import Power_BI_sec from "../pages/Power BI";
import application_integration from "../pages/Application_Integration";
import Cloud_App_Devlopment from "../pages/Cloud_App_Development";
import Tableau from "../pages/Tableau";
import Tableau_M from "../pages/Tableau";
import Database_Management_Main from "../pages/Database-Management";
import White_Paper from "../pages/white-paper";
import WhitePaperDetail from "../pages/white-paper/WhitePaperDetail";
import DetailPage from "../pages/life_metaqualt/DetailPage";

// Solution Pages

const StartupSolution_sec = React.lazy(() =>
  import("../pages/startup-solutions")
);
const Enterprise_Solution_sec = React.lazy(() =>
  import("../pages/enterprise-solution")
);
const Devops_SolutionsSec = React.lazy(() =>
  import("../pages/devops_Solutions")
);
const CloudsolutionsmainSec = React.lazy(() =>
  import("../pages/Cloud_SolutionS")
);
const OndemandBusinessSolutions = React.lazy(() =>
  import("../pages/OndemandBusinessSolutions")
);
const FintechAppMain = React.lazy(() =>
  import("../pages/fintech-app-solution")
);
const FitnessAppMain = React.lazy(() =>
  import("../pages/fitness-wellness-app-solutions")
);
const BusinessAppSolution = React.lazy(() =>
  import("../pages/business-app-solution")
);
const TravelAppMain = React.lazy(() => import("../pages/travel-app-solutions"));
const RealEstateAppMain = React.lazy(() =>
  import("../pages/real-estate-app-solution")
);
const HealthcareAppMain = React.lazy(() =>
  import("../pages/healthcare-app-solution")
);
const SocialMediaAppMain = React.lazy(() =>
  import("../pages/social-media-app")
);

// Hire Offshare Developers

const HirePHPDevelopersMain = React.lazy(() =>
  import("../pages/hire-php-developers")
);
const HireQaDevelopersMain = React.lazy(() =>
  import("../pages/hire-qa-developers")
);
const HireReactNativeDevelopersMain = React.lazy(() =>
  import("../pages/hire-react-native-developers")
);
const HireMeanStackDevelopersMain = React.lazy(() =>
  import("../pages/hire-mean-stack-developers")
);
const HireFullStackDevelopersMain = React.lazy(() =>
  import("../pages/hire-full-stack-developers")
);
const HireFlutterDevelopersMain = React.lazy(() =>
  import("../pages/hire-flutter-developers")
);
const HirePythonDevelopersMain = React.lazy(() =>
  import("../pages/hire-python-developers")
);
const HireLaravelDevelopersPage = React.lazy(() =>
  import("../pages/hire-laravel-developers")
);
const HireNodeJsDevelopersPage = React.lazy(() =>
  import("../pages/hire-nodejs-developers")
);
const HireReactJsDevelopersPage = React.lazy(() =>
  import("../pages/hire-reactjs-developers")
);
const HireAngularJsDevelopersPage = React.lazy(() =>
  import("../pages/hire-angularjs-developer")
);
const HireiOSAppDevelopers = React.lazy(() =>
  import("../pages/hire-ios-app-developer")
);

const HireVueJsDevelopersPage = React.lazy(() =>
  import("../pages/hire-vuejs-developer")
);

const HireAndroidAppDevelopers = React.lazy(() =>
  import("../pages/hire-android-app-developer")
);

const HireWordpressDevelopers = React.lazy(() =>
  import("../pages/hire-wordpress-developer")
);

const BusinessAnalystSec = React.lazy(() =>
  import("../pages/business_analyst")
);

const Projectmanagerssec = React.lazy(() =>
  import("../pages/project_managers")
);

const Softwaretesterssec = React.lazy(() =>
  import("../pages/software-testers")
);

const HiredesignersMainec = React.lazy(() => import("../pages/Hire-designers"));

const CriptoSec = React.lazy(() => import("../pages/Crypto"));

const blockcainSec = React.lazy(() => import("../pages/Blockcain"));

const FintechSec = React.lazy(() => import("../pages/Fintech"));

const SalesforceSec = React.lazy(() => import("../pages/Salesforce"));

const HireWebDesignerDevelopers = React.lazy(() =>
  import("../pages/hire-ux-ui-developers")
);

// Company

const About = React.lazy(() => import("../pages/about"));

const OurAchievementssec = React.lazy(() => import("../pages/our_achievement"));

const Ourleadingmetaqualtsec = React.lazy(() =>
  import("../pages/our_leading_metaqualt")
);

const ClientTestimonialsSec = React.lazy(() =>
  import("../pages/Client-Testimonials")
);

const CurrentopeningsSec = React.lazy(() =>
  import("../pages/Current_openings/Currentopenings")
);

const WhyJoinUsMainSec = React.lazy(() => import("../pages/why_Join_us"));

const Employee_RewardsMainSec = React.lazy(() =>
  import("../pages/Employee_Rewards")
);

const LifeAtMetaqualtSection = React.lazy(() =>
  import("../pages/life_metaqualt")
);

const Contact = React.lazy(() => import("../pages/contact"));

const Blog = React.lazy(() => import("../pages/blog"));

const BlogDetail = React.lazy(() => import("../pages/blog/BlogDetail"));

const Portfolio = React.lazy(() => import("../pages/Portfolio"));

const App = () => {
  return (
    <div className="App">
      <Router>
        <LoadTop />

        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/service" exact component={Services} />
            <Route path="/solution" component={Solution} />
            <Route path="/php-development" exact component={phpDevelopment} />
            <Route path="/angularJs-development" exact component={AngularJs} />
            <Route
              path="/Hire-designers"
              exact
              component={HiredesignersMainec}
            />
            <Route
              path="/Mobile-App-Development"
              exact
              component={MobileAppDevelopment}
            />
            <Route
              path="/android-app-development"
              exact
              component={AndroidAppDevelopment}
            />
            <Route
              path="/Project-Managers"
              exact
              component={Projectmanagerssec}
            />
            <Route
              path="/ios-app-development"
              exact
              component={IosAppDevelopment}
            />
            <Route
              path="/ECommerce-Development"
              exact
              component={EcommerceSolutionsMain}
            />
            <Route
              path="/business-analyst"
              exact
              component={BusinessAnalystSec}
            />
            <Route path="/reactJs-development" exact component={ReactJS} />
            <Route
              path="/codeigniter-development"
              exact
              component={Codeigniter}
            />
            <Route path="/Ux-Ui-Design" exact component={webDesign} />
            <Route path="/Digitle-Market" exact component={DigitalMarketSec} />
            <Route
              path="/Quality-Engineering"
              exact
              component={QualityEngineering}
            />
            <Route
              path="/Artificial-Intelligence"
              exact
              component={ArtificialIntelligenceSec}
            />
            <Route
              path="/microsoft-dotnet"
              exact
              component={Microsoft_dev_sec}
            />
            <Route
              path="/Cloud-App-Development"
              exact
              component={Cloud_App_Devlopment}
            />
            <Route
              path="/SaaS-App-Development"
              exact
              component={SaasAppDevelopment}
            />
            <Route path="/Power-BI" exact component={Power_BI_sec} />
            <Route
              path="/Application-Migration"
              exact
              component={Application_migration_sec}
            />
            <Route
              path="/Application-Integration"
              exact
              component={application_integration}
            />
            <Route path="/Tableau" exact component={Tableau_M} />

            <Route
              path="/Database-Management"
              exact
              component={Database_Management_Main}
            />

            <Route
              path="/Cloud-Consulting"
              exact
              component={Cloud_consulting_sec}
            />
            <Route
              path="/Machine-Learning"
              exact
              component={Machin_learning_sec}
            />
            <Route path="/Data-Science" exact component={DataScienceSec} />
            <Route
              path="/CRM-Development"
              exact
              component={CRM_Development_sec}
            />
            <Route
              path="/Software-Development"
              exact
              component={SoftwareDevelopmentsec}
            />
            <Route
              path="/Salesforce-Consulting-Services"
              exact
              component={Salesforceconsulting_solution}
            />
            <Route
              path="/Salesforce-Lightning-Services"
              exact
              component={Salesforce_Lightning}
            />

            <Route
              path="/Salesforce-Exchange"
              exact
              component={Salesforce_AppExchange}
            />

            <Route
              path="/startup-solutions"
              exact
              component={StartupSolution_sec}
            />
            <Route
              path="/enterprise-solution"
              exact
              component={Enterprise_Solution_sec}
            />
            <Route
              path="/Devops-Solutions"
              exact
              component={Devops_SolutionsSec}
            />
            <Route
              path="/Cloud-Solutions"
              exact
              component={CloudsolutionsmainSec}
            />
            <Route
              path="/On-Demand-Business-Solutions"
              exact
              component={OndemandBusinessSolutions}
            />
            <Route
              path="/Software-Testers"
              exact
              component={Softwaretesterssec}
            />
            <Route path="/Crypto" exact component={CriptoSec} />
            <Route path="/Blockcain" exact component={blockcainSec} />
            <Route path="/Fintech" exact component={FintechSec} />
            <Route path="/Salesforce" exact component={SalesforceSec} />
            <Route
              path="/Salesforce-Implementation"
              exact
              component={Salesforce_implementation}
            />
            <Route
              path="/Our-Achievement"
              exact
              component={OurAchievementssec}
            />
            <Route
              path="/Our-Leading-Metaqualt"
              exact
              component={Ourleadingmetaqualtsec}
            />
            <Route
              path="/Client-Testimonials"
              exact
              component={ClientTestimonialsSec}
            />
            <Route
              path="/Current-Openings"
              exact
              component={CurrentopeningsSec}
            />
            <Route path="/Why-Join-Us" exact component={WhyJoinUsMainSec} />
            <Route
              path="/Employee-Rewards"
              exact
              component={Employee_RewardsMainSec}
            />
            <Route
              path="/Life-At-Metaqualt"
              exact
              component={LifeAtMetaqualtSection}
            />

            <Route
              path="/Life-Metaqualt/Life-Metaqualt-details/:cardId"
              component={DetailPage}
            />

            <Route
              path="/javaScripts-development"
              exact
              component={JavaScripts}
            />

            <Route path="/laravel-development" exact component={Laravel} />
            <Route path="/vueJs-development" exact component={VueJs} />

            <Route path="/nodeJs-development" exact component={NodeJS} />

            <Route path="/front-end" exact component={Front_End} />

            <Route path="/web-development" exact component={WebDevelopment} />

            <Route
              path="/CMS-Development"
              exact
              component={WordpressDevelopment}
            />

            <Route path="/eCommerce-development" exact component={eCommerce} />
            <Route
              path="/fintech-app-solution"
              exact
              component={FintechAppMain}
            />
            <Route
              path="/business-app-solution"
              exact
              component={BusinessAppSolution}
            />
            <Route
              path="/fitness-wellness-app-solutions"
              exact
              component={FitnessAppMain}
            />
            <Route
              path="/travel-app-solutions"
              exact
              component={TravelAppMain}
            />
            <Route
              path="/real-estate-app-solution"
              exact
              component={RealEstateAppMain}
            />
            <Route
              path="/healthcare-app-solution"
              exact
              component={HealthcareAppMain}
            />
            <Route
              path="/social-media-app"
              exact
              component={SocialMediaAppMain}
            />
            <Route
              path="/hire-dedicated-developers"
              exact
              component={HireDevelopersMain}
            />

            <Route
              path="/hire-php-developers"
              exact
              component={HirePHPDevelopersMain}
            />
            <Route
              path="/hire-qa-developers"
              exact
              component={HireQaDevelopersMain}
            />

            <Route
              path="/hire-react-native-developers"
              exact
              component={HireReactNativeDevelopersMain}
            />

            <Route
              path="/hire-mean-stack-developers"
              exact
              component={HireMeanStackDevelopersMain}
            />

            <Route
              path="/hire-full-stack-developers"
              exact
              component={HireFullStackDevelopersMain}
            />

            <Route
              path="/hire-flutter-developers"
              exact
              component={HireFlutterDevelopersMain}
            />

            <Route
              path="/hire-python-developers"
              exact
              component={HirePythonDevelopersMain}
            />

            <Route
              path="/hire-laravel-developers"
              exact
              component={HireLaravelDevelopersPage}
            />
            <Route
              path="/hire-nodejs-developers"
              exact
              component={HireNodeJsDevelopersPage}
            />
            <Route
              path="/hire-reactjs-developers"
              exact
              component={HireReactJsDevelopersPage}
            />
            <Route
              path="/hire-vuejs-developer"
              exact
              component={HireVueJsDevelopersPage}
            />
            <Route
              path="/hire-android-app-developer"
              exact
              component={HireAndroidAppDevelopers}
            />
            <Route
              path="/hire-android-app-developer"
              exact
              component={HireAndroidAppDevelopers}
            />
            <Route
              path="/hire-iphone-app-developer"
              exact
              component={HireiOSAppDevelopers}
            />
            <Route
              path="/hire-angular-developer"
              exact
              component={HireAngularJsDevelopersPage}
            />
            <Route
              path="/hire-wordpress-developer"
              exact
              component={HireWordpressDevelopers}
            />
            <Route
              path="/Hire-UI-UX-Developers"
              exact
              component={HireWebDesignerDevelopers}
            />

            <Route
              path="/SalesCloud-Consulting"
              exact
              component={Salesforce_cloud}
            />

            <Route
              path="/hire-web-developers"
              exact
              component={HireWebDevelopers}
            />
            <Route
              path="/hire-cross-platform-app-developers"
              exact
              component={HireCrossPlatformAppDevelopers}
            />
            <Route path="/Portfolio" exact component={Portfolio} />
            <Route path="/team/team-details" component={TeamDetails} />
            <Route path="/blog" exact component={Blog} />
            <Route path="/blog/:slug" component={BlogDetail} />
            
            <Route path="/case-study/style-1" component={CaseStudtyStyleOne} />
            <Route path="/careers" component={Careers} />
            <Route path="/faq" component={Faq} />
            <Route path="/contact" component={Contact} />
            <Route path="/white-paper" exact component={White_Paper} />
            <Route
              path="/white-paper/white-paper-details/:whitepaperId"
              component={WhitePaperDetail}
            />
            {/* Catch-all route for undefined paths */}
            <Route component={ErrorPage} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
