import React from "react";

import WeServes from "./it-services";
import SectionTitle from "../../components/Common/SectionTitle";

import WhyChoose from "./whychooseus";

import TechSlider from "./TechSlider";


// Working Process Images

import roundImg from '../../assets/img/about/salesforceco.webp';
import aboutImg from '../../assets/img/about/salesforceconusulting.webp';

// Service Icons


import effectImg2 from '../../assets/img/about/dotted-3.webp';
import effectImg3 from '../../assets/img/about/shape3.webp';
import ConsultNowBtn from '../../components/ConsultNowBtn';

const salesforceconsultingServices_Main = () => {
  const options = {
    items: 3,
    nav: false,
    dots: true,
    margin: 30,
    rewind: false,
    autoplay: false,
    stagePadding: 30,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    loop: true,
    center: false,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        dots: false,
      },
      768: {
        items: 2,
        stagePadding: 0,
        dots: true,
      },
      992: {
        items: 3,
        stagePadding: 0,
      },
      1500: {
        items: 3,
        stagePadding: 0,
      },
    },
  };

  return (
    <React.Fragment>
      {/* <!-- banner section end --> */}
      <div id="rs-about" className="rs-about style3 pt-150 pb-0 md-pt-60 row_rev_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 mb-20">
              <div className="rs-animation-image md-pb-10 md-pt-10">
                <div className="pattern-img">
                  <img src={roundImg} alt="" />
                </div>
                <div className="middle-img ">
                  <img className="dance3" src={aboutImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 pb-70 pt-70 md-pt-10 md-pb-20"
                subtitleClass="sub-text style-bg"
                subtitle="WHO WE ARE"
                titleClass="title mb-25"
                title="Transform Your Business with Salesforce and MetaQualt

                                "
                descClass="desc pb-15"
                description="In today’s fast-paced world, connecting with prospects, customers, partners, vendors, and employees using your enterprise data is crucial—and that’s where Salesforce excels.
Salesforce offers a robust suite of cloud-based tools that effortlessly link your organization to key stakeholders, driving impactful interactions and exceptional experiences and helping you meet your business goals.
"
                secondDescClass="mb-10 pb-16"
                secondDescription="At MetaQualt, we know that success with Salesforce requires a tailored approach. Our certified Salesforce consultants work closely with you to align the platform with your unique business needs, processes, and technologies. We're committed to delivering long-term value and helping you harness Salesforce to its fullest potential."
              />
              <ConsultNowBtn />
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>

      {/* about-area-start */}
      <WeServes />
      {/* about-area-end */}

      {/* TechSlider-start */}
      <TechSlider />

      <WhyChoose />
    </React.Fragment>
  );
};

export default salesforceconsultingServices_Main;
