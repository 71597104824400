import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon3 from '../../assets/img/service/style2/main-img/3.webp';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.webp';
import mainIcon34 from '../../assets/img/service/style2/main-img/34.webp';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.webp';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.webp';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.webp';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="6 Reasons Why You Should Hire Our Dedicated Developers?"
                    descClass="desc"
                    description="Our professionals have a reputation for delivering high-quality apps or nothing. The following six factors determine why you should pick us over other web development companies."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Dedicated Team"
                            Text="Our dedicated team put to use the knowledge and skills to craft a nice app for you. We leverage frameworks and technologies to make wonders happen."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="On Time Delivery"
                           Text="Rest assured! Your product will be delivered on time. We use cutting-edge technologies and agile methodologies for the on-time delivery of your project."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon34}
                            hoverIcon={mainIcon34}
                            Title="Flexible Engagement Models"
                            Text="Avoid working on rigid & strict parameters. Stick to us for your app development as we offer varied engagement & hiring models to choose from. We are flexible to your needs!"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile & Adaptive Development"
                            serviceURL="#"
                           Text="Speed up your web development process with us. Meet the most agile cross-functional teams and let your ideas materialize at a faster pace without losing quality."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Cost-Effective"
                            Text="Board your virtual development team and save on hiring, infrastructure, and staffing. Get the best-selling solutions at a reasonable rate with a quick onboarding process."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Absolute Project Control"
                           Text="Hire remote developers as per your project needs. With our prompt updates, feedback, & code delivery, you are always in control of your project throughout & even after the development."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;