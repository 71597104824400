import React, { Component, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import techImg1 from "../../../assets/img/technology/style2/1.webp";
import techImg2 from "../../../assets/img/technology/style2/2.webp";

import techImg4 from "../../../assets/img/technology/style2/4.webp";
import techImg5 from "../../../assets/img/technology/style2/5.webp";



import techImg10 from "../../../assets/img/technology/style2/java.webp";
import techImg9 from "../../../assets/img/technology/style2/9.webp";
import techImg8 from "../../../assets/img/technology/style2/8.webp";

import techImg13 from "../../../assets/img/technology/style2/13.webp";
import techImg12 from "../../../assets/img/technology/style2/12.webp";
import techImg15 from "../../../assets/img/technology/style2/15.webp";
import techImg17 from "../../../assets/img/technology/style2/Apple.webp";
import techImg18 from "../../../assets/img/technology/style2/18.webp";
import techImg19 from "../../../assets/img/technology/style2/19.webp";
import techImg20 from "../../../assets/img/technology/style2/20.webp";
import techImg21 from "../../../assets/img/technology/style2/21.webp";
import techImg22 from "../../../assets/img/technology/style2/22.webp";

import techImg26 from "../../../assets/img/technology/style2/26.webp";
import techImg28 from "../../../assets/img/technology/style2/28.webp";
import techImg32 from "../../../assets/img/technology/style2/32.webp";
import techImg33 from "../../../assets/img/technology/style2/33.webp";

import techImg45 from "../../../assets/img/technology/style2/45.webp";

import techImg48 from "../../../assets/img/technology/style2/48.webp";

const ServicesTabs = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  let tab1 = "Front-end",
    tab2 = "Back-end",
    tab3 = "Mobile Development",
    tab4 = "Database",
    tab5 = "Info & Cloud";
  const tabStyle = "nav nav-tabs";
  return (
    <div className="tab-area home_tab_area">
      <Tabs>
        <div className="row">
          <div className="col-lg-12">
            <TabList className={tabStyle}>
              <Tab>
                <h3>{tab1}</h3>
              </Tab>
              <Tab>
                <h3>{tab2}</h3>
              </Tab>
              <Tab>
                <h3>{tab3}</h3>
              </Tab>
              <Tab>
                <h3>{tab4}</h3>
              </Tab>
              <Tab>
                <h3>{tab5}</h3>
              </Tab>
            </TabList>
          </div>
          <div className="col-lg-12 pt-40 pb-30 md-mb-30">
            <div className="tab-content">
              <TabPanel>
                <div className="image-wrap">
                  <div class="react-tabs text-center">
                    <div className="rs-technology">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg1} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg2} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg4} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg5} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg9} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg8} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg45} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg22} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="image-wrap">
                  <div class="react-tabs text-center">
                    <div className="rs-technology">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg2} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg15} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg19} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg20} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg21} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg10} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg26} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg12} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="image-wrap">
                  <div class="react-tabs text-center">
                    <div className="rs-technology">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg48} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg13} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img pt-5">
                              <img src={techImg18} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg17} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="image-wrap">
                  <div class="react-tabs text-center">
                    <div className="rs-technology">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div
                              className="logo-img"
                              style={{ width: "100px" }}
                            >
                              <img src={techImg32} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg33} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="image-wrap">
                  <div class="react-tabs text-center">
                    <h3 class="poppins-bold"></h3>
                    <div className="rs-technology">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                          <div className="technology-item">
                            <div className="logo-img">
                              <img src={techImg28} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};

export default ServicesTabs;
