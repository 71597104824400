import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

// Working Process Images
import processImage1 from '../../assets/img/process/1.webp';
import processImage13 from '../../assets/img/process/13.webp';
import processImage14 from '../../assets/img/process/14.webp';
import processImage15 from '../../assets/img/process/15.webp';
// Service Icons
import imgMain26 from '../../assets/img/service/style2/main-img/26.webp';
import imgMain27 from '../../assets/img/service/style2/main-img/27.webp';
import imgMain28 from '../../assets/img/service/style2/main-img/28.webp';
import imgMain29 from '../../assets/img/service/style2/main-img/29.webp';
import imgMain30 from '../../assets/img/service/style2/main-img/30.webp';

// Service Image Icons

import serviceIcon14 from '../../assets/img/service/style2/main-img/fs1.webp';
import serviceIcon1 from '../../assets/img/service/style2/main-img/fs2.webp';
import serviceIcon15 from '../../assets/img/service/style2/main-img/fs3.webp';
import serviceIcon3 from '../../assets/img/service/style2/main-img/fs4.webp';
import serviceIcon24 from '../../assets/img/service/style2/main-img/36.webp';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.webp';
import img3 from '../../assets/img/technology/style3/3.webp';
import img11 from '../../assets/img/technology/style3/11.webp';
import img13 from '../../assets/img/technology/style3/13.webp';
import img16 from '../../assets/img/technology/style3/16.webp';
import img15 from '../../assets/img/technology/style3/15.webp';
import img14 from '../../assets/img/technology/style3/14.webp';
import img17 from '../../assets/img/technology/style3/17.webp';



// Service Icons
import effectImg2 from '../../assets/img/about/dotted-3.webp';
import effectImg3 from '../../assets/img/about/shape3.webp';

const Front_end = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-80">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-7 col-md-12 pl-20 md-pl-5">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text gold-color"
                                subtitle="Our ReactJS Expertise"
                                titleClass="title pb-25"
                                title="Highly appealing and smooth-functioning Front-End Development Services"
                                descClass="desc pb-5"
                                description=".All that looks and interacts well sells well! Our disciplined top dogs in front-end development have a proven track record of delivering industry-leading front-end designs. If an immersive and appealing design is on your mind, rely on us.
                               "
                                secondDescClass="desc pb-16"
                                secondDescription="Our meticulous experts specialize in Javascript frameworks like Angular, Reactjs, and Vuejs. We have got the hang of single-page applications, UI/UX of large-scale apps, progressive web apps, HTML5, CSS, and Javascript user interfaces. When your business joins hands with our ever-upgrading techies, your type and size of the industry don’t matter. Your front-end development design is bound to attract end users like crazy."
                            />
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}
         
           {/* rs-servicess-area-start */}
           <div className="rs-process style6 pt-50 pb-80 md-pt-70 md-pb-70">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text gold-color"
                        subtitle="Services"
                        titleClass="title pb-10"
                        title="Your brand needs Extensive Front-End Development Capabilities."
                        descClass="desc"
                        description="Now is the right time to let your business cross paths with our professional crew, who crafts engaging, innovative, and responsive apps to get your business up to snuff."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain29}
                                    HoverImg={imgMain29}
                                    Title="FrontEnd Architecture & Design"
                                    Text="Do you want an eye-catching app rich in user experience for your business? Count on our front-end developers for a responsive product that aligns with your vision and delivers nothing but best-in-class results. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain28}
                                    HoverImg={imgMain28}
                                    Title="Developing FrontEnd User Interfaces"
                                    Text="Every time you contact us for an app, you get a breezy user interface that manifests your digital presence like never before. We satisfy clients like you with our engagement-enhancing front-end development models."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                     MainImg={imgMain26}
                                    HoverImg={imgMain26}
                                    Title="Custom FrontEnd Development"
                                    Text="Your goals and ideas matter to us. Our front-end development team strives to develop purpose-built products using the latest technologies. Contact us and watch your app take you closer to your dreams.  "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain27}
                                    HoverImg={imgMain27}
                                    Title="Maintenance and Support"
                                    Text="Your investment in MetaQualt’s app development is worth every dime. How? We don’t leave you alone with your app. You get our full support in maintaining the app, troubleshooting the issues, and upgrading it with the latest trends."
                                    btnClass='d-none'
                                />
                            </div>


                            {/* <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain30}
                                    HoverImg={imgMain30}
                                    Title="NodeJS Migration Solutions"
                                    Text="Go with the flow! You can upgrade your existing website to Node.js and embrace its unique features. We are experts in the seamless migration of existing websites to Node.js or migrating a Node.js app to the newest version. "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="Delivering Next-Generation Node.js Services To Meet Your Business Objectives"
                                    effectClass="heading-line"
                                />
                            </div> */}


                        </div>
                    </div>
                </div>
            </div>
            <Technologies />

             {/* rs-services-area-start */}
             <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="Why Opt for Us? 

                        "
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon14}
                            Title="Proven Expertise"
                            Text="With our years of experience in user-centric app development using JavaScript, the solution you get is the unrivaled window to showcase your talent online. 
                            "
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Customer-Centric" 
                            Text="Our entire development process, from ideation to delivery, revolves around your goals and needs. Your website’s maintenance is in the hands of skilled and proactive developers. 
                            " 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon15}
                            Title="Year-long Experience" 
                            Text="Developing feature-rich JavaScript is a piece of cake for our experienced team, who have upgraded their skills promptly. So, what you get is a sheer hassle-free and authoritative application. 
                            " 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            Title="Result-Drive" 
                            Text="We don’t just provide glittering apps. We give a customized product that suits your industry’s needs. What you get is glittering and golden, appealing and profitable.
                            " 
                        />
                    </div>
                </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            {/* rs-services-area-start */}

            {/* <Technologies /> */}

            {/* rs-services-area-start */}
            {/* <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-60"
                            subtitleClass="sub-text gold-color"
                            subtitle="We Build Fast & Reliable Web Applications"
                            titleClass="title title2"
                            title="Working with NodeJS Web Application Development"
                            effectClass="heading-line" 
                            descClass="desc w-80"
                            description="We let you dig into our app development process. Here’s how we follow a strategic workflow, which is the success behind our company’s brilliant reputation."
                        />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="Send Us Your Project Requirements"
                                Text="We will go through your ideas and requirements sincerely. Then we will cherry-pick the best developer for your project to give you 100% customer satisfaction."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon1}
                                Title="Terms & Timeframes For Engagement" 
                                Text="Based on the consultation provided by analysts, you are the one to choose the project timelines. We also offer milestone options. We use agile strategies to meet project deadlines." 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon24}
                                Title="Secure Payment Methods" 
                                Text="We not only make secured apps, but we also make our client’s payments secure. You can opt for any of the multiple payment methods that are highly confidential. You get a cost-effective service. If you instantly pay us, we'll start working on your project.
                                " 
                            />
                        </div>
                        
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */}
            
             {/* rs-servicess-area-start */}
             {/* <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Industry-Leading Front End Developers"
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, deployment, design, and support to turn them into irresistible apps. With us, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default  Front_end;