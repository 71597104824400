import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/SalesforceConsultingcloud.webp';

const TechSlider = () => {
    return (
        <React.Fragment>
            <div className="bg18  style3 pt-100 pb-100 md-pt-40 md-pb-40" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title text-white">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 text-white"
                                    title="Why Choose MetaQualt for Salesforce Sales Cloud Solutions?"
                                    descClass="desc text-white"
                                    description="At MetaQualt, we help businesses maximise the benefits of Salesforce Sales Cloud through expert implementation, optimisation, and ongoing support. Our tailored approach ensures that you’re not just using a CRM tool, but driving tangible growth and success for your business."
                                    secondDescClass="desc mb-10 pb-16 text-white"
                                    secondDescription={
                                        <>
                                            <strong>MetaQualt’s Comprehensive Sales Cloud Consulting Services
                                            </strong><br/>
                                            &nbsp;Unlock the full potential of Salesforce Sales Cloud with our expert consulting services, tailored to optimise your sales processes and enhance customer relationships Our offerings include:
                                            . 
                                            </>
                                    }
                        
                                />
                                
                                </div>
                               
                                <div className='col-lg-6 col-md-6 '>
                                    <ul className="check-square">
                                        <li className="text-white">Sals Cloud Implementation</li>
                                        <li className="text-white">Sales Cloud Optimisation</li>
                                        <li className="text-white">Sales Cloud Customisation</li>
                                        
                                    </ul>
                                </div>
                                <div className='col-lg-6 col-md-6 pl-0 pr-0'>
                                    <ul className="check-square">
                                        <li className="text-white">Sales Cloud Integration</li>
                                        <li className="text-white">Sales Cloud Training and Support</li>
                              
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default TechSlider;