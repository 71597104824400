import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";

import banner_Img from "../../assets/img/banner/power_bi_home.webp";

import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";


// Service Icons
import imgMain50 from "../../assets/img/service/style2/main-img/powerbi11.webp";
import imgMain51 from "../../assets/img/service/style2/main-img/powerbi12.webp";
import imgMain52 from "../../assets/img/service/style2/main-img/powerbi13.webp";
import imgMain53 from "../../assets/img/service/style2/main-img/powerbi14.webp";
import imgMain54 from "../../assets/img/service/style2/main-img/powerbi15.webp";
import imgMainai55 from "../../assets/img/service/style2/main-img/powerbi16.webp";

// Service Image Icons
import experiencedai from "../../assets/img/service/style2/main-img/icon1.webp";
import experiencedai1 from "../../assets/img/service/style2/main-img/icon2.webp";
import experiencedai2 from "../../assets/img/service/style2/main-img/icon3.webp";

import effectImg2 from "../../assets/img/about/dotted-3.webp";
import effectImg3 from "../../assets/img/about/shape3.webp";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import { CounterSection } from "../../components/CounterSection/Data";


import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";

const Power_BI_Main = () => {

  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-6 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="AI Innovation Hub"
                titleClass="title pb-25"
                title="Partner with Metaqualt for Expert Power BI Consulting
                            "
                descClass="desc pb-5"
                description="In today’s fast-paced business world, every decision is important. With the right data, you can make smarter, more strategic choices that drive your business forward. Microsoft Power BI is the tool that turns complex data into clear, interactive dashboards, empowering you to make decisions with confidence.
At Metaqualt, we specialize in helping businesses unlock the full potential of their data. With years of experience in Power BI consulting, we’ve been assisting organizations in transforming their data into actionable insights since the tool’s inception. Our team of experts ensures you get the most out of Power BI, making your decision-making process faster and more efficient."
                secondDescClass="desc pb-16"
              />
              <ConsultNowBtn />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="text-center">
                <img className="main" src={banner_Img} alt="" />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>
      <div className="rs-process style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Guiding Innovation"
            titleClass="title pb-10"
            title="Metaqualt’s Power BI Services"
            descClass="desc"
            description="At Metaqualt, we help turn your data into powerful insights, making business decisions faster and more accurate. Whether you’re new to Power BI or looking to optimize, our team provides the support you need.Let Metaqualt unlock the power of your data with tailored Power BI solutions. Reach out today to get started!
                    "
            effectClass="heading-line"
          />

          <div className="rs-services style3 modify2 pt-20">
            <div className="row">
              <div className="col-lg-6  mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain50}
                  HoverImg={imgMain50}
                  Title="Power BI Advanced Analytics
"
                  Text="Unlock predictive insights and actionable recommendations, helping you make data-driven decisions that move your business forward.
 
                                "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain51}
                  HoverImg={imgMain51}
                  Title="Power BI Implementation"
                  Text="We assess your current data and business needs to create a customized Power BI plan, ensuring smooth integration and maximum impact.
                                "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain52}
                  HoverImg={imgMain52}
                  Title="Power BI Integration and Customization"
                  Text="We seamlessly integrate Power BI with data from MySQL, NoSQL, Oracle, CSV files, and third-party APIs, making data exploration easier and more insightful.
                                "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain53}
                  HoverImg={imgMain53}
                  Title="Power BI Visualization and Reporting
"
                  Text="Our team creates custom, interactive dashboards and reports tailored to specific roles and departments for intuitive, actionable insights."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain54}
                  HoverImg={imgMain54}
                  Title="Power BI Migration"
                  Text="Ready to move from legacy software or Excel? Our experts help migrate your data to Power BI smoothly, ensuring continuity and data integrity.
                                "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMainai55}
                  HoverImg={imgMainai55}
                  Title="Power BI Reports and Dashboards"
                  Text="Create intuitive, graphical dashboards and reports to provide clear insights and drive success across your organization.

                                "
                  btnClass="d-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Process"
            titleClass="title title"
            title="The expertise of Our Power BI Specialists
                        "
            effectClass="heading-line"
            descClass="desc w-80"
            description="As a Business Intelligence consulting provider, we offer seamless integration of Power BI visuals into your website or application using Power BI Embedded with Azure. Benefit from a fully integrated, rich experience with complete API support and automatic authentication."
          />
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai}
                Title="Expert Power BI Solutions"
                Text="Our team of 10+ Power BI architects specializes in database design, ETL processes, and Power BI Excel consulting, providing tailored solutions for your needs. We ensure a secure, seamless data environment by following best governance practices."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai1}
                Title="Seamless Integration for Comprehensive Insights"
                Text="Metaqualt’s Power BI services integrate smoothly with platforms like Google, Salesforce, and Marketo, giving you all the insights you need in one place."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai2}
                Title="Empower Your Business with Data-Driven Strategy"
                Text="Let our experienced team help you create a data-driven strategy that empowers your business to make smarter decisions, faster."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="accordions pb-50">
        <div className="container">
          <FAQHeader data={faqDescriptions["Power_BI"]} />

          <AccordionCards data={accordions.Power_BI} />
        </div>
      </div>

      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
        ctaTitleClass="epx-title"
        ctaTitle="Enhance Your Business Strategy with Metaqualt’s Power BI Consulting Services
"
        ctaTextClass="exp-text"
        ctaText="At Metaqualt, we turn data into a competitive advantage with seamless Power BI-Salesforce integration for real-time insights, AI-powered analytics for predictive decision-making, and customized dashboards tailored to every department. 
"
        ctaText2="Our data-rich dashboards enable confident, data-driven decisions, while CRM analytics embed valuable insights directly into workflows. We focus on critical KPIs, offer deep customer insights through Salesforce CDP, and enhance marketing impact with seamless integration across platforms. Empower your team, streamline operations, and accelerate growth with Metaqualt's Power BI expertise."
        cta2TextClass="exp-text mannage"
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Get In Touch"
      />
    </React.Fragment>
  );
};

export default Power_BI_Main;
