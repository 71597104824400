import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import Solution from '../../components/Common/Breadcumb/solution';
import SolutionMain from './solutionMain';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.webp';

const SolutionMainPage = () => {

    return (
        <React.Fragment>
           
            <HeaderStyleThree
                parentMenu='about'
                 activeMenu='/about'
            />
            <HeaderStyleThree />
            <main>
                {/* breadcrumb-area-start */}
                <Solution
                    pageTitle="Solutions We Provide"
                    titleClass="page-title"
                    pageName="Solution"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="Solutions We Provide"
                />
                {/* breadcrumb-area-start */}

                {/* About Main */}
                <SolutionMain />
                {/* About Main */}

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </main>
            <Footer />
            <SearchModal />
        </React.Fragment>
    );
}

export default SolutionMainPage;