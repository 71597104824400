export const accordions = {
  digitl_market: [
    {
      id: 1,
      question: "Why invest in digital marketing services?",
      answer: {
        text: "Digital marketing boosts your brand's visibility, engagement, and sales with targeted online strategies.",
      },
    },
    {
      id: 2,
      question: "How does digital marketing help my business grow?",
      answer: {
        text: "It ensures a better brand awareness, drives traffic, and converts leads into loyal customers.",
      },
    },
    {
      id: 3,
      question: "How do I pick the right digital marketing agency?",
      answer: {
        text: "Find services that include, for example, SEO, PPC, Social Media, and Content Marketing. This should be accompanied by creativity, proven results, industry recognition, and excellent testimonials from satisfied clients.",
      },
    },
  ],
  quality_engineering: [
    {
      id: 1,
      question: "What kinds of QA testing services does Metaqualt provide?",
      answer: {
        text: "",
        bulletPoints: [
          "Test automation",
          "Functional testing",
          "Nonfunctional testing",
        ],
      },
    },
    {
      id: 2,
      question:
        "Why to decide on Metaqualt for software testing and QA services?",
      answer: {
        text: "",
        bulletPoints: [
          "Error-free and bug-free launches",
          "Enhanced user experience",
          "Improved product build quality",
          "Increased efficiency and productivity",
          "Increase sales conversion",
        ],
      },
    },
    {
      id: 3,
      question:
        "How does Metaqualt differ from others in its software testing and QA services?",
      answer: {
        text: "Metaqualt realizes every project is different. We hire the best QA engineers with relevant experience and forward them for every project in a strategic development and testing structure. In doing so, we ensure via both manual and automated testing that we don't leave any stone unturned for the identification of product issues that need settling for optimal performance and overall quality user experience.",
        bulletPoints: [],
      },
    },
    {
      id: 4,
      question:
        "What measures do you take into account during software testing?",
      answer: {
        text: "We have the key software testing metrics of response time, endurance, crash ratio, bandwidth, error rate, security, and resource utilization in delivering a robust, high-quality product.",
        bulletPoints: [],
      },
    },
    {
      id: 5,
      question: "How many testing rounds are enough for my project?",
      answer: {
        text: "There is no strict answer to 'when to stop testing,' because testing is an iterative process; once we have run several cycles, we know we can be confident that your software has been adequately tested against all functional requirements.",
        bulletPoints: [],
      },
    },
    {
      id: 6,
      question: "Do you provide the results of the testing in reports?",
      answer: {
        text: "Yes. We prepare comprehensive reports including audits summary and strategy outlines. These reports inform testing approaches that have been adopted thus making it easier for you to know the extent of work done by Metaqualt's QA professionals.",
        bulletPoints: [],
      },
    },
    {
      id: 7,
      question: "What are the benefits of outsourcing your QA services?",
      answer: {
        text: "",
        bulletPoints: [
          "Any time at your convenience",
          "Professional and experienced QA engineers",
          "Fewer days before getting the product to market",
          "Quality and safety are assured",
          "Up-to-date with the latest tools and methodologies",
        ],
      },
    },
    {
      id: 8,
      question:
        "Metaqualt - Does it guard client intellectual property confidentiality well?",
      answer: {
        text: "We take care to respect client confidentiality. From initial signing of an NDA to private repositories of secure code, we safeguard your intellectual property over the course of our engagement, including secure code transfer and clear ownership upon delivery.",
        bulletPoints: [],
      },
    },
  ],

  ux_ui_design: [
    {
      id: 1,
      question:
        "What design services do you provide under your UI/UX umbrella?",
      answer: {
        text: "We have a range of UI/UX services up our sleeve, covering user research, wireframing, prototyping, interface designing, and usability testing in creating engaging digital experiences.",
      },
    },
    {
      id: 2,
      question: "How much does it cost to hire designers for UI/UX?",
      answer: {
        text: "Our prices depend on the scope of the project and its requirements. Contact us for a quote tailored to your specific needs",
      },
    },
    {
      id: 3,
      question: "What is your design approach?",
      answer: {
        text: "Our approach is user-centric: starting from deep research and planning to iterative design, prototyping, and testing for optimal functionality and appeal.",
      },
    },
    {
      id: 4,
      question:
        "What value does Metaqualt bring in improving my website or app's user interface and experience?",
      answer: {
        text: "Our team uses research, user insights, and industry best practices, in order to create designs that go further on usability improvement, aesthetics elevation, and the development of user satisfaction.",
      },
    },
    {
      id: 5,
      question: "How do I start a design project with Metaqualt?",
      answer: {
        text: "Contact us via our website. We will guide you through the first consultation, get to know your requirements, and present further work.",
      },
    },
    {
      id: 6,
      question: "What if I don't like the design work?",
      answer: {
        text: "Your satisfaction is of utmost importance to us. We provide revisions upon revision for design work towards perfect coordination with your idea and feedback.",
      },
    },
    {
      id: 7,
      question: "What am I supposed to do?",
      answer: {
        text: "As our collaborator, you'll give us the initial project requirements, check on feedback in the review stages, and, if needed, any brand-specific assets for our design.",
      },
    },
    {
      id: 8,
      question:
        "Which tools and technologies are applied by Metaqualt for UI/UX design?",
      answer: {
        text: "We make use of a mix of market-leading tools: Sketch, Adobe XD, Figma, Photoshop, Illustrator, InVision Studio, Adobe After Effects, and Zeplin. Our complete toolkit enables cutting-edge and customized solutions.",
      },
    },
    {
      id: 9,
      question:
        "How long will it take to deliver a UI/UX design project for Metaqualt?",
      answer: {
        text: "It will depend on how complex and extensive the scope of the project is. Normally, UI/UX projects usually take a few weeks to up to several months in duration with clear milestones set forth during the period.",
      },
    },
    {
      id: 10,
      question: "Innovate Your Digital Experience?",
      answer: {
        text: "UI/UX design services help make experiences meaningful, memorable, and effective. Collaborate with Metaqualt to create a user-friendly, impactful digital product today!",
      },
    },
  ],
  artificial_intelligence: [
    {
      id: 1,
      question: "Experienced AI Professionals?",
      answer: {
        text: "Our specialized AI team has extensive experience handling complex AI projects and delivering powerful, impactful solutions.",
      },
    },
    {
      id: 2,
      question: "Innovation with Strong R&D Focus?",
      answer: {
        text: "Metaqualt invests heavily in R&D to develop cutting-edge algorithms and models, helping clients bring unique, competitive AI solutions to market.",
      },
    },
    {
      id: 3,
      question: "Prioritizing Security & Compliance?",
      answer: {
        text: "With security as our priority, we uphold strict confidentiality practices, sign NDAs, and implement data protection measures to maintain the highest standards throughout each project..",
      },
    },
  ],
  machine_learning: [
    {
      id: 1,
      question: "What is machine learning?",
      answer: {
        text: "Machine learning is a type of artificial intelligence that enables computers to learn from data and improve over time. It does not require explicit programming; instead, it uses algorithms that dig through patterns in data with the aim of predicting or making decisions.",
      },
    },
    {
      id: 2,
      question:
        "How does machine learning, deep learning, and artificial intelligence differ?",
      answer: {
        bulletPoints: [
          "Artificial Intelligence (AI): A broad field of activity focused on creating intelligent systems",
          "Machine Learning: This is an area of ML and applied when the deep neural network, especially in performing tasks such as image recognition and natural language processing, is used.",
        ],
      },
    },
    {
      id: 3,
      question: "How might Metaqualt help my business with machine learning?",
      answer: {
        text: "Metaqualt can also create tailored ML solutions depending on your needs. From predictive analytics to natural language processing and image recognition, our team can craft ML systems that help you and your organization capture value and innovate.",
      },
    },
    {
      id: 4,
      question: "What can be built with machine learning?",
      answer: {
        text: "",
        bulletPoints: [
          "E-commerce: Personalized recommendations",
          "Healthcare: Predictive diagnostics",
          "Finance: Fraud detection",
          "Retail & Media: Customer insights",
        ],
      },
    },
    {
      id: 5,
      question: "How do you ensure quality and accuracy of ML models?",
      answer: {
        text: "We use best practices: data preprocessing, feature engineering, and cross-validation in order to ensure high-quality and accurate models. Our testing and code review assure the best performance and security.",
      },
    },
    {
      id: 6,
      question: "How much does an ML solution cost?",
      answer: {
        text: "The price is mostly determined by the complexity of your project, the amount of available data and the expertise needed. Contact us for a custom quotation.",
      },
    },
    {
      id: 7,
      question:
        "Do you have an experience with the existing data infrastructure we work with?",
      answer: {
        text: "Absolutely! We can integrate ML models with your existing data systems. This can either be databases, cloud platforms, or other infrastructures.",
      },
    },
    {
      id: 8,
      question: "How do you protect sensitive data and enforce privacy?",
      answer: {
        text: "We encrypt your data both in transit and rest. In addition to this, we enforce strict controls on access and have regular security audits in place.",
      },
    },
  ],
  data_science: [
    {
      id: 1,
      question:
        "Why do I need to hire dedicated data scientists for my project?",
      answer: {
        text: "",
        bulletPoints: [
          "Specialized Expertise: Tap experts with additional knowledge in particular areas, thus no extensive training is required.",
          "Real-Time Process Improvement: Monitor and refine business processes through data insights in real time.",
          "Flexible Scaling: Change according to the project requirements that may have to shift due to many reasons.",
          "Collaborative Communication: Sustain constant collaboration to keep in line with the aims of projects.",
        ],
      },
    },
    {
      id: 2,
      question: "How do you approach your data science solution?",
      answer: {
        text: "",
        bulletPoints: [
          "Problem Definition/Understanding of the Business Problem: Define the objectives.",
          "Data Gathering: Source data from clients or third parties",
          "EDA: Clean and preprocess data following good practices on data engineering, together with analyzing it",
          "ML Model Development: Develop, train, and test suitable machine learning models according to your needs",
          "Integration & Deployment: Integrate ML systems into existing software or IT infrastructure. MLOps shall be used for monitoring and maintenance.",
        ],
      },
    },
    {
      id: 3,
      question:
        "How secure is the enterprise data in outsourcing the data science services?",
      answer: {
        text: "Data security is on one of the top priorities at Metaqualt. We are following the guidelines of ethical duties and industry standards, conducting constant regular security audits along with transparent algorithms, and anonymizing as well as de-identifying the data to keep away that critical information from being accessed. We are strictly following privacy regulations and best practices in AI and data science. ",
      },
    },
    {
      id: 4,
      question:
        "What are some of the tools and technologies you use for data science projects?",
      answer: {
        text: "",
        bulletPoints: [
          "All our data scientists are updated about the latest tools and technologies. This includes:",
          "Programming & Databases: Python, SQL, MongoDB.",
          "ML Models: Linear regression, SVM, XGBoost, Clustering, Time Series Models (SARIMAX, LSTM).",
          "Neural Networks: CNN, DNN, RNN.",
          "Visualization Tools: Power BI, Plotly, Seaborn, Matplotlib.",
          "Cloud Platforms: AWS, Azure, Google Cloud Platform (GCP).",
        ],
      },
    },
    {
      id: 5,
      question:
        "Do you help to apply the integration of Data Science solutions with other systems?",
      answer: {
        text: "Yes, Metaqualt specializes in tailoring custom data science solutions to work within existing systems. We ensure an easy integration and fine-tune the setup for a smooth user experience.",
      },
    },
    {
      id: 6,
      question: "Which areas would benefit from data science?",
      answer: {
        text: "",
        bulletPoints: [
          "Healthcare: Improve patient care, optimize hospital operations, and advance medical research",
          "Finance: Improve fraud detection, risk management, and personalized financial services.",
          "Retail & E-commerce: Forecast demand, Personalize the customer experience, and Price to maximize",
          "Transportation & Logistics: Improve routes, predict maintenance, and increase overall efficiency",
          "Education: Personalized learning and better resource allocation",
        ],
      },
    },
    {
      id: 7,
      question: "How long does a typical implementation take?",
      answer: {
        text: "This depends upon the complexity of projects involved in size of dataset, analysis scope, and specific solution goals. Metaqualt ensures on-time delivery by tailoring each project to suit the client's specific needs.",
      },
    },
    {
      id: 8,
      question: "How do I sign up for Metaqualt's data science services?",
      answer: {
        text: "",
        bulletPoints: [
          "Fill out our Contact Us form.",
          "Email us your requirements and references.",
          "Schedule a consultation to talk about your project goals and objectives.",
        ],
      },
    },
  ],
  software_development: [
    {
      id: 1,
      question:
        "What is the difference between software and a software product?",
      answer: {
        text: "A software is a group of programs designed to perform predefined activities in an organization or system, whereas a software product is the proprietary solution developed to be marketed to end-users, typically containing features, updates, and maintenance. ",
      },
    },
    {
      id: 2,
      question: "How do I start outsourcing my software development project?",
      answer: {
        text: "Contact us first, with your requirements for the project, and we will then schedule a consultation over which we will discuss a comprehensive project proposal outlining the steps of our collaboration for smooth outsourcing.",
      },
    },
    {
      id: 3,
      question: "How much does it cost to develop a software product?",
      answer: {
        text: "The cost is determined by the complexity, features, and timeline; a simple web or mobile app will cost about $50,000, while complex enterprise or SaaS-type solutions can easily reach over $1 million. ",
      },
    },
    {
      id: 4,
      question: "How long does it take to develop a software product?",
      answer: {
        text: "The development timeline can vary from a few months to over a year, depending on the complexity of the project, the feature set, and the experience of the development team",
      },
    },
    {
      id: 5,
      question: "How does one identify the best software development company?",
      answer: {
        text: "Look for a company that has a proven track record, a good portfolio, industry expertise, and good client reviews. Their approach to technology and project management should also be understood.",
      },
    },
    {
      id: 6,
      question: "Why India for offshore software development services?",
      answer: {
        text: "India offers a large pool of experienced IT professionals, cost-effective services, high standards of quality, and innovations to become an ideal offshoring destination for any software development project",
      },
    },
    {
      id: 7,
      question:
        "What type of support and maintenance after software deployment?",
      answer: {
        text: "Metaqualt offers on-going support and maintenance, including updates, bug fixes, performance monitoring, and technical assistance so that your software is secure, functional, and up-to-date.",
      },
    },
    {
      id: 8,
      question:
        "Ensuring the safety of data while developing the software How do you ensure data security while developing software?",
      answer: {
        text: "Metaqualt follows secure coding practices, data encryption, access controls, and regular security audits to ensure the safety of your data. We strictly follow all the compliance standards like HIPAA, GDPR, and PCI-DSS so that your data is protected throughout the development lifecycle.",
      },
    },
    {
      id: 9,
      question: " Can the new software integrate with our existing systems?",
      answer: {
        text: "Yes, seamless integration of the new software with the existing one is our specialty. Compatibility, and at the same time, improving functionality so that it may help to streamline workflows and be well-integrated into a system- this does everything it promises.",
      },
    },
    {
      id: 10,
      question:
        " How do you ensure quality in your software development projects?",
      answer: {
        text: "Metaqualt ensures high-quality software through rigorous code review, automated testing, continuous integration, and comprehensive quality assurance practices. We test every stage so that the software is reliable as well as robust in its performance.",
      },
    },
    {
      id: 11,
      question: "Why do I need to hire a custom software development company?",
      answer: {
        text: "Designing a custom software development company like Metaqualt assures you that you get bespoke solutions, which are tailored to your particular business. Thus, you will get more flexibility, scalability, and integration than you would in off-the-shelf options.",
      },
    },
  ],
  CRM_development: [
    {
      id: 1,
      question:
        "What is the difference between CRM and a CRM software solution?",
      answer: {
        text: "CRM represents the overall strategy for managing customer relationships, while a CRM software would be used for carrying out such a process with the aid of automation tools for tracking interactions, management of the sales pipeline, and customer data analysis",
      },
    },
    {
      id: 2,
      question: "How do I start implementing CRM in my business?",
      answer: {
        text: "Initiate: Get in touch with Metaqualt to talk over your business requirements. We shall organize a meeting solely aimed at knowing what you need and how we can fit a tailored CRM solution for you.",
      },
    },
    {
      id: 3,
      question: "How much will a CRM system cost to develop?",
      answer: {
        text: "Developing a CRM system will cost according to features, complexity, and the selected platform. For instance, a basic CRM can run between $20 000, while complex and highly customized ones can range to around $50,000 or up to more than $150,000.",
      },
    },
    {
      id: 4,
      question: "How long does it take to have a CRM system?",
      answer: {
        text: "It depends on the complexity and scope of the CRM system. Some simple system could be developed in a couple of weeks, while advanced, custom CRM solutions may require several months to develop.",
      },
    },
    {
      id: 5,
      question: "How do I choose the best CRM for my business?",
      answer: {
        text: "The CRM for your business is going to depend on those needs, size, and objectives. We can help you evaluate Salesforce, Odoo, Zoho, or a custom-made solution to determine what's best suited for your business.",
      },
    },
    {
      id: 6,
      question: "Why should I invest in a custom CRM solution?",
      answer: {
        text: "Customized CRM solutions are tailor-made for your business. They give more flexibility, scalability, and integration compared to generic off-the-shelf software. An individualized CRM gives you that competitive edge due to unique needs and goals.",
      },
    },
  ],
  Web_Development: [
    {
      id: 1,
      question: "Why is business-specific web app development important?",
      answer: {
        text: "",
        bulletPoints: [
          "Establishing credibility for your brand.",
          "Expanding your reach to a larger audience.",
          "Enhancing promotions through PPC campaigns.",
          "Monitoring business performance with analytics tools.",
          "Enhancing customer experience and satisfaction through data analytics.",
        ],
      },
    },
    {
      id: 2,
      question:
        "What are the advantages of outsourcing web application development in India?",
      answer: {
        text: "",
        bulletPoints: [
          "You get the flexibility to work as per your time zone.",
          "Get hold of skilled and experienced developers.",
          "Fastened up development timelines.",
          "High-quality services at low costs.",
          "Up-to-date cutting-edge technologies.",
          "Familiarity with varied development platforms.",
        ],
      },
    },
    {
      id: 3,
      question:
        "Why Choose Metaqualt for Web Application Development Services?",
      answer: {
        text: "",
        bulletPoints: [
          "Extensive industry experience.",
          "Clear and transparent processes.",
          "Timely project deliveries.",
          "Solutions customized to your unique needs.",
          "Direct communication with a dedicated team.",
          "Enhanced user experiences guaranteed.",
          "Enhanced user experiences guaranteed.",
        ],
      },
    },
    {
      id: 4,
      question: "What factors influence the cost of custom web development?",
      answer: {
        text: "The cost varies depending on factors such as the development platform, project complexity, desired features, and developer location. Share your requirements with us to receive a detailed estimate.",
      },
    },
    {
      id: 5,
      question: "How long does it take to build a website or web app?",
      answer: {
        text: "Development timelines depend on the project's complexity, design, features, and testing requirements. Contact us with your project details for a precise timeline.",
      },
    },
    {
      id: 6,
      question: "Can you work with our pre-designed UI/UX?",
      answer: {
        text: "Absolutely! Whether you have designs ready or need assistance from scratch, our team can bring your vision to life at any development stage.",
      },
    },
    {
      id: 7,
      question: "Are your developers available for meetings in my time zone?",
      answer: {
        text: "Our working hours are 10 AM to 7 PM IST (Mon-Fri), but we can adjust meeting schedules by +/- 3 hours to accommodate your time zone.",
      },
    },
    {
      id: 8,
      question: "How do you ensure that my project is kept confidential?",
      answer: {
        text: "We ensure the safety of your intellectual property with NDAs, secure code management, and full ownership transfer, so your project will be protected at every turn.",
      },
    },
    {
      id: 9,
      question: "Do you offer any post-development support?",
      answer: {
        text: "We do. We offer a flexible bucket model, which gives you the option to hire developers on demand to keep your web app in top condition and up-to-date.",
      },
    },
    {
      id: 10,
      question:
        "Can Metaqualt help complete my incomplete web/app development project?",
      answer: {
        text: "WYes, we specialize in helping businesses finish projects left incomplete by other teams. Our experts will review your requirements and deliver a robust solution to meet your needs.",
      },
    },
  ],
  MobileAppDevelopment: [
    {
      id: 1,
      question: "What mobile app development technologies do you use?",
      answer: {
        text: "",
        bulletPoints: [
          "Programming Languages: Kotlin, Java, Swift, Flutter, React Native, Ionic",
          "Tools & Utilities: Android Studio, Xcode, Visual Studio Code, WebStorm, IntelliJ, SwiftUI, Firebase",
          "Databases: SQLite, Core Data, Firestore, Realm, Hive, ObjectBox",
        ],
      },
    },
    {
      id: 2,
      question:
        "How much does the development of a custom mobile application cost?",
      answer: {
        text: "",
        bulletPoints: [
          "platform - iOS, Android or both",
          "complexity level of the application",
          "scope of technologies and tools in use",
          "High-quality services at low costs.",
          "location and the developer's experience",
          "Ask us for a quote using your specifications.",
        ],
      },
    },
    {
      id: 3,
      question: "How many weeks does it take to develop a custom mobile app?",
      answer: {
        text: "",
        bulletPoints: [
          "Technology Stack and Platform",
          "Number of designs",
          "Features and functionality",
          "Testing and debugging requirements",
          "Contact us for a customized estimate of the time required for your project.",
        ],
      },
    },
    {
      id: 4,
      question: "Can you help me finish my incomplete app development project?",
      answer: {
        text: "Yes! Most of our clients come to us to save their partially completed projects. Our professionals analyze the work done and deliver solutions to finish the app in the most efficient and effective manner.",
      },
    },
    {
      id: 5,
      question: "We already have designs. Can you work with those?",
      answer: {
        text: "Yes, we can! Whether you have pre-made UI/UX designs or need us to design them, we are adept at delivering quality applications at any stage of the development cycle.",
      },
    },
    {
      id: 6,
      question: "I have a website. Can you create a mobile app for it?",
      answer: {
        text: "Of course! With our mobile-first approach, we can transform your website into an engaging mobile application that reaches your audience more effectively.",
      },
    },
    {
      id: 7,
      question: "Will your developers be able to meet in my time zone??",
      answer: {
        text: "Our developers are flexible and work across multiple time zones, so it is quite easy to collaborate. We work from 10 AM to 7 PM IST (Mon–Fri) but can accommodate meetings up to +/- 3 hours from our regular hours.",
      },
    },
    {
      id: 8,
      question:
        "What is your policy on intellectual property and app ownership?",
      answer: {
        text: "We protect your intellectual property with fool proof agreements. From the time we sign NDAs to completely giving you complete code ownership, you can be ensured of full ownership over every stage of the application development process.",
      },
    },
    {
      id: 9,
      question: "Does post-development support exist?",
      answer: {
        text: "Yes! Our flexible models for post-development support maintain your application robust and on time by providing ongoing support and updating it over the years.",
      },
    },
  ],
  CMSDevelopment: [
    {
      id: 1,
      question: "What CMS platforms do you specialize in?",
      answer: {
        text: "",
        bulletPoints: [
          "Platforms:",
          "Wordpress",
          "Joomla",
          "Drupal",
          "Shopify",
          "Magento",
          "WooCommerce",
          "Custom CMS:",
          "We also develop bespoke content management systems tailored to your business goals and user needs.",
        ],
      },
    },
    {
      id: 2,
      question: "How much does CMS development cost?",
      answer: {
        text: "",
        bulletPoints: [
          "Platform choice (custom or pre-built CMS)",
          "Design difficulty and customization needs",
          "Features and functionality",
          "Integration with other third-party tools",
          "Drop us a line with your project's details to get a better quote.",
        ],
      },
    },
    {
      id: 3,
      question: "How long does a CMS-based website take??",
      answer: {
        text: "",
        bulletPoints: [
          "Platform choice and difficulty",
          "Customization needs",
          "Other integrations with systems or tools",
          "Content migration and testing",
          "Let's talk to get an estimate that fits your needs.",
        ],
      },
    },
    {
      id: 4,
      question: "Can you help migrate my existing website to a new CMS?",
      answer: {
        text: "Yes, we can! Our CMS migration services ensure that your website is transferred to a new platform without losing data, downtime, or performance issues.",
      },
    },
    {
      id: 5,
      question: "Do you offer CMS customization services?",
      answer: {
        text: "Absolutely! We will tailor CMS solutions to meet your exact business needs. We design custom themes and develop unique plugins so that your CMS aligns perfectly with your objectives.",
      },
    },
    {
      id: 6,
      question:
        "What is your policy on intellectual property and CMS ownership?",
      answer: {
        text: "We ensure complete ownership of your CMS. From signing NDAs to handing over all source code and documentation, you retain full rights to your project.",
      },
    },
    {
      id: 7,
      question: "Do you provide post-development support for CMS?",
      answer: {
        text: "Yes, we do! Our maintenance and support services ensure your CMS remains secure, updated, and optimized for performance.",
      },
    },
  ],
  ECommerceDevelopment: [
    {
      id: 1,
      question: "What kind of e-commerce platforms do you specialize in?",
      answer: {
        text: "",
        bulletPoints: [
          "Platforms:",
          "Shopify",
          "Magento",
          "WooCommerce",
          "BigCommerce",
          "OpenCart",
          "PrestaShop",
          "Custom E-commerce:",
          "We also offer tailored e-commerce solutions to fulfill the one-of-a-kind needs of your business",
        ],
      },
    },
    {
      id: 2,
      question: "What influences the e-commerce development price?",
      answer: {
        text: "",
        bulletPoints: [
          "Types of platforms: in-house solution or off-the-shelf solution",
          "Complexity of designs and UI/UX features",
          "Number of product lines and categories",
          "Payment gateway, shipping system, and other integrations",
          "Chatbot, AI-based tools, and analytics",
          "Tell us more about your project to get a quote.",
        ],
      },
    },
    {
      id: 3,
      question: "How long does it take to develop an e-commerce website?",
      answer: {
        text: "",
        bulletPoints: [
          "Design complexity and features to be included",
          "Type of platform chosen - ready-made template or a customized solution",
          "Integration with the third party systems",
          "Products' count and comprehensive testing",
          "Give us your needs, so we can give you an approximate time for that task.",
        ],
      },
    },
    {
      id: 4,
      question:
        "Can I have third-party systems in my e-commerce website integrated?",
      answer: {
        text: "",
        bulletPoints: [
          "Payment gateways: Stripe, PayPal",
          "CRM",
          "Inventory management software",
          "Shipping and logistics software",
          "Analytics and reporting software",
        ],
      },
    },
    {
      id: 5,
      question: "Do your e-commerce solutions come with mobile-friendliness?",
      answer: {
        text: "We ensure your security by implementing:",
        bulletPoints: [
          "SSL certificates",
          "Secure payment gateways",
          "GDPR and PCI-DSS compliance",
          "The latest security protocols to protect your site and customer data",
        ],
      },
    },
    {
      id: 6,
      question: "Do you offer post-development support and maintenance?",
      answer: {
        text: "Yes! We provide detailed post-development support to maintain your e-commerce platform updated, secure, and optimized to run at its best and get timely fixes and upgrades.",
      },
    },
    {
      id: 7,
      question: "Can you migrate my existing store to a new platform?",
      answer: {
        text: "Absolutely! Our e-commerce migration services ensure smooth transition to your desired platform with zero data loss and minimal downtime.",
      },
    },
  ],
  StartUpSolution: [
    {
      id: 1,
      question: "What are the advantages of a mobile app for a startup?",
      answer: {
        text: "",
        bulletPoints: [
          "You can establish direct contact with your customers.",
          "Make your business more accessible and user-friendly.",
          "Magento",
          "Make your business more accessible and user-friendly.",
          "Release products and services update rapidly.",

          "Increase brand recognition and value.",
        ],
      },
    },
    {
      id: 2,
      question: "How long does it take to develop an app for a startup?",
      answer: {
        text: "",
        bulletPoints: [
          "Development platform.",
          "Complexity of design and features.",
          "Expertise of the developer.",
          "Testing and deployment requirements.",
          "Get an accurate time estimate for your specific needs by contacting us.",
        ],
      },
    },
    {
      id: 3,
      question:
        "What are the advantages of outsourcing the development of an app for a startup?",
      answer: {
        text: "",
        bulletPoints: [
          "Flexible communication across time zones.",
          "Access to highly experienced and skilled developers.",
          "Shorter development cycles.",
          "Affordable quality service with assured output.",
          "State-of-the-art technologies",
          "Proficiency in multiple platforms",
        ],
      },
    },
    {
      id: 4,
      question: "How do I select the perfect startup app development company?",
      answer: {
        text: "",
        bulletPoints: [
          "Development methodology",
          "Industry experience and expertise",
          "Size and strength of the team",
          "Reviews and success stories from clients",
        ],
      },
    },
    {
      id: 5,
      question: "Why Metaqualt?",
      answer: {
        text: "",
        bulletPoints: [
          "Transparent processes and communication.",
          "Projects are delivered in time.",
          "Tailor-made solutions that would best suit your needs",
          "Direct access to our expert team",
          "Enhanced user experiences through creative designs",
          "Flexible engagement models that best fit your needs.",
        ],
      },
    },
    {
      id: 6,
      question:
        "Does Metaqualt promise the confidentiality of intellectual properties?",
      answer: {
        text: "",
        bulletPoints: [
          "Non-disclosure agreements (NDAs).",
          "Storage of code in private repositories",
          "Handing over all project materials with ownership rights properly given.",
        ],
      },
    },
    {
      id: 7,
      question: "Will I get post-delivery support?",
      answer: {
        text: "Yes, we provide dedicated support and maintenance services through flexible engagement models. Whether you need constant maintenance or require updates on an ad hoc basis, our team is here to help.",
      },
    },
    {
      id: 8,
      question: "Can I hire app developers on an hourly or project basis?",
      answer: {
        text: "Yes, we offer flexible hiring models, including hourly, monthly, and yearly options. You can begin with one model and switch to another depending on your project's needs.",
      },
    },
    {
      id: 9,
      question: "Can Metaqualt complete my unfinished app development project?",
      answer: {
        text: "Yes, we specialize in rescuing incomplete projects. You might have experienced problems with your previous partner; our experts work with you to identify your requirements, find the best solution, and bring your app idea to life.",
      },
    },
    {
      id: 10,
      question: "We already have designs. Can Metaqualt work with them?",
      answer: {
        text: "Of course! Whether you have a ready-to-use design or need help creating one, we can develop your app from any stage of the development process.",
      },
    },
  ],
  Power_BI: [
    {
      id: 1,
      question: "What is Power BI, and how can it benefit my business?",
      answer: {
        text: "Power BI is a powerful business analytics tool that transforms your data into interactive reports and dashboards, enabling data-driven decision-making and insights for all levels of your organization.",
      },
    },
    {
      id: 2,
      question:
        "How does Power BI integrate with Salesforce for real-time insights?",
      answer: {
        text: "Power BI integrates seamlessly with Salesforce, allowing you to visualize and analyze Salesforce data in real time, providing actionable insights that empower smarter decision-making.",
      },
    },
    {
      id: 3,
      question: "Can Power BI handle large datasets and complex analytics?",
      answer: {
        text: "Yes, Power BI is designed to handle large datasets and complex analytics with high performance, ensuring efficient data processing and analysis even at scale.",
      },
    },
    {
      id: 4,
      question:
        "Are Power BI dashboards customizable for specific business needs?",
      answer: {
        text: "Power BI dashboards are fully customizable, allowing you to tailor visualizations and reports to meet your unique business requirements and KPIs.",
      },
    },
    {
      id: 5,
      question:
        "What kind of support do you offer for Power BI implementation and training?",
      answer: {
        text: "We provide comprehensive support for Power BI implementation, including data integration, dashboard design, and hands-on training to ensure your team can effectively use the platform for ongoing success.",
      },
    },
  ],
  Application_Integration_Services: [
    {
      id: 1,
      question: "What are application integration services?",
      answer: {
        text: "Application integration services basically means connecting various software systems or applications in an organization and enables smooth exchange of information and automation of workflow that helps the organization to perform its functions at higher efficiency and improved interdepartmental collaboration.",
      },
    },
    {
      id: 2,
      question: "Why does my business need application integration services?",
      answer: {
        text: "If the business is using multiple software solutions, working in silos, then there is a tendency of inefficiencies, duplication of data, and gaps in communication. All the systems will work together to achieve increased productivity, accurate data, and better decision-making with the application integration services.",
      },
    },
    {
      id: 3,
      question: "What types of applications can be integrated?",
      answer: {
        text: "We can incorporate diverse applications including CRM systems, ERP solutions, cloud platforms, e-commerce tools, financial systems, and software built custom for any business. Our services are as per your specific business requirements.",
      },
    },
    {
      id: 4,
      question: "How secure is the integration process?",
      answer: {
        text: "Security is always the top priority in our integration procedures. We are at best practice in the industry: from encryption of data, safe configuration of APIs, and even following relevant regulations; therefore, your data can be kept confidential and protected.",
      },
    },
    {
      id: 5,
      question:
        " Can integration services support cloud-based and on-premise systems?",
      answer: {
        text: "Our company specializes in the smooth integration of both cloud-based and on-premise systems with your existing infrastructure.",
      },
    },
    {
      id: 6,
      question:
        "How long does it take to complete an application integration project?",
      answer: {
        text: "This depends on the complexity of your requirements, the number of systems involved, and the degree of customization needed. Our team provides a detailed project plan that ensures you are well-continued with the process and timelines.",
      },
    },
    {
      id: 7,
      question: "Will the integration disrupt my ongoing business operations?",
      answer: {
        text: "We are always on the lookout for ways to minimize disruption and will coordinate closely with your team to arrange integrations at non-peak times, and, if needed, phase an integration.",
      },
    },
    {
      id: 8,
      question: "Do you offer post-integration support and maintenance?",
      answer: {
        text: "Absolutely, we provide comprehensive support after integration has been implemented, including troubleshooting, updates, and optimization services to keep the integrated system running over time.",
      },
    },
  ],
  Application_Migration_Services: [
    {
      id: 1,
      question: "What are application migration services?",
      answer: {
        text: "Application migration services refer to the process of transferring software applications from one environment to another, for example, from on-premise servers to the cloud, between cloud platforms, or even migrating legacy systems to modern frameworks.",
      },
    },
    {
      id: 2,
      question: " Why should I migrate my applications?",
      answer: {
        text: "It will ensure better performance, scalability, and security. The company will thus save money and be kept abreast with the recent technologies; operational efficiency will thus be enhanced.",
      },
    },
    {
      id: 3,
      question: "Which kind of migration services do you offer?",
      answer: {
        text: "Response: I offer multiple migrations such as cloud migration, platform migration, database migration, along with legacy application modernization directed toward the customer's business objective.",
      },
    },
    {
      id: 4,
      question: "How would you maintain data safety while migrating?",
      answer: {
        text: "The data will be ensured for safety through stronger encryption and safe transfer protocols at all stages of migration by sticking to the industries concerned regulations.",
      },
    },
    {
      id: 5,
      question: "Will there be a downtime?",
      answer: {
        text: "We meticulously plan, test ahead, and, where possible, schedule the migrations during off-peak hours to avoid any maximum downtime.",
      },
    },
    {
      id: 6,
      question: " How would you handle compatibility issues while migrating?",
      answer: {
        text: "Compatibility issues are assessed by the team on your existing systems and applications. Then proven strategies and tools would be used to address such challenges to ensure smooth migration.",
      },
    },
    {
      id: 7,
      question: "How long does an application migration project take?",
      answer: {
        text: "It depends on the complexity of the application, volume of data, and the target environment. We give you a customized project plan with clear milestones so you are kept informed through each phase of the process.",
      },
    },
    {
      id: 8,
      question: "Do you offer post-migration support?",
      answer: {
        text: "Yes, we give you complete post-migration support including monitoring, optimization, and troubleshooting services, so that your applications can run smoothly in their new environment.",
      },
    },
  ],
  Cloud_Application_Development: [
    {
      id: 1,
      question: "What is cloud application development?",
      answer: {
        text: "Cloud application development is the development of software applications that run in a cloud environment. The usage of software applications relies on the scalability and flexibility of cloud computing, cost-effective to perform better, and for end-user access.",
      },
    },
    {
      id: 2,
      question:
        "What are the benefits of cloud-based applications for businesses?",
      answer: {
        text: "The pros offered by cloud-based applications are scalability, low infrastructure cost, better collaboration, improved access, automatic update, and good disaster recovery capabilities.",
      },
    },
    {
      id: 3,
      question:
        "Which cloud platforms do you support for application development?",
      answer: {
        text: "We work on leading cloud platforms like AWS, Microsoft Azure, GCP, among others, and our solutions are shaped to match your specific business requirements.",
      },
    },
    {
      id: 4,
      question: "How secure are cloud-based applications?",
      answer: {
        text: " In our development process, security is in our DNA. We use state-of-the-art security technology that includes encryption, multi-factor authentication, regular updates, and industry compliance standards to ensure that your data as well as applications are safe.",
      },
    },
    {
      id: 5,
      question: "Can you migrate my existing application to the cloud?",
      answer: {
        text: "Yes we do migration services; we can have a smooth transfer of your current applications into the cloud without any interruption and cause very minimal downtime to your business..",
      },
    },
    {
      id: 6,
      question: "What types of applications can be developed in the cloud?",
      answer: {
        text: "We can develop a wide range of applications in the cloud, from web applications to mobile apps, enterprise software, to e-commerce platforms, custom solutions, tailored to fit your business needs.",
      },
    },
    {
      id: 7,
      question: "How long does it take to develop a cloud application?",
      answer: {
        text: " It can range from a few hours up to months depending on the complexity and features and functionality you want to have. In our case, we develop with you a detailed project plan, and work on timely delivery.",
      },
    },
    {
      id: 8,
      question:
        "Do you provide ongoing support and maintenance for cloud applications?",
      answer: {
        text: "Yes, we provide full post-launch support, which includes performance monitoring, updates, security enhancement, and scalability management to keep your application reliable and updated.",
      },
    },
  ],
  Cloud_Consulting: [
    {
      id: 1,
      question: "What does cloud consulting offer?",
      answer: {
        text: "",
        bulletPoints: [
          "Developing a cloud strategy",
          "Migration planning",
          "Cost optimization",
          "Performance enhancement",
        ],
      },
    },
    {
      id: 2,
      question: "Why do I need cloud consulting services for my business?",
      answer: {
        text: "Cloud consulting will ensure all right decisions are made regarding your business adopting or using more cloud technology, thereby ensuring you derive the maximum ROI, scalability, security, and lower costs from your operations.",
      },
    },
    {
      id: 3,
      question: "What cloud platforms do you support?",
      answer: {
        text: "We support the leading cloud platforms, including Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform (GCP), and others, providing customized solutions according to your business needs.",
      },
    },
    {
      id: 4,
      question: "How can cloud consulting reduce costs?",
      answer: {
        text: "Cloud consulting will help identify unnecessary expenses, optimize resource usage, and provide cost-effective solutions to make sure you only pay for what you need while keeping high performance.",
      },
    },
    {
      id: 5,
      question: "Can you assist with cloud migration?",
      answer: {
        text: "Yes, our cloud consulting services include planning and executing the migration. We ensure that your business operations are not affected as much as possible during the transition to the cloud.",
      },
    },
    {
      id: 6,
      question:
        "How do you ensure cloud security in the process and after the implementation?",
      answer: {
        text: "We strictly follow industry best practices by implementing robust encryption, access control measures, regulatory compliance standards, and regular security audits to protect your cloud environment.",
      },
    },
    {
      id: 7,
      question:
        "Which industries would benefit from cloud consulting services?",
      answer: {
        text: "Cloud consulting services are beneficial to a wide variety of industries, including health care, finance, retail, manufacturing, education, and IT, in that it will help them modernize operations and improve efficiency.",
      },
    },
    {
      id: 8,
      question: "Do you offer support post consulting engagement?",
      answer: {
        text: "Yes, we offer long-term support, which includes performance monitoring, scalability planning, cost optimization, and troubleshooting to ensure that the cloud infrastructure continues to deliver for your business.",
      },
    },
  ],

  Microsoft_DotNET_dev: [
    {
      id: 1,
      question: "What is Microsoft .NET development?",
      answer: {
        text: ".NET development using the .NET framework or .NET Core is the development of applications in .NET. It is one versatile platform for developing web, desktop, mobile, and cloud-based applications that are secure, scalable, and efficient.",
      },
    },
    {
      id: 2,
      question:
        "What are the benefits of using .NET for application development?",
      answer: {
        text: ".NET allows cross-platform development, supports high performance, provides robust security, contains a very rich library of tools and frameworks, and fully supports integration with services from Microsoft, and there is a huge number of applications that will be completely enterprise grade.",
      },
    },
    {
      id: 3,
      question: "What types of applications can be developed with .NET?",
      answer: {
        text: "With .NET you can create the following applications: enterprise solutions, web applications, mobile apps, APIs, IoT solutions, and cloud-based applications.",
      },
    },
    {
      id: 4,
      question: "Is .NET suitable for cross-platform development?",
      answer: {
        text: "Yes, .NET Core and .NET 5+ is cross-platform development created for developers to develop applications on Windows, Linux, and macOS without any problems.",
      },
    },
    {
      id: 5,
      question: "How do you ensure the security of .NET applications?",
      answer: {
        text: "We follow all the best practices meant for us to ensure using all sorts of security features in .NET, encryption, authentication protocols, and also we do code reviews to keep your applications secure.",
      },
    },
    {
      id: 6,
      question: "Can you integrate existing systems with .NET applications?",
      answer: {
        text: "Yes, we specialize in integrating .NET applications with existing systems, databases, and third-party tools to streamline workflows and enhance operational efficiency.",
      },
    },
    {
      id: 7,
      question: "How long does it take to develop a .NET application?",
      answer: {
        text: "We depend on the complexity, features, and requirements of the application to give us a timeline. We discuss a detailed project plan with milestones for you so that we can deliver well in time.",
      },
    },
    {
      id: 8,
      question:
        "Do you provide post-development support for .NET applications?",
      answer: {
        text: "Yes, we offer complete post-development support through maintenance, updates, monitoring performance, and troubleshooting to get your .NET applications to function optimally.",
      },
    },
  ],

  SaaS_Application_Development: [
    {
      id: 1,
      question: "What is SaaS application development?",
      answer: {
        text: "Definition of SaaS application development: Development of cloud-based software applications using internet-based software, hosting through a central server; which can be accessed via an Internet connection. Subscription model wherein the application can no longer be installed in your desktop or on your site like before.",
      },
    },
    {
      id: 2,
      question: "What are the benefits of developing a SaaS application?",
      answer: {
        text: "SaaS applications will give scalability, cost-effective, easy access, and auto-update capability along with better collaboration. All expensive hardware and infrastructure also avoided.",
      },
    },
    {
      id: 3,
      question: "How do you ensure the security of a SaaS application?",
      answer: {
        text: "We have robust security for your SaaS application like data encryption, secure authentication, role-based access control, regular vulnerability assessment, and the compliance of industry standards.",
      },
    },
    {
      id: 4,
      question:
        "What technologies do you use for SaaS application development?",
      answer: {
        text: "We are developing scalable and high-performance SaaS applications on top of modern technologies, such as React, Angular, Node.js, Python, .NET Core, Ruby on Rails, AWS, Azure, and Google Cloud.",
      },
    },
    {
      id: 5,
      question:
        "Can you help with migrating an existing application to a SaaS model?",
      answer: {
        text: "We can provide our expertise for the migration of your existing traditional software to SaaS model so that you face minimum disturbance and benefit in terms of cloud as much as possible.",
      },
    },
    {
      id: 6,
      question: "How long does it take to develop a SaaS application?",
      answer: {
        text: "It will depend on the complexity, features, and functionality needed. We do provide a detailed project roadmap and work collaboratively to make sure we are meeting deadlines with quality solutions.",
      },
    },
    {
      id: 7,
      question: "How do you ensure scalability in SaaS applications?",
      answer: {
        text: "We design our SaaS application for scalability considering cloud-native architectures, containerization, microservices, and load-balancing techniques so that user demands can be managed in an effective manner as they grow with time.",
      },
    },
    {
      id: 8,
      question: "Do you offer post-launch support for SaaS applications?",
      answer: {
        text: "Yes, support for full post-launch operations which include Maintenance, updates of the current version, check performance for improvement in its features with the aim of providing a steady and competitive edge SaaS application.",
      },
    },
  ],

  Tableau: [
    {
      id: 1,
      question: "What is Tableau, and how will it benefit my business?",
      answer: {
        text: "Tableau is the leader in data visualization and business intelligence. It helps you and your organization make better decisions by transforming complex data sets into interactive dashboards and reports so that you can easily find actionable insights.",
      },
    },
    {
      id: 2,
      question:
        "How does Tableau integrate with Salesforce to give me real-time insights?",
      answer: {
        text: "Tableau has an integration with Salesforce. This gives real-time analytics and visualizations directly into the Salesforce platform. Using this integration, one can track KPIs, watch trends, and get actionable insights without having to navigate between tools. It accelerates productivity and enhances decision-making capabilities.",
      },
    },
    {
      id: 3,
      question: "Does Tableau support large data and complex analytics?",
      answer: {
        text: "Yes, Tableau has been designed with the capability to handle extensive data and perform complex analytics very fast. It supports data blending, advanced calculations, and several integrations with other types of data sources in order to ensure scalability and strong performance, even when dealing with highly extensive and intricate datasets.",
      },
    },
    {
      id: 4,
      question:
        "Are the Tableau dashboards customizable for a specific business need?",
      answer: {
        text: "Fully. Tableau dashboards are very customizable; customize according to the needs of your business. You are allowed to create dynamic visualizations, apply filters, configure layouts, and make sure that the output will be in line with what you have in your goals and the data shows meaningfully.",
      },
    },
    {
      id: 5,
      question:
        "What kind of support you can offer for Tableau implementation and training?",
      answer: {
        text: "We ensure complete end-to-end Tableau implementation, including setting up the system, integrating it with other systems, and customizing it. Moreover, we provide training sessions that will help your team get the most out of what Tableau has to offer to ensure smooth implementation and continued success.",
      },
    },
  ],
  Database_Management: [
    {
      id: 1,
      question: "What is Database Management? Why is it important to businesses?",
      answer: {
        text: "In Database Management, data is organized and stored in a manner that facilitates effective retrieval and security. Data management has very important roles in ensuring data integrity, efficiency in general operations, and proper decision making from available information.",
      },
    },
    {
      id: 2,
      question: "How do I select the best database for my business?",
      answer: {
        text: "This is based on the volume, variety, and complexity of data for your business. For structured data, relational databases like SQL Server would be best. NoSQL databases like MongoDB would be great for unstructured and semi-structured data.",
      },
    },
    {
      id: 3,
      question: "Can you help me transition from a legacy system to a modern database?",
      answer: {
        text: "Yes we specialize in modernizing legacy systems by migration to agile and scalable database solutions. Our approach ensures minimal disruption and allows for optimizing performance with advanced features.",
      },
    },
    {
      id: 4,
      question:
        "How do you ensure the safety of my database?",
      answer: {
        text: "We enforce stringent security protocols that cover measures on encryption, access control, backup strategies, and regular patch management against unauthorized access and data breaches to your database.",
      },
    },
    {
      id: 5,
      question:
        "Is your support available on-premise and cloud-based databases?",
      answer: {
        text: "Yes, both on-premise and cloud databases are included. We support cloud solutions like SQL Azure and Amazon RDS that provide flexibility and scalability tailored to your needs.",
      },
    },
    {
      id: 6,
      question: "How will you ensure my database backup and recovery?",
      answer: {
        text: "We offer encrypted automated backup solutions, as well as efficient recovery procedures, to make sure that data is always safeguarded. This means your important business information will always be readily available and safeguarded against potential loss.",
      },
    },
    {
      id: 7,
      question: "What is the difference between relational and NoSQL databases?",
      answer: {
        text: "Relational databases, such as MySQL and PostgreSQL, are structured in tables and thus are suitable for complex queries. NoSQL databases, such as MongoDB and DynamoDB, are schema-less, ideal for unstructured data and high-speed real-time applications.",
      },
    },
    {
      id: 8,
      question: "Can you optimize the performance of our existing database?",
      answer: {
        text: "Yes. Our performance tuning services help identify bottlenecks, optimize queries, and enhance database configurations to ensure maximum efficiency and speed.",
      },
    },
  ],
};

export const faqDescriptions = {
  "digital-marketing": {
    description:
      "Here are some common questions and answers regarding our digital marketing services that can help boost your business.",
  },
  "quality-engineering": {
    description:
      "Below are the FAQs for our QA and software testing services that can bring answers to some of the most common questions.",
  },
  "ui-ux-design": {
    description:
      "Below are some frequently asked questions that explain how our UI/UX design services can help improve your digital experience.",
  },
  "web-development": {
    description:
      "Find the answers to your most common questions about our web development services. Get to know how we create robust and scalable websites.",
  },
  "ai-development": {
    description:
      "Explore the frequently asked questions about AI development, and learn how our solutions leverage artificial intelligence to drive innovation.",
  },
  "machine-learning": {
    description:
      "Explore the frequently asked questions about Machine Learning, and learn how our solutions leverage machine learning to drive innovation.",
  },
  "data-science": {
    description:
      "Explore the frequently asked questions about Data Science, and learn how our solutions leverage data science to drive innovation.",
  },
  "software-development": {
    description:
      "Explore the frequently asked questions about Software Development, and learn how our solutions leverage software development to drive innovation.",
  },
  "CRM-development": {
    description:
      "Explore the frequently asked questions about CRM Development, and learn how our solutions leverage CRM development to drive innovation.",
  },
  "Web-development": {
    description:
      "Explore the frequently asked questions about Web Development, and learn how our solutions leverage CRM development to drive innovation.",
  },
  MobileAppDevelopment: {
    description:
      "Explore the frequently asked questions about Mobile App Development, and learn how our solutions leverage CRM development to drive innovation.",
  },
  "CMS-Development": {
    description:
      "Explore the frequently asked questions about CMS Development, and learn how our solutions leverage CRM development to drive innovation.",
  },
  "ECommerce-Development": {
    description:
      "Explore the frequently asked questions about ECommerce Development, and learn how our solutions leverage CRM development to drive innovation.",
  },
  "StartUp-Solution": {
    description:
      "We have provided some of the most common questions that our clients often ask about developing a startup app.",
  },
  Power_BI: {
    description: "Explore Frequently Asked Questions about Power BI",
  },
  Application_Integration_Services: {
    description:
      "Explore Frequently Asked Questions about Application Integration Services",
  },
  Application_Migration_Services: {
    description:
      "Explore Frequently Asked Questions about Application Migration Services",
  },
  Cloud_Application_Development: {
    description:
      "Explore Frequently Asked Questions about Cloud Application Development",
  },
  Cloud_Consulting: {
    description: "Explore Frequently Asked Questions About Cloud Consulting",
  },
  "Microsoft_.NET_dev": {
    description: "Explore Frequently Asked Questions About Microsoft.NET Dev",
  },
  SaaSApplicationDevelopment: {
    description:
      "Explore Frequently Asked Questions about SaaS Application Development",
  },
  Tableau: {
    description: "Explore Frequently Asked Questions about Tableau",
  },
  Database_Management: {
    description: "Explore Frequently Asked Questions about Database Management",
  },
};
