import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import bannerImg from "../../assets/img/banner/banner-10.webp";
import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";

// Working Process Images




import techImg34 from "../../assets/img/technology/style2/34.webp";
import techImg35 from "../../assets/img/technology/style2/35.webp";
import techImg36 from "../../assets/img/technology/style2/36.webp";
import techImg37 from "../../assets/img/technology/style2/37.webp";
import techImg38 from "../../assets/img/technology/style2/38.webp";
import techImg9 from "../../assets/img/technology/style2/9.webp";
import techImg8 from "../../assets/img/technology/style2/8.webp";
import techImg4 from "../../assets/img/technology/style2/4.webp";
import techImg3 from "../../assets/img/technology/style2/03.webp";

import effectImg2 from "../../assets/img/about/dotted-3.webp";
import effectImg3 from "../../assets/img/about/shape3.webp";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import ConsultNowBtn from "../../components/ConsultNowBtn";



// Service Icons
import imgMain40 from "../../assets/img/service/style2/main-img/40.webp";
import imgMain41 from "../../assets/img/service/style2/main-img/41.webp";
import imgMain42 from "../../assets/img/service/style2/main-img/42.webp";
import imgMain43 from "../../assets/img/service/style2/main-img/43.webp";
import imgMain28 from "../../assets/img/service/style2/main-img/28.webp";

// Service Image Icons
import serviceIcon14 from "../../assets/img/service/style2/main-img/14.webp";
import serviceIcon1 from "../../assets/img/service/style2/main-img/1.webp";
import serviceIcon15 from "../../assets/img/service/style2/main-img/15.webp";

const HTML5CSS3 = () => {


  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-7 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="We are Different"
                titleClass="title pb-25"
                title="Hire best-in-class UI/UX Designers for your enterprise

                                "
                descClass="desc pb-5"
                description="It’s one thing to have a brilliant solution that values your customers and it’s another thing to present it the right way. Come up with the best idea and leave us for delivering a catchy, interactive, and rewarding website."
                secondDescClass="desc pb-16"
                secondDescription="You will get years of expertise to present you with a trending design that is highly immersive. Our UI/UX service is the most reliable to give you a custom website that profits you and awe-inspires your clients with its stunning interface. 

                                "
              />
              <ConsultNowBtn />
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>
      {/* <!-- banner section end --> */}

      {/* rs-services-area-start */}

      <div className="rs-technology pt-30 pb-30 pb-sm-0 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-1 col-md-4 col-sm-6 col-12"></div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="technology-item ui_ux_img">
                <a href="#">
                  <div className="logo-img">
                    <img src={techImg34} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="technology-item ui_ux_img">
                <a href="#">
                  <div className="logo-img">
                    <img src={techImg35} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="technology-item ui_ux_img">
                <a href="#">
                  <div className="logo-img">
                    <img src={techImg36} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="technology-item ui_ux_img">
                <a href="#">
                  <div className="logo-img">
                    <img src={techImg37} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="technology-item ui_ux_img">
                <a href="#">
                  <div className="logo-img">
                    <img src={techImg38} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-1 col-md-4 col-sm-6 col-12"></div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="technology-item">
                <a href="#">
                  <div className="logo-img">
                    <img src={techImg8} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="technology-item">
                <a href="#">
                  <div className="logo-img">
                    <img src={techImg9} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="technology-item ui_ux_img1">
                <a href="#">
                  <div className="logo-img">
                    <img src={techImg4} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="technology-item ui_ux_img1">
                <a href="#">
                  <div className="logo-img">
                    <img src={techImg3} alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

   
      <div className="rs-process style6 pt-80 pb-50 sm-pt-0">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Our Expertise"
            titleClass="title pb-10"
            title="Next-Gen UX/UI Design Beside Professional Excellence

                        "
            descClass="desc"
            description="Your sweet ideas get a sweeter medium to reach out to the audience that matters to you the most. With our UI/UX services, your website is an easy-to-use, interactive, and valuable straw that stirs the drink.
                        "
            effectClass="heading-line"
          />
      </div>
      </div>
          <div className="rs-services style3 modify2 pt-20">
            <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-20">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain40}
                  HoverImg={imgMain40}
                  Title="Web Interface Design"
                  Text="Looking for a first impression like never before? You can impress end-users with a dedicated team of UI/UX designers, well-known for their revolutionary web designs. You get a team of experts who crafts an eye-pleasing website interface with intense research and a user-centric approach."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-20">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain41}
                  HoverImg={imgMain41}
                  Title= "Responsive Design Development"
                  Text="We enable innovative ideas to dovetail creativity and flexibility. With our Responsive Design Development, you get the same jaw-dropping interface across a range of platforms."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-20">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain42}
                  HoverImg={imgMain42}
                  Title="Wireframes & Prototypes"
                  Text="We use Prototypes and Wireframes to test the design style 2 and framework. Then we go about crafting that attractive design that we promise to deliver."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-20">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain28}
                  HoverImg={imgMain28}
                  Title="Design Layout To HTML"
                  Text="We give ears to your design suggestions. If you come up with a design that matches your brand value, we are on board to transform it into a hassle-free HTML document."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-20">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain43}
                  HoverImg={imgMain43}
                  Title="Custom Logo Design & Branding"
                  Text= "We adorn your brand’s value with a unique and adaptable logo. We consider branding the bedrock of any successful endeavor. We deliberately make a great impact on your potential customer with our branding services."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 d-flex align-items-center align-content-center">
                <SectionTitle
                  sectionClass="sec-title2 text-center"
                  titleClass="title pb-10 fz-36"
                  title="With UI/UX Design Services, You Can Bridge The Gap Between Real and Digital."
                  effectClass="heading-line"
                />
              </div>
            </div>
          </div>
          </div>
        
     

      {/* <Technologies /> */}
      {/* rs-services-area-start */}

      {/* rs-services-area-start */}
      <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50 sm-pt-30">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Process"
            titleClass="title title"
            title="Looking for a Best UI/UX Design Service? Our Craftsmen are all set. 
                            "
            effectClass="heading-line"
            descClass="desc w-80"
            description="
                            The design we create gives your website the sheer power to boost conversion. You are at the right place if you are looking for a UI/UX design service that enriches the user experience of your site. 
                            "
          />
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon14}
                Title="Pixel-by-pixel, img-by-img"
                Text="Your website feels and looks great as our industrious techies work dedicatedly. We analyze the future design time. Get your ideas designed by designers with a Midas touch! 
                                "
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon1}
                Title="User-Centered Design"
                Text="What looks good and what suits you is what converts well. Keeping your end users on top, we skillfully blend design with usability and functionality. "
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon15}
                Title="Future-Centric Designs"
                Text="With our design assistance, your website is a beauty with a brain. Our serious designers create a logical and technical art that’s bound to impress and convert like crazy. 
                                "
              />
            </div>
          </div>
        </div>
      </div>

      {/* newsletter-area-start */}

      <div className="accordions pb-50">
        <div className="container">
          <FAQHeader data={faqDescriptions["ui-ux-design"]} />

          <AccordionCards data={accordions.ux_ui_design} />
        </div>
      </div>
      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
        ctaTitleClass="epx-title"
        ctaTitle="Hire our UI/UX Designers and witness a huge transformation"
        ctaTextClass="exp-text"
        ctaText="Let your ideas traverse the right stream of brainstorming, branding, web interface, and logo design to turn them into irresistible web apps. With our UI/UX masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision. 
                "
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Get In Touch"
      />
     
    
    
    </React.Fragment>
  );
};

export default HTML5CSS3;
