import React from "react";

import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ScrollToTop from "../../components/Common/ScrollTop";



// Breadcrumbs Background Image
import bannerbg from "../../assets/img/banner/banner-6.webp";
import WhitePaper from "../../components/Common/Breadcumb/WhitePaper";
import WhitePaperMain from "../../components/white-paper";

const White_Paper = () => {
  return (
    <React.Fragment>
      <HeaderStyleThree parentMenu="blog" activeMenu="/blog" />
      <div>
       
        <WhitePaper 
         pageTitle="White Paper"
         titleClass="page-title"
         pageName="White Paper"
         breadcrumbsImg={bannerbg}
         animatedFont="lined-Font dance2"
         animatedFontsize="White Paper" />

       
        
        <WhitePaperMain />
        {/* Blog Main End */}

        {/* scrolltop-start */}
        <ScrollToTop />
        {/* scrolltop-end */}
      </div>
      <Footer />
      <SearchModal />
    </React.Fragment>
  );
};

export default White_Paper;
