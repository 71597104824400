import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.webp';
import Application_Integration from '../../components/Common/Breadcumb/Application_Integration';
import ApplicationIntagration_Main from './ApplicationIntagration_Main';
import ScrollToTop from '../../components/Common/ScrollTop';

const application_integration = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
         
                <Application_Integration 
                 pageTitle="Application Integration Service"
                 titleClass="page-title "
                 pageName="Application Integration Services"
                 breadcrumbsImg={bannerbg}
                 animatedFont="lined-Font dance2"
                 animatedFontsize="Application Integration Service" />
   

                 <ApplicationIntagration_Main />
     
            </div>
            <ScrollToTop />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default application_integration;